const initialData = {
  isLoading: false,
  activePrizesData: [],
  activeCollectionData: {},
  raffleDrawList: [],
  raffleDrawData: {},
  putViewerData: {},
  settingsData: [],
  prizesData: [],
  collectionsData: []
};

export default function rcontroller(state = initialData, action) {
  switch (action.type) {
    case "GET_RAFFLE_CONTROLLER_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_RAFFLE_CONTROLLER_SUCCESS":
      return {
        ...state,
        settingsData: action.payload.settings,
        prizesData: action.payload.prizes,
        collectionsData: action.payload.collections,
        isLoading: false,
      };
    case "GET_RAFFLE_CONTROLLER_FAILED":
      return {
        ...state,
        isLoading: false,
      };

    case "GET_ACOLLECTIONS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_ACOLLECTIONS_SUCCESS":
      return {
        ...state,
        activeCollectionData: action.payload.data,
        isLoading: false,
      };
    case "GET_ACOLLECTIONS_FAILED":
      return {
        ...state,
        activeCollectionData: [],
        isLoading: false,
      };

    case "GET_APRIZES_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_APRIZES_SUCCESS":
      return {
        ...state,
        activePrizesData: action.payload.data,
        isLoading: false,
      };
    case "GET_APRIZES_FAILED":
      return {
        ...state,
        activePrizesData: [],
        isLoading: false,
      };

    case "GET_DRAWS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_DRAWS_SUCCESS":
      return {
        ...state,
        raffleDrawList: action.payload.data,
        isLoading: false,
      };
    case "GET_DRAWS_FAILED":
      return {
        ...state,
        raffleDrawList: [],
        isLoading: false,
      };

    case "CREATE_RDRAW_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "CREATE_RDRAW_SUCCESS":
      return {
        ...state,
        raffleDrawData: action.payload.data,
        isLoading: false,
      };
    case "CREATE_RDRAW_FAILED":
      return {
        ...state,
        raffleDrawData: {},
        isLoading: false,
      };

    case "UPDATE_VIEWER_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "UPDATE_VIEWER_SUCCESS":
      return {
        ...state,
        putViewerData: action.payload.data,
        isLoading: false,
      };
    case "UPDATE_VIEWER_FAILED":
      return {
        ...state,
        putViewerData: {},
        isLoading: false,
      };

    case "UPDATE_RAFFLE_DRAW_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "UPDATE_RAFFLE_DRAW_SUCCESS":
      return {
        ...state,
        isLoading: false,
      };
    case "UPDATE_RAFFLE_DRAW_FAILED":
      return {
        ...state,
        isLoading: false,
      };

    default:
      return {
        ...state,
      };
  }
}
