import Axios from "../../../utils/axios";

export const getUsers = (data) => {
    return (dispatch) => {
        Axios.get("/users", dispatch, "GET_USERS", data);
    };
};

export const createUser = (data) => {
    return (dispatch) => {
        return Axios
            .post("/user", dispatch, "CREATE_USER", data)
            .then((response) => {
                if (response.response.data.status === "success") {
                    dispatch(getUsers());
                }
                return response;
            });
    };
};

export const updateUser = (data) => {
    return (dispatch) => {
        return Axios
            .post("/user/" + data.id, dispatch, "UPDATE_USER", data)
            .then((response) => {
                if (response.response.data.status === "success") {
                    dispatch(getUsers());
                }
                return response;
            });
    };
};

export const deleteUser = (data) => {
    return (dispatch) => {
        return Axios
            .delete("/user/" + data.id, dispatch, "DELETE_USER", data)
            .then((response) => {
                if (response.response.data.status === "success") {
                    dispatch(getUsers());
                }
                return response;
            });
    };
};

export const resetPassword = (data) => {
    return (dispatch) => {
        return Axios
            .post("/reset-password/" + data.id, dispatch, "RESET_PASSWORD", data)
            .then((response) => {
                if (response.response.data.status === "success") {
                    dispatch(getUsers());
                }
                return response;
            });
    };
};