import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "antd";
import Page from "./Page";

class LayoutSiderCard extends Component {
  render() {
    return <Page {...this.props} />;
  }
}

const LayoutSiderForm = Form.create({ name: "layout-sider-form" })(LayoutSiderCard);

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutSiderForm);
