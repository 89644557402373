import Axios from "../../../utils/axios";

export const getAttendance = (data) => {
    return (dispatch) => {
        Axios.get("/attendance", dispatch, "GET_ATTENDANCE", data);
    };
};

export const exportAttendance = (data) => {
    return (dispatch) => {
        Axios.get("/export-attendance", dispatch, "EXPORT_ATTENDANCE", data)
            .then((response) => {
                window.location.assign(`${process.env.REACT_APP_S3_BUCKET}${response.response.data.url}`);
            });
    };
};