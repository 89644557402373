const initialData = {
  isLoading: false,
  registrationFieldsData: [],
  registrationFieldData: {},
};

export default function admin(state = initialData, action) {
  switch (action.type) {
    case "GET_REGISTRATION_FIELDS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_REGISTRATION_FIELDS_SUCCESS":
      return {
        ...state,
        registrationFieldsData: action.payload.data,
        isLoading: false,
      };
    case "GET_REGISTRATION_FIELDS_FAILED":
      return {
        ...state,
        registrationFieldsData: [],
        isLoading: false,
      };

    case "CREATE_REGISTRATION_FIELD_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "CREATE_REGISTRATION_FIELD_SUCCESS":
      return {
        ...state,
        registrationFieldData: action.payload.data,
        isLoading: false,
      };
    case "CREATE_REGISTRATION_FIELD_FAILED":
      return {
        ...state,
        registrationFieldData: {},
        isLoading: false,
      };

    case "UPDATE_REGISTRATION_FIELD_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "UPDATE_REGISTRATION_FIELD_SUCCESS":
      return {
        ...state,
        registrationFieldData: action.payload.data,
        isLoading: false,
      };
    case "UPDATE_REGISTRATION_FIELD_FAILED":
      return {
        ...state,
        registrationFieldData: {},
        isLoading: false,
      };

    case "DELETE_REGISTRATION_FIELD_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "DELETE_REGISTRATION_FIELD_SUCCESS":
      return {
        ...state,
        registrationFieldData: action.payload.data,
        isLoading: false,
      };
    case "DELETE_REGISTRATION_FIELD_FAILED":
      return {
        ...state,
        registrationFieldData: {},
        isLoading: false,
      };

    default:
      return {
        ...state,
      };
  }
}
