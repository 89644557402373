import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "antd";

import { getSettings } from "./actions"
import { getEvent } from "../../utils/token";

import Page from "./Page";

class RaffleViewerCard extends Component {
  render() {
    return <Page {...this.props} />;
  }
}

const RaffleViewerForm = Form.create({ name: "raffle-viewer-form" })(RaffleViewerCard);

function mapStateToProps(state) {
  return {
    rSettingsData: state.rsetting.rSettingsData,
    event: getEvent()
  };
}

function mapDispatchToProps(dispatch) {
  return {
    OnGetSettings: (data) => dispatch(getSettings(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RaffleViewerForm);
