import React, { Component } from "react";
import { Form, Layout, Button, Row, Col, Popconfirm, Table, Tag } from "antd";
import "./index.scss";
import { checkPermission } from "../../utils/helper";
import { Redirect } from "react-router-dom";
import PageHeader from "../shared/PageHeader";
import DataTable from "../shared/DataTable";

const { Content } = Layout;

class User extends Component {
  initialState = {
    selectedRow: {},
    selectedRowKeys: [],
    tableData: [],
    showRegistrationModal: false,
    totalData: 0,
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  componentDidMount() {
    const onGetParam = {
      event_id: this.props.event.id
    };
    this.props.onGetAuditLogs(onGetParam);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.auditLogsData !== nextProps.auditLogsData) {
      this.setState({ tableData: nextProps.auditLogsData });
    }

    if (this.props.auditLogTotal !== nextProps.auditLogTotal) {
      this.setState({
        totalData: nextProps.auditLogTotal
      });
    }
  }

  render() {
    const { tableData, showCreateUpdateModal, totalData } = this.state;
    const { isLoading, } = this.props;
    const module = "audit-log";

    if (!checkPermission(module, "view")) {
      return (<Redirect to="/403" />);
    }

    const columns = [
      {
        title: 'Username',
        dataIndex: 'username',
        key: 'username',
        // filterable: true,
        render: (column, row) => (
          <span>{`${row.user ? row.user.username : ''}`}</span>
        )
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        // filterable: true,
        render: (column, row) => (
          <span>{`${row.user ? row.user.first_name : ''} ${row.user ? row.user.last_name : ''}`}</span>
        )
      },
      {
        title: 'Activity',
        dataIndex: 'activity',
        key: 'activity',
        filterKey: 'activity',
      },
      {
        title: 'Timestamp',
        dataIndex: 'created_at',
        key: 'created_at'
      }
    ];

    return (
      <Layout className="audit-log-container">
        <Content className="audit-log-content" style={{ padding: "20px 60px" }}>
          <PageHeader />

          <Row type="flex" gutter={30}>
            <Col span={24}>
              <DataTable
                isLoading={isLoading}
                columns={columns}
                dataSource={tableData}
                total={totalData}
                onTableChange={this.props.onGetAuditLogs} />
            </Col>
          </Row>
        </Content>
      </Layout>
    );
  }
}

const UserForm = Form.create({ name: "audit-log-form" })(User);

export default UserForm;
