import React, { Component } from "react";
import { Form, Modal, message, Layout, Button, Row, Col, Input, Spin, Table } from "antd";
import "./index.scss";

const { Content } = Layout;

class ManualRegisterModal extends Component {
  initialState = {
    showModal: false,
    selectedRow: {},
    tableData: []
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  handleCloseModal = () => {
    this.setState({
      showModal: false,
      selectedRow: {},
      tableData: []
    });
    this.props.form.resetFields();
    this.props.handleCloseModal();
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.showModal !== nextProps.showModal) {
      this.setState({ showModal: nextProps.showModal });
    }

    if (this.props.registrationsData !== nextProps.registrationsData) {
      this.setState({ tableData: nextProps.registrationsData });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const params = {
          event_id: this.props.event.id,
          results: 10,
        };

        if (values.email) {
          params.email = values.email;
        }

        if (values.name) {
          params.name = values.name;
        }

        this.props.onGetRegistrations(params);

      } else {
        console.log("error", err);
      }
    });
  }

  handleAddRegistration = async row => {
    const params = {
      event_id: row.event_id,
      event_registration_id: row.id,
      event_ticket_id: row.ticket.id
    };

    await this.props
      .onAcceptAttendance(params)
      .then((response) => {
        if (response.response.data.status === "success") {
          message.success('Successfuly registered');
          this.setState({
            tableData: []
          });
          const onGetParam = {
            event_id: this.props.event.id,
            results: 10
          };
          this.props.onGetAttendance(onGetParam);
        } else {
          message.error('Error occured: ' + response.response.data.message);
        }
      })
      .catch(error => {
        console.log("error", error);
      });
  }

  render() {
    const { showModal, tableData } = this.state;
    const { isLoading, isRegistrationLoading, form: { getFieldDecorator }, } = this.props;

    const columns = [
      {
        title: 'Email Address',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text, record) => (
          <span>{`${record.first_name ?? ""} ${record.last_name ?? ""} ${record.name ?? ""}`}</span>
        )
      },
      {
        title: 'Ticket',
        dataIndex: 'ticket.ticket_name',
        key: 'ticket.ticket_name',
        align: 'center',
        sorter: (a, b) => a.ticket.length - b.ticket.length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        render: (status) => {
          if (status == 0) {
            return (<span>Declined</span>);
          } else if (status == 1) {
            return (<span>For Approval</span>);
          } else if (status == 2) {
            return (<span>Approved</span>);
          } else {
            return (<span></span>);
          }
        },
      },
      {
        title: '',
        key: 'action',
        align: 'center',
        render: (text, record) => (
          <Button type="primary" onClick={e => this.handleAddRegistration(record)} disabled={record.status != 2}>
            Register
          </Button>
        ),
      },
    ];

    return (
      <Modal
        title={'Manual Registration'}
        visible={showModal}
        onCancel={this.handleCloseModal}
        centered={true}
        footer={null}
        closable={false}
        maskClosable={false}
        width={"700px"}
      >
        <Layout className="overview-container">
          <Content className="overview-content" style={{ padding: "10px" }}>
            <Spin spinning={isLoading} key="create-event">
              <Form onSubmit={this.handleSubmit} style={{ width: "100%" }} disabled={isLoading}>
                <Row type="flex" gutter={30}>
                  <Col span={12}>
                    <Form.Item label="Email Address">
                      {getFieldDecorator("email", {
                        rules: [],
                      })(<Input />)}
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="Name">
                      {getFieldDecorator("name", {
                        rules: [],
                      })(<Input />)}
                    </Form.Item>
                  </Col>
                </Row>

                <Row type="flex" gutter={30}>
                  <Col span={24} style={{ marginBottom: 10, textAlign: "right" }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={isRegistrationLoading}
                      loading={isRegistrationLoading}
                      onClick={this.handleSearch}
                    >
                      Search
                    </Button>
                  </Col>
                </Row>

                <Row type="flex" gutter={30}>
                  <Col span={24}>
                    <Table columns={columns} dataSource={tableData}
                      rowKey={record => record.id}
                      loading={isRegistrationLoading}
                    />
                  </Col>
                </Row>

                <Row type="flex" gutter={30}>
                  <Col span={24} align="right">
                    <Form.Item>
                      <Button
                        type="default"
                        disabled={isLoading}
                        loading={isLoading}
                        onClick={this.handleCloseModal}
                      >
                        Close
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </Content>
        </Layout >
      </Modal>
    );
  }
}

const ManualRegisterModalComponent = Form.create({ name: "manual-register-modal" })(ManualRegisterModal);

export default ManualRegisterModalComponent;
