import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "antd";
import { getTickets, createTicket, updateTicket, deleteTicket } from "./actions";
import Page from "./Page";
import { getEvent } from "../../utils/token";

class TicketCard extends Component {
  render() {
    return <Page {...this.props} />;
  }
}

const TicketForm = Form.create({ name: "ticket-form" })(TicketCard);

function mapStateToProps(state) {
  return {
    isLoading: state.ticket.isLoading,
    ticketsData: state.ticket.ticketsData,
    ticketData: state.ticket.ticketData,
    event: getEvent()
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onGetTickets: (data) => dispatch(getTickets(data)),
    onCreateTicket: (data) => dispatch(createTicket(data)),
    onUpdateTicket: (data) => dispatch(updateTicket(data)),
    onDeleteTicket: (data) => dispatch(deleteTicket(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TicketForm);
