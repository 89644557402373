export const getMenu = () => {
  return JSON.parse(window.localStorage.getItem("MENU"));
};

export const deleteMenu = () => {
  return window.localStorage.removeItem("MENU");
};

export const setMenu = (menu) => {
  window.localStorage.setItem("MENU", JSON.stringify(menu));

  return menu;
};