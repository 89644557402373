import React, { Component } from "react";
import { Form, Layout, Button, Row, Col, Table, Icon, Tooltip } from "antd";
import "./index.scss";
import CreateUpdateModal from "./components/CreateUpdateModal";
import { checkPermission } from "../../utils/helper";
import { Redirect } from "react-router-dom";
import PageHeader from "../shared/PageHeader";

const { Content } = Layout;

class EmailTemplate extends Component {
  initialState = {
    emailTemplatesData: [],
    showCreateUpdateModal: false,
    selectedRow: {}
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  handleUpdateButton = (row) => {
    this.setState({
      showCreateUpdateModal: true,
      selectedRow: row
    });
  };

  handleCloseModal = () => {
    this.setState({
      showCreateUpdateModal: false,
    });
  };

  componentDidMount() {
    const onGetParam = {
      event_id: this.props.event.id
    };
    this.props.onGetEmailTemplates(onGetParam);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.emailTemplatesData !== nextProps.emailTemplatesData) {
      this.setState({ emailTemplatesData: nextProps.emailTemplatesData });
    }
  }

  render() {
    const { emailTemplatesData, showCreateUpdateModal, selectedRow } = this.state;
    const { isLoading, } = this.props;
    const module = "email-template";

    if (!checkPermission(module, "view")) {
      return (<Redirect to="/403" />);
    }

    const columns = [
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        align: 'center',
        render: type => (
          <span>
            {type == 2 ? "Confirmation" : ''}
            {type == 1 ? "QR Code" : ''}
            {type == 3 ? "Notify" : ''}
          </span>
        ),
      },
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
      },
      {
        title: 'Content',
        dataIndex: 'content',
        key: 'content',
        render: (content) => (
          <div dangerouslySetInnerHTML={{ __html: content }} style={{ maxHeight: "300px", overflow: "auto" }} />
        )
      },
      {
        title: 'Auto Send',
        dataIndex: 'auto_send',
        key: 'auto_send',
        render: type => (
          <span>
            {type == 0 ? "Off" : ''}
            {type == 1 ? "All" : ''}
            {type == 2 ? "Site Only" : ''}
          </span>
        ),
      },
      {
        title: 'Action',
        key: 'action',
        align: 'center',
        render: (text, record) => (
          <span>
            {(checkPermission(module, "edit")) ?
              (<Tooltip title="Edit"><Button type="default" onClick={() => this.handleUpdateButton(record)}><Icon type="edit" /></Button></Tooltip>) : ''
            }
          </span>
        ),
      },
    ];

    return (
      <Layout className="ticket-container">
        <Content className="ticket-content" style={{ padding: "20px 60px" }}>
          <PageHeader />

          <Row type="flex" gutter={30}>
            <Col span={24}>
              <Table columns={columns} dataSource={emailTemplatesData} loading={isLoading} rowKey={"id"}
                rowClassName={(row, index) => {
                  if (index % 2 === 0) {
                    return "row-odd";
                  }

                  return "row-even";
                }} />
            </Col>
          </Row>
        </Content>

        <CreateUpdateModal showModal={showCreateUpdateModal} selectedRow={selectedRow} handleCloseModal={this.handleCloseModal} {...this.props} />
      </Layout>
    );
  }
}

const EmailTemplateForm = Form.create({ name: "ticket-form" })(EmailTemplate);

export default EmailTemplateForm;
