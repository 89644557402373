import Axios from "../../../utils/axios";
import { getEvents } from "../../LayoutHeader/actions";

export const updateEvent = (data) => {
    return (dispatch) => {
        return Axios
            .post("/event/" + data.id, dispatch, "UPDATE_EVENT", data)
            .then((response) => {
                if (response.response.data.status === "success") {
                    dispatch(getEvents());
                }
                return response;
            });
    };
};

export const deleteEvent = (data) => {
    return (dispatch) => {
        return Axios
            .delete("/event/" + data.id, dispatch, "DELETE_EVENT", data)
            .then((response) => {
                if (response.response.data.status === "success") {
                    dispatch(getEvents());
                }
                return response;
            });
    };
};