const initialData = {
  isLoading: false,
  eventPageData: {},
};

export default function admin(state = initialData, action) {
  switch (action.type) {
    case "GET_EVENT_PAGE_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_EVENT_PAGE_SUCCESS":
      return {
        ...state,
        eventPageData: action.payload.data,
        isLoading: false,
      };
    case "GET_EVENT_PAGE_FAILED":
      return {
        ...state,
        eventPageData: {},
        isLoading: false,
      };

    case "UPDATE_EVENT_PAGE_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "UPDATE_EVENT_PAGE_SUCCESS":
      return {
        ...state,
        eventPageData: action.payload.data,
        isLoading: false,
      };
    case "UPDATE_EVENT_PAGE_FAILED":
      return {
        ...state,
        eventPageData: {},
        isLoading: false,
      };

    default:
      return {
        ...state,
      };
  }
}
