import React, { Component } from "react";
import { Form, Layout, Button, Row, Col, Popconfirm, Table, Tag } from "antd";
import "./index.scss";
import { checkPermission } from "../../utils/helper";
import { Redirect } from "react-router-dom";
import moment from "moment";
import PageHeader from "../shared/PageHeader";

const { Content } = Layout;

class PassportClaims extends Component {
  initialState = {
    passportClaimsData: [],
    showCreateUpdateModal: false,
    selectedRow: {}
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  handleResendButton = (row) => {
    this.props.onResendEmail(row);
  }

  handleCloseModal = () => {
    this.setState({
      showCreateUpdateModal: false,
    });
  };

  componentDidMount() {
    const onGetParam = {
      event_id: this.props.event.id
    };
    this.props.onGetPassportClaims(onGetParam);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.passportClaimsData !== nextProps.passportClaimsData) {
      this.setState({ passportClaimsData: nextProps.passportClaimsData });
    }
  }

  render() {
    const { passportClaimsData, showCreateUpdateModal, selectedRow } = this.state;
    const { isLoading, } = this.props;
    const module = "passport-claims";

    if (!checkPermission(module, "view")) {
      return (<Redirect to="/403" />);
    }

    const columns = [
      {
        title: 'Email Address',
        dataIndex: 'email',
        key: 'email'
      },
      {
        title: 'Registrant',
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: 'Passport Name',
        dataIndex: 'passport_name',
        key: 'passport_name'
      },
      // {
      //   title: 'Description',
      //   dataIndex: 'description',
      //   key: 'description'
      // },
      {
        title: 'Ticket',
        dataIndex: 'ticket.ticket_name',
        key: 'ticket.ticket_name',
        align: 'left',
        // sorter: (a, b) => a.ticket.length - b.ticket.length,
        // sortDirections: ['descend', 'ascend'],
        render: (column, row) => {
          return (row.ticket.ticket_name);
        },
      },
      {
        title: 'QR Code',
        dataIndex: 'registration_ticket.qr_code',
        key: 'registration_ticket.qr_code',
        align: 'center',
        // sorter: (a, b) => a.ticket.length - b.ticket.length,
        // sortDirections: ['descend', 'ascend'],
        // render: (column, row) => {
        //   return (row.ticket.ticket_name);
        // },
      },
      {
        title: 'Timestamp',
        dataIndex: 'created_at',
        key: 'created_at',
        render: (created_at) => (
          <span>{`${moment(created_at).format('MMMM DD YYYY, h:mm A')}`}</span>
        )
      },
      // {
      //   title: 'Action',
      //   key: 'action',
      //   align: 'center',
      //   render: (text, record) => (
      //     <span>
      //       {record.status == 0 ? <Button type="default" onClick={() => this.handleResendButton(record)}>Resend</Button> : ''}
      //     </span>
      //   ),
      // },
    ];

    return (
      <Layout className="passport-claims-container">
        <Content className="passport-claims-content" style={{ padding: "20px 60px" }}>
          <PageHeader />

          <Row type="flex" gutter={30}>
            <Col span={24}>
              <Table columns={columns} dataSource={passportClaimsData} loading={isLoading} rowKey={"id"} />
            </Col>
          </Row>
        </Content>

        {/* <CreateUpdateModal showModal={showCreateUpdateModal} selectedRow={selectedRow} handleCloseModal={this.handleCloseModal} {...this.props} /> */}
      </Layout>
    );
  }
}

const PassportClaimsForm = Form.create({ name: "passport-claims-form" })(PassportClaims);

export default PassportClaimsForm;
