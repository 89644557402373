import { notification } from "antd";

export const notify = (type, description, message = null) => {
  const config = {
    placement: "bottomRight",
  };

  switch (type.toLowerCase()) {
    case 'info':
      config.message = message ?? "Information";
      config.description = description;
      notification.info(config);
      break;

    case 'success':
      config.message = message ?? "Success!";
      config.description = description;
      notification.success(config);
      break;

    case 'warning':
      config.message = message ?? "Warning!";
      config.description = description;
      notification.warning(config);
      break;

    case 'error':
      config.message = message ?? "Error Occured!";
      config.description = description;
      notification.error(config);
      break;
  }
}

export const notifyFormError = (err) => {
  const errors = Object.values(err);
  errors.forEach(row => {
    row.errors.forEach(subRow => {
      notify('error', JSON.stringify(subRow.message))
    });
  });
}
