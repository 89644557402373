import React from "react";
import "./index.scss";

const Forbidden = () => (
  <div className="not-found">
    <h3>403 You do not have access to this page</h3>
  </div>
);

export default Forbidden;
