import Axios from "../../../utils/axios";

export const getEmailLogs = (data) => {
    return (dispatch) => {
        Axios.get("/email-logs", dispatch, "GET_EMAIL_LOGS", data);
    };
};

export const createEmailLog = (data) => {
    return (dispatch) => {
        return Axios
            .post("/email-log", dispatch, "CREATE_EMAIL_LOG", data)
            .then((response) => {
                if (response.response.data.status === "success") {
                    const param = {
                        event_id: response.response.data.data.event_id
                    };
                    dispatch(getEmailLogs(param));
                }
                return response;
            });
    };
};

export const resendEmail = (data) => {
    return (dispatch) => {
        return Axios
            .post("/email-log-resend/" + data.id, dispatch, "UPDATE_EMAIL_LOG", data)
            .then((response) => {
                if (response.response.data.status === "success") {
                    const param = {
                        event_id: response.response.data.data.event_id
                    };
                    dispatch(getEmailLogs(param));
                }
                return response;
            });
    };
};

export const deleteEmailLog = (data) => {
    return (dispatch) => {
        return Axios
            .delete("/email-log/" + data.id, dispatch, "DELETE_EMAIL_LOG", data)
            .then((response) => {
                if (response.response.data.status === "success") {
                    const param = {
                        event_id: data.event_id
                    };
                    dispatch(getEmailLogs(param));
                }
                return response;
            });
    };
};