import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "antd";
import { updateEvent, deleteEvent } from "./actions";
import Page from "./Page";
import { getEvent } from "../../utils/token";

class OverviewCard extends Component {
  render() {
    return <Page {...this.props} />;
  }
}

const OverviewCardForm = Form.create({ name: "overview-form" })(OverviewCard);

function mapStateToProps(state) {
  return {
    isLoading: state.header.isLoading || state.overview.isLoading,
    event: getEvent()
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onUpdateEvent: (data) => dispatch(updateEvent(data)),
    onDeleteEvent: (data) => dispatch(deleteEvent(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OverviewCardForm);
