import React, { Component } from "react";
import { Form, Layout, Button, Input, Row, Col, Modal, Table, Icon, Popconfirm, Dropdown, Menu, message, Pagination } from "antd";
import "./index.scss";
import CsvCreator from "react-csv-creator";
import UploadModal from "./components/UploadModal";
import RegistrationModal from "./components/RegistrationModal";
import { checkPermission } from "../../utils/helper";
import { Redirect } from "react-router-dom";
import PageHeader from "../shared/PageHeader";
import { CSVLink, CSVDownload } from "react-csv";

const { Content } = Layout;

class Registration extends Component {
  initialState = {
    event: [],
    searchText: '',
    searchedColumn: '',
    registrationsData: [],
    tableData: [],
    showCreateUpdateModal: false,
    selectedRow: {},
    selectedRowKeys: [],
    registrationFieldsData: [],
    fieldsData: [],
    showUploadModal: false,
    // searchKey: [],
    pagination: {}
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  handleCloseModal = () => {
    this.setState({
      showCreateUpdateModal: false,
      showUploadModal: false,
      selectedRow: {}
    });
  };

  handleCreateButton = () => {
    this.setState({
      showCreateUpdateModal: true
    });
  };

  handleUpdateButton = (row) => {
    this.setState({
      showCreateUpdateModal: true,
      selectedRow: row
    });
  };

  handleDeleteButton = (row) => {
    this.props.onDeleteTicket(row);
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });

    this.props.onGetRegistrations({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      event_id: this.props.event.id,
      ...filters,
    });
  };

  handleReload = () => {
    const onGetParam = {
      event_id: this.props.event.id,
      results: 10
    };

    this.props.onGetRegistrations(onGetParam);
  };

  componentDidMount() {
    const onGetParam = {
      event_id: this.props.event.id,
      results: 10
    };
    this.props.onGetRegistrations(onGetParam);
    // this.props.onGetRegistrationFields(onGetParam);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.registrationsData !== nextProps.registrationsData) {
      let tableData = [];

      nextProps.registrationsData.forEach(row => {
        let status = "Declined";
        if (row.status == 1) {
          status = "For Approval";
        } else if (row.status == 2) {
          status = "Approved";
        }
        // row.ticket_name = row.ticket.ticket_name;

        // let ticket = [];
        // row.tickets.forEach(t => {
        //   let hasMatch = false;

        //   ticket.forEach(r => {
        //     if (r.id == t.event_ticket_id) {
        //       r.quantity++;
        //       r.qr_code = r.qr_code + ", " + t.qr_code
        //       hasMatch = true;
        //     }
        //   });

        //   if (!hasMatch) {
        //     const trow = {
        //       id: t.event_ticket_id,
        //       name: t.ticket.ticket_name,
        //       price: t.price,
        //       qr_code: t.qr_code,
        //       quantity: 1
        //     };

        //     ticket.push(trow);
        //   }
        // });

        // row.ticket = ticket;

        const newRow = {
          ...row
        };
        tableData.push(newRow);
      });

      this.setState({
        registrationsData: tableData,
        tableData: tableData
      });
    }

    if (this.props.registrationFieldsData !== nextProps.registrationFieldsData) {
      this.setState({ registrationFieldsData: nextProps.registrationFieldsData });
    }

    if (this.props.registrationsTotal !== nextProps.registrationsTotal) {
      const pagination = { ...this.state.pagination };
      pagination.total = nextProps.registrationsTotal;
      // pagination.showTotal = this.showTotal;

      pagination.showTotal = (total, range) => `${range[0]}-${range[1]} of ${total} ${total > 1 ? "items" : "item"}`

      pagination.pageSize = 10
      pagination.defaultCurrent = 1

      this.setState({
        pagination,
      });
    }

    // if (this.props.fieldsData !== nextProps.fieldsData) {
    //   let searchKey = ["email", "source_type", "qr_code", "ticket_name"];
    //   nextProps.fieldsData.forEach(row => {
    //     searchKey.push(row.key_name);
    //   });
    //   this.setState({ fieldsData: nextProps.fieldsData, searchKey });
    // }
  }

  showTotal = (total) => {
    return `Total ${total} item/s`;
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  handleMenuClick = (e) => {
    const { selectedRowKeys } = this.state;
    const key = e.key;
    switch (key) {
      case "register":
        break;

      case "download":
        break;

      case "upload":
        this.setState({
          showUploadModal: true
        });
        break;

      case "approve-all":
        const approveAllParams = {
          status: 2,
          ids: []
        }
        this.updateStatus(approveAllParams);
        break;

      case "decline-all":
        const declineAllParams = {
          status: 0,
          ids: []
        }
        this.updateStatus(declineAllParams);
        break;

      case "resend-all":
        this.sendEmail('confirmation', []);
        break;

      case "send-access-all":
        this.sendEmail('qr', []);
        break;

      case "send-reminder-all":
        this.sendEmail('reminder', []);
        break;

      case "approve-selected":
        const approveParams = {
          status: 2,
          ids: selectedRowKeys
        }
        this.updateStatus(approveParams);
        break;

      case "decline-selected":
        const declineParams = {
          status: 0,
          ids: selectedRowKeys
        }
        this.updateStatus(declineParams);
        break;

      case "delete-selected":
        const deleteParams = {
          event_id: this.props.event.id,
          ids: selectedRowKeys.join()
        }
        this.deleteRegistration(deleteParams);
        break;

      case "resend-selected":
        this.sendEmail('confirmation', selectedRowKeys);
        break;

      case "send-access-selected":
        this.sendEmail('qr', selectedRowKeys);
        break;

      case "send-reminder-selected":
        this.sendEmail('reminder', selectedRowKeys);
        break;

      case "export":
        const onGetParam = {
          event_id: this.props.event.id,
          event_name: this.props.event.event_name
        };
        this.props.onExportRegistrations(onGetParam);
        break;

      default:
        break;
    }
  }

  handleRowActionClick = (e, row) => {
    const key = e.key;
    const id = row.id;
    switch (key) {
      case "approve":
        const approveParams = {
          status: 2,
          ids: [id]
        }
        this.updateStatus(approveParams);
        break;

      case "decline":
        const declineParams = {
          status: 0,
          ids: [id]
        }
        this.updateStatus(declineParams);
        break;

      case "edit":
        this.handleUpdateButton(row);
        break;

      case "delete":
        const deleteParams = {
          event_id: row.event_id,
          ids: [id].join()
        }
        this.deleteRegistration(deleteParams);
        break;

      default:
        break;
    };
  }

  updateStatus = params => {
    this.props.onBatchUpdateStatusRegistration(params)
      .then((response) => {
        if (response.response.data.status === "success") {
          message.success('Successfuly updated registration status: ' + response.response.data.data.emails.join(', '));
          this.setState({
            selectedRowKeys: []
          });
        } else {
          message.error('Error occured: ' + response.response.data.message);
        }
      })
      .catch(error => {
        console.log("error", error);
      });
  }

  deleteRegistration = params => {
    this.props.onDeleteRegistration(params)
      .then((response) => {
        if (response.response.data.status === "success") {
          message.success('Successfuly deleted registration/s');
          this.setState({
            selectedRowKeys: []
          });
        } else {
          message.error('Error occured: ' + response.response.data.message);
        }
      })
      .catch(error => {
        console.log("error", error);
      });
  }

  sendEmail = (type, ids) => {
    const params = {
      ids: ids,
      event_id: this.props.event.id
    }

    if (type === "confirmation") {
      this.props.onEmailConfirmation(params)
        .then((response) => {
          this.postSendEmail(response);
        })
        .catch(error => {
          console.log("error", error);
        });
    } else if (type === "qr") {
      this.props.onEmailQrCode(params)
        .then((response) => {
          this.postSendEmail(response);
        })
        .catch(error => {
          console.log("error", error);
        });
    } else if (type === "reminder") {
      this.props.onEmailNotify(params)
        .then((response) => {
          this.postSendEmail(response);
        })
        .catch(error => {
          console.log("error", error);
        });
    }
  }

  postSendEmail = response => {
    if (response.response.data.status === "success") {
      message.success('Successfuly sent email/s: ' + response.response.data.data.join(", "));
      this.setState({
        selectedRowKeys: []
      });
    } else {
      message.error('Error occured: ' + response.response.data.message);
    }
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearchApi(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearchApi(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    // onFilter: (value, record) =>
    //   record[dataIndex]
    //     .toString()
    //     .toLowerCase()
    //     .includes(value.toLowerCase()),
    // onFilterDropdownVisibleChange: visible => {
    //   if (visible) {
    //     setTimeout(() => this.searchInput.select());
    //   }
    // },
    // render: text =>
    //   this.state.searchedColumn === dataIndex ? (
    //     <Highlighter
    //       highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
    //       searchWords={[this.state.searchText]}
    //       autoEscape
    //       textToHighlight={text.toString()}
    //     />
    //   ) : (
    //     text
    //   ),
  });

  handleSearchApi = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  render() {
    const { tableData, showCreateUpdateModal, selectedRow, selectedRowKeys, showUploadModal, fieldsData, pagination } = this.state;
    const { isLoading, form: { getFieldDecorator }, event } = this.props;
    const module = "registration";

    if (!checkPermission(module, "view")) {
      return (<Redirect to="/403" />);
    }

    const columns = [
      {
        title: 'Email Address',
        dataIndex: 'email',
        key: 'email',
        ...this.getColumnSearchProps('email'),
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        ...this.getColumnSearchProps('name'),
        render: (text, record) => (
          <span>{`${record.first_name ?? ""} ${record.last_name ?? ""} ${record.name ?? ""}`}</span>
        )
      },
      // {
      //   title: 'Ticket',
      //   dataIndex: 'ticket.ticket_name',
      //   key: 'ticket.ticket_name',
      //   align: 'center',
      //   sorter: (a, b) => a.ticket.length - b.ticket.length,
      //   sortDirections: ['descend', 'ascend'],
      // },
      {
        title: 'Ticket/s',
        dataIndex: 'tickets',
        key: 'tickets',
        // ...this.getColumnSearchProps('name'),
        render: (tickets) => (
          <span>
            {/* {ticket.map((row, index) => <div key={index}>{`${row.quantity} x ${row.name}${row.qr_code ? " -" + row.qr_code : ""}`}</div>)} */}
            {tickets.map((row, index) =>
              <div key={index}>{`${row.qr_code ?? "No QR yet"} (${row?.ticket?.ticket_name})`}</div>
            )}
          </span>
        )
      },
      {
        title: 'Source',
        dataIndex: 'source_type',
        key: 'source_type',
      },
      // {
      //   title: 'QR Code',
      //   dataIndex: 'qr_code',
      //   key: 'qr_code',
      // },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        render: (status) => {
          if (status == 0) {
            return (<span>Declined</span>);
          } else if (status == 1) {
            return (<span>For Approval</span>);
          } else if (status == 2) {
            return (<span>Approved</span>);
          } else {
            return (<span></span>);
          }
        },
        sorter: (a, b) => a.ticket.length - b.ticket.length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: '',
        key: 'action',
        align: 'center',
        render: (text, record) => (
          <Dropdown overlay={
            <Menu onClick={e => this.handleRowActionClick(e, record)}>
              {(checkPermission(module, "approve")) && record.status != 2 ? (<Menu.Item key="approve">Approve</Menu.Item>) : ''}
              {(checkPermission(module, "approve")) && record.status != 0 ? (<Menu.Item key="decline">Decline</Menu.Item>) : ''}
              {(checkPermission(module, "edit")) ? <Menu.Item key="edit"><Icon type="edit" />Edit</Menu.Item> : ''}
              {(checkPermission(module, "delete")) ? <Menu.Item key="delete"><Icon type="delete" />Delete</Menu.Item> : ''}
            </Menu>
          }>
            <Button type="default">
              Actions <Icon type="down" />
            </Button>
          </Dropdown>
          // <span>
          //   <Button type="default" onClick={() => this.handleUpdateButton(record)}>Edit</Button>
          //   &nbsp;&nbsp;
          //   {/* <Divider type="vertical" /> */}
          //   <Popconfirm
          //     title="Are you sure you want to delete?"
          //     onConfirm={() => this.handleDeleteButton(record)}
          //     okText="Proceed"
          //     cancelText="Cancel"
          //   >
          //     <Button type="danger">Delete</Button>
          //   </Popconfirm>
          // </span>
        ),
      },
    ];

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const disableSelectedMenu = selectedRowKeys.length > 0 ? false : true

    let csvData = ['#'];
    let csvHeader = [{
      id: "counter",
      display: "#"
    }];

    fieldsData.forEach(row => {
      csvHeader.push({
        id: row.label.toLowerCase().replace(/ /g, "_"),
        display: row.label
      });
      csvData.push(row.label);
    });

    csvHeader.push({
      id: "ticket",
      display: "Ticket"
    });
    csvData.push("Ticket");

    return (
      <Layout className="registration-container" >
        <Content className="registration-content" style={{ padding: "20px 60px" }}>
          <PageHeader rightPanel={<>
            {(checkPermission(module, "bulk-upload") || checkPermission(module, "export-data")) ?
              <Dropdown loading={isLoading} overlay={
                <Menu onClick={this.handleMenuClick}>
                  {/* <Menu.Item key="register">Register</Menu.Item> */}
                  {(checkPermission(module, "bulk-upload")) ? <Menu.Item key="download">
                    {/* <CsvCreator
                      filename={`${event.event_name.toLowerCase().replace(/ /g, "_")}_registration_template`}
                      headers={csvHeader}
                      rows={[]}
                      text={"1"}
                    >
                      Download Template
                    </CsvCreator> */}
                    <CSVLink data={[csvData]} filename={`${event.event_name.toLowerCase().replace(/ /g, "_")}_registration_template`}>Download Template</CSVLink>
                  </Menu.Item> : ''}
                  {(checkPermission(module, "bulk-upload")) ? <Menu.Item key="upload">Upload CSV</Menu.Item> : ''}
                  {(checkPermission(module, "export-data")) ? <Menu.Item key="export">Export Data</Menu.Item> : ''}
                  {/* <Menu.Item key="export">
                    <CsvCreator
                      filename={`${event.event_name.toLowerCase().replace(" ", "_")}_registration`}
                      headers={csvExportHeader}
                      rows={csvRow}
                      text={""}
                    >
                      Export Data
                    </CsvCreator>
                  </Menu.Item> */}
                </Menu>
              }>
                <Button type="primary">
                  Actions <Icon type="down" />
                </Button>
              </Dropdown> : ''
            }

            &nbsp;&nbsp;

            {(checkPermission(module, "approve") || checkPermission(module, "email") || checkPermission(module, "delete")) ?
              <Dropdown loading={isLoading} overlay={
                <Menu onClick={this.handleMenuClick}>
                  <Menu.ItemGroup title="All">
                    {(checkPermission(module, "approve")) ? <Menu.Item key="approve-all">Approve</Menu.Item> : ''}
                    {(checkPermission(module, "approve")) ? <Menu.Item key="decline-all">Decline</Menu.Item> : ''}
                    {(checkPermission(module, "email")) ? <Menu.Item key="resend-all">Resend Email</Menu.Item> : ''}
                    {(checkPermission(module, "email")) ? <Menu.Item key="send-access-all">Send Access Code</Menu.Item> : ''}
                    {(checkPermission(module, "email")) ? <Menu.Item key="send-reminder-all">Send Reminder</Menu.Item> : ''}
                  </Menu.ItemGroup>
                  <Menu.ItemGroup title="Selected Rows">
                    {(checkPermission(module, "approve")) ? <Menu.Item disabled={disableSelectedMenu} key="approve-selected">Approve</Menu.Item> : ''}
                    {(checkPermission(module, "approve")) ? <Menu.Item disabled={disableSelectedMenu} key="decline-selected">Decline</Menu.Item> : ''}
                    {(checkPermission(module, "delete")) ? <Menu.Item disabled={disableSelectedMenu} key="delete-selected">Delete</Menu.Item> : ''}
                    {(checkPermission(module, "email")) ? <Menu.Item disabled={disableSelectedMenu} key="resend-selected">Resend Email</Menu.Item> : ''}
                    {(checkPermission(module, "email")) ? <Menu.Item disabled={disableSelectedMenu} key="send-access-selected">Send Access Code</Menu.Item> : ''}
                    {(checkPermission(module, "email")) ? <Menu.Item disabled={disableSelectedMenu} key="send-reminder-selected">Send Reminder</Menu.Item> : ''}
                  </Menu.ItemGroup>
                </Menu>
              }>
                <Button type="primary">
                  Bulk Actions <Icon type="down" />
                </Button>
              </Dropdown> : ''
            }
          </>} />

          <Row type="flex" gutter={30}>
            <Col span={24}>
              <Table columns={columns} dataSource={tableData} rowSelection={rowSelection}
                rowKey={record => record.id}
                loading={isLoading}
                onChange={this.handleTableChange}
                pagination={pagination}
                expandedRowRender={registration => (
                  <Row type="flex" gutter={30} key={"expanded-row-" + registration.id}>
                    <Col span={8} style={{ marginBottom: "10px" }} key={registration.id}>
                      <span style={{ fontWeight: "bold" }}>Reg ID:</span>&nbsp;&nbsp;
                      <span>{registration.id}</span>
                    </Col>

                    {fieldsData.map((field, key) => {
                      const value = registration[field.query_slug.replace(/\-/g, "_")];

                      if (field.type == "File Upload") {
                        return (
                          <Col span={8} style={{ marginBottom: "10px" }} key={field.id}>
                            <span style={{ fontWeight: "bold" }}>{field.label}:</span>&nbsp;&nbsp;
                            <span><img src={`${process.env.REACT_APP_S3_BUCKET}${value}`} width="150px" /></span>
                          </Col>
                        );
                      }

                      return (
                        <Col span={8} style={{ marginBottom: "10px" }} key={field.id}>
                          <span style={{ fontWeight: "bold" }}>{field.label}:</span>&nbsp;&nbsp;
                          <span>{value}</span>
                        </Col>
                      );
                    })}
                    {/* {registration.values.map(field => {
                      if (field.field === undefined) {
                        return ('');
                      }

                      if (field.field.type == "File Upload") {
                        return (
                          <Col span={8} style={{ marginBottom: "10px" }} key={field.id}>
                            <span style={{ fontWeight: "bold" }}>{field.field.label}:</span>&nbsp;&nbsp;
                            <span><img src={`${process.env.REACT_APP_S3_BUCKET}${field.field_value}`} width="150px" /></span>
                          </Col>
                        );
                      }

                      return (
                        <Col span={8} style={{ marginBottom: "10px" }} key={field.id}>
                          <span style={{ fontWeight: "bold" }}>{field.field.label}:</span>&nbsp;&nbsp;
                          <span>{field.field_value}</span>
                        </Col>
                      );
                    })} */}
                  </Row>
                )}
              />
            </Col>
          </Row>
        </Content>

        <RegistrationModal event={event} selectedRow={selectedRow} showRegistrationModal={showCreateUpdateModal} registrationFields={fieldsData} handleCloseModal={this.handleCloseModal} {...this.props} />

        <UploadModal showModal={showUploadModal} handleCloseModal={this.handleCloseModal} {...this.props} />
      </Layout>
    );
  }
}

const RegistrationForm = Form.create({ name: "registration-form" })(Registration);

export default RegistrationForm;
