import Axios from "../../../utils/axios";

export const getSalesReportFilters = (data) => {
    return (dispatch) => {
        Axios.get("/sales-report-filters", dispatch, "GET_SALES_REPORT_FILTERS", data);
    };
};

export const getSalesReport = (data) => {
    return (dispatch) => {
        Axios.post("/sales-report", dispatch, "GET_SALES_REPORT", data);
    };
};
