import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "antd";
import { getEmailLogs, resendEmail } from "./actions";
import Page from "./Page";
import { getEvent } from "../../utils/token";

class EmailLogCard extends Component {
  render() {
    return <Page {...this.props} />;
  }
}

const EmailLogForm = Form.create({ name: "email-log-form" })(EmailLogCard);

function mapStateToProps(state) {
  return {
    isLoading: state.emailLog.isLoading,
    emailLogsData: state.emailLog.emailLogsData,
    emailLogData: state.emailLog.emailLogData,
    event: getEvent()
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onGetEmailLogs: (data) => dispatch(getEmailLogs(data)),
    // onCreateEmailLog: (data) => dispatch(createEmailLog(data)),
    // onUpdateEmailLog: (data) => dispatch(updateEmailLog(data)),
    onResendEmail: (data) => dispatch(resendEmail(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailLogForm);
