import Axios from "../../../utils/axios";

export const getAuditLogs = (data) => {
    return (dispatch) => {
        Axios.get("/audit-logs", dispatch, "GET_AUDIT_LOGS", data);
    };
};

export const createauditLog = (data) => {
    return (dispatch) => {
        return Axios
            .post("/audit-log", dispatch, "CREATE_AUDIT_LOG", data)
            .then((response) => {
                if (response.response.data.status === "success") {
                    const param = {
                        event_id: response.response.data.data.event_id
                    };
                    dispatch(getAuditLogs(param));
                }
                return response;
            });
    };
};

export const updateauditLog = (data) => {
    return (dispatch) => {
        return Axios
            .post("/audit-log/" + data.id, dispatch, "UPDATE_AUDIT_LOG", data)
            .then((response) => {
                if (response.response.data.status === "success") {
                    const param = {
                        event_id: response.response.data.data.event_id
                    };
                    dispatch(getAuditLogs(param));
                }
                return response;
            });
    };
};

export const deleteauditLog = (data) => {
    return (dispatch) => {
        return Axios
            .delete("/audit-log/" + data.id, dispatch, "DELETE_AUDIT_LOG", data)
            .then((response) => {
                if (response.response.data.status === "success") {
                    const param = {
                        event_id: data.event_id
                    };
                    dispatch(getAuditLogs(param));
                }
                return response;
            });
    };
};