const initialData = {
  isLoading: false,
  registrationsData: [],
  registrationsTotal: 0,
  fieldsData: [],
  registrationData: {},
};

export default function admin(state = initialData, action) {
  switch (action.type) {
    case "GET_REGISTRATIONS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_REGISTRATIONS_SUCCESS":
      return {
        ...state,
        registrationsData: action.payload.data,
        registrationsTotal: action.payload.total_count,
        fieldsData: action.payload.fields,
        isLoading: false,
      };
    case "GET_REGISTRATIONS_FAILED":
      return {
        ...state,
        registrationsData: [],
        isLoading: false,
      };

    case "CREATE_REGISTRATION_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "CREATE_REGISTRATION_SUCCESS":
      return {
        ...state,
        registrationData: action.payload.data,
        isLoading: false,
      };
    case "CREATE_REGISTRATION_FAILED":
      return {
        ...state,
        registrationData: {},
        isLoading: false,
      };

    case "UPDATE_REGISTRATION_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "UPDATE_REGISTRATION_SUCCESS":
      return {
        ...state,
        registrationData: action.payload.data,
        isLoading: false,
      };
    case "UPDATE_REGISTRATION_FAILED":
      return {
        ...state,
        registrationData: {},
        isLoading: false,
      };

    case "UPDATE_REGISTRATION_STATUS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "UPDATE_REGISTRATION_STATUS_SUCCESS":
      return {
        ...state,
        registrationData: action.payload.data,
        isLoading: false,
      };
    case "UPDATE_REGISTRATION_STATUS_FAILED":
      return {
        ...state,
        registrationData: {},
        isLoading: false,
      };

    case "DELETE_REGISTRATION_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "DELETE_REGISTRATION_SUCCESS":
      return {
        ...state,
        registrationData: action.payload.data,
        isLoading: false,
      };
    case "DELETE_REGISTRATION_FAILED":
      return {
        ...state,
        registrationData: {},
        isLoading: false,
      };

    case "PROCESS_REGISTRATION_BATCH_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "PROCESS_REGISTRATION_BATCH_SUCCESS":
      return {
        ...state,
        isLoading: false,
      };
    case "PROCESS_REGISTRATION_BATCH_FAILED":
      return {
        ...state,
        isLoading: false,
      };

    case "EMAIL_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "EMAIL_SUCCESS":
      return {
        ...state,
        isLoading: false,
      };
    case "EMAIL_FAILED":
      return {
        ...state,
        isLoading: false,
      };

    default:
      return {
        ...state,
      };
  }
}
