import Axios from "../../../utils/axios";

export const getControllerSetup = (data) => {
    return (dispatch) => {
        Axios.get("/raffle-controller", dispatch, "GET_RAFFLE_CONTROLLER", data)
            .then((response) => {
                return response.response.data
            });
    };
};

export const getActiveCollections = (data) => {
    return (dispatch) => {
        Axios.get("/active-raffle-collections", dispatch, "GET_ACOLLECTIONS", data)
            .then((response) => {
                return response.response.data
            });
    };
};

export const getActivePrizes = (data) => {
    return (dispatch) => {
        Axios.get("/active-raffle-prizes", dispatch, "GET_APRIZES", data)
            .then((response) => {
                return response.response.data
            });
    };
};

export const getRaffleDraws = (data) => {
    return (dispatch) => {
        Axios.get("/raffle-draws", dispatch, "GET_DRAWS", data)
            .then((response) => {
                return response.response.data
            });
    };
};

export const createRaffleDraw = (data) => {
    return (dispatch) => {
        return Axios
            .post("/raffle-draw", dispatch, "CREATE_RDRAW", data)
            .then((response) => {
                if (response.response.data.status === "success") {
                    const params = {
                        event_id: response?.response?.data?.data[0]?.event_id
                    }
                    dispatch(getRaffleDraws(params));
                }
                return response;
            });
    };
};

export const updateRaffleViewer = (data) => {
    return (dispatch) => {
        return Axios
            .post("/raffle-settings/show-page", dispatch, "UPDATE_VIEWER", data)
            .then((response) => {
                if (response.response.data.status === "success") {
                    const param = {
                        event_id: response.response.data.data.event_id
                    };
                    dispatch(getControllerSetup(param));
                }
                return response;
            });
    };
};

export const updateRaffleDraw = (data) => {
    return (dispatch) => {
        return Axios
            .post("/raffle-draw-claim/" + data.id, dispatch, "UPDATE_RAFFLE_DRAW", data)
            .then((response) => {
                if (response.response.data.status === "success") {
                    const param = {
                        event_id: response.response.data.data.event_id
                    };
                    dispatch(getControllerSetup(param));
                    dispatch(getRaffleDraws(param));
                }
                return response;
            });
    };
};

export const bulkUpdateSettings = (data) => {
    return (dispatch) => {
        return Axios
            .post("/bulk-raffle-settings", dispatch, "UPDATE_BULK_RAFFLE_SETTINGS", data)
            .then((response) => {
                if (response.response.data.status === "success") {
                    const param = {
                        event_id: data.event_id
                    };
                    dispatch(getControllerSetup(param));
                }
                return response;
            });
    };
};

export const clearDraw = (data) => {
    return (dispatch) => {
        return Axios
            .post("/clear-raffle-draw", dispatch, "CLEAR_RDRAW", data)
            .then((response) => {
                if (response.response.data.status === "success") {
                    const params = {
                        event_id: data.event_id
                    }
                    dispatch(getControllerSetup(params));
                }
                return response;
            });
    };
};
