const initialData = {
  isLoading: false,
  userRolesData: [],
  userRoleData: {},
  roleAccessData: [],
};

export default function admin(state = initialData, action) {
  switch (action.type) {
    case "GET_USER_ROLES_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_USER_ROLES_SUCCESS":
      return {
        ...state,
        userRolesData: action.payload.data,
        roleAccessData: action.payload.role_access,
        isLoading: false,
      };
    case "GET_USER_ROLES_FAILED":
      return {
        ...state,
        userRolesData: [],
        isLoading: false,
      };

    case "CREATE_USER_ROLE_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "CREATE_USER_ROLE_SUCCESS":
      return {
        ...state,
        userRoleData: action.payload.data,
        isLoading: false,
      };
    case "CREATE_USER_ROLE_FAILED":
      return {
        ...state,
        userRoleData: {},
        isLoading: false,
      };

    case "UPDATE_USER_ROLE_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "UPDATE_USER_ROLE_SUCCESS":
      return {
        ...state,
        userRoleData: action.payload.data,
        isLoading: false,
      };
    case "UPDATE_USER_ROLE_FAILED":
      return {
        ...state,
        userRoleData: {},
        isLoading: false,
      };

    case "DELETE_USER_ROLE_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "DELETE_USER_ROLE_SUCCESS":
      return {
        ...state,
        userRoleData: action.payload.data,
        isLoading: false,
      };
    case "DELETE_USER_ROLE_FAILED":
      return {
        ...state,
        userRoleData: {},
        isLoading: false,
      };

    default:
      return {
        ...state,
      };
  }
}
