import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "antd";
import { getPassports, createPassport, updatePassport, deletePassport } from "./actions";
import Page from "./Page";
import { getEvent } from "../../utils/token";

class PassportCard extends Component {
  render() {
    return <Page {...this.props} />;
  }
}

const PassportForm = Form.create({ name: "passport-form" })(PassportCard);

function mapStateToProps(state) {
  return {
    isLoading: state.passport.isLoading,
    passportsData: state.passport.passportsData,
    passportData: state.passport.passportData,
    ticketsData: state.passport.ticketsData,
    event: getEvent()
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onGetPassports: (data) => dispatch(getPassports(data)),
    onCreatePassport: (data) => dispatch(createPassport(data)),
    onUpdatePassport: (data) => dispatch(updatePassport(data)),
    onDeletePassport: (data) => dispatch(deletePassport(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PassportForm);
