import React, { Component } from "react";
import { Form, Layout, Button, Row, Col, Icon, Table, Popconfirm, Tooltip, Tag } from "antd";
import "./index.scss";
import RaffleItemModal from "./components/RaffleItemModal.js";
import PageHeader from "../shared/PageHeader";
import { checkPermission } from "../../utils/helper";

const { Content } = Layout;

class RaffleItem extends Component {
  initialState = {
    rPrizesData: [],
    showRaffleItemModal: false,
    selectedRow: {}
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  getData = async () => {
    const onGetParam = {
      event_id: this.props.event.id,
      results: 10
    };
    await this.props.OnGetPrizes(onGetParam);
  }

  async componentDidMount() {
    await this.getData()
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.rPrizesData !== nextProps.rPrizesData) {
      this.setState({
        rPrizesData: nextProps.rPrizesData,
      });
    }
  }

  handleCreateButton = () => {
    this.setState({
      showRaffleItemModal: true
    });
  };

  handleUpdateButton = (row) => {
    this.setState({
      showRaffleItemModal: true,
      selectedRow: row
    });
  };

  handleCloseModal = () => {
    this.setState({
      showRaffleItemModal: false,
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;

    this.setState({
      pagination: pager,
    });

    this.props.OnGetPrizes({
      results: pagination.pageSize,
      page: pagination.current,
      event_id: this.props.event.id,
      ...filters,
    });
  };

  handleDeleteButton = async (row) => {
    await this.props.OnDeletePrize({
      id: row.id,
      event_id: this.props.event.id,
    })
  }

  handleUpdateButton = (row) => {
    this.setState({
      showRaffleItemModal: true,
      selectedRow: row
    });
  };

  render() {
    const { showRaffleItemModal, selectedRow, pagination, rPrizesData } = this.state;
    const { isLoading, } = this.props;
    const module = "raffle-prize"

    const columns = [
      {
        title: '',
        dataIndex: 'prize_image',
        key: 'prize_image',
        render: (prize_image) => <div style={{ height: "150px", position: "relative" }}>
          {prize_image ? <img height="100%" src={process.env.REACT_APP_S3_BUCKET + prize_image} /> : ''}</div>
      },
      {
        title: 'Item Prize',
        dataIndex: 'label',
        key: 'label',
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: 'Category',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Draw Count',
        dataIndex: 'draw_count',
        key: 'draw_count',
        align: 'center'
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: status => (
          <span>
            {status == 1 ?
              (
                <Tag color='green' key='active'>
                  Active
                </Tag>
              ) : (
                <Tag color='gray' key='inactive'>
                  Inactive
                </Tag>
              )
            }
          </span>
        ),
      },
      {
        title: 'Actions',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        render: (text, record) => (
          <span>
            {/* {(checkPermission(module, "edit")) ? */}
            <Tooltip title="Edit">
              <Button type="default"
                onClick={() => this.handleUpdateButton(record)}
              >
                <Icon type="edit" />
              </Button>
            </Tooltip>
            {/* : ''
            } */}
            &nbsp;&nbsp;
            {/* <Divider type="vertical" /> */}
            {/* {(checkPermission(module, "delete")) ? */}
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => this.handleDeleteButton(record)}
              okText="Proceed"
              cancelText="Cancel"
            // disabled={!record.is_deletable}
            >
              <Tooltip title="Delete">
                <Button
                  type="danger"
                // disabled={!record.is_deletable}
                >
                  <Icon type="delete" />
                </Button>
              </Tooltip>
            </Popconfirm>
            {/* : ''
            } */}
          </span>
        ),
        // render: (value) => <div>
        //   <Icon type="edit" theme="filled" className='action-icon'/>
        //   <Icon type="delete" theme="filled" className='action-icon'/>
        // </div>
      },
    ];

    return (
      <Layout className="raffle-item-container">
        <Content className="raffle-item-content" style={{ padding: "20px 60px" }}>
          <PageHeader rightPanel={checkPermission(module, "create") ? <Button type="primary" loading={isLoading} onClick={this.handleCreateButton}><Icon type="plus" />Add Prize</Button> : ''} />

          <Row type="flex" gutter={30}>
            <Col span={24}>
              <Table columns={columns} dataSource={rPrizesData} loading={isLoading} rowKey={"id"} pagination={pagination} onChange={this.handleTableChange} />
            </Col>
          </Row>
        </Content>
        <RaffleItemModal showModal={showRaffleItemModal} selectedRow={selectedRow} handleCloseModal={this.handleCloseModal} {...this.props} getData={this.getData} />
      </Layout>
    );
  }
}

const RaffleItemForm = Form.create({ name: "raffle-item-form" })(RaffleItem);

export default RaffleItemForm;
