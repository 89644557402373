import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "antd";
import { updateProfile, updatePassword } from "./actions";
import Page from "./Page";

class OverviewCard extends Component {
  render() {
    return <Page {...this.props} />;
  }
}

const OverviewCardForm = Form.create({ name: "overview-form" })(OverviewCard);

function mapStateToProps(state) {
  return {
    isLoading: state.profile.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onUpdateProfile: (data) => dispatch(updateProfile(data)),
    onUpdatePassword: (data) => dispatch(updatePassword(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OverviewCardForm);
