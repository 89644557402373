import React, { Component } from "react";
import { Form, Modal, message, Layout, Button, Row, Col, Input, Spin, Select, Icon } from "antd";
import "./index.scss";
import { EditorState, convertToRaw, ContentState, Modifier } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { notify, notifyFormError } from "../../../shared/Notification";

const { Content } = Layout;

const html = '';
const contentBlock = htmlToDraft(html);
const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
const editorState = EditorState.createWithContent(contentState);

class TicketModal extends Component {
  initialState = {
    showModal: false,
    selectedRow: {},
    emailContent: editorState
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
    this.contentEditor = React.createRef();
  }

  handleCloseModal = () => {
    this.setState({
      showModal: false,
      selectedRow: {}
    });
    this.props.form.resetFields();
    this.props.handleCloseModal();
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.showModal !== nextProps.showModal) {
      this.setState({ showModal: nextProps.showModal });
    }

    if (this.props.selectedRow !== nextProps.selectedRow) {
      if (nextProps.selectedRow.content && nextProps.selectedRow.content.trim() != "") {
        const contentBlock = htmlToDraft(nextProps.selectedRow.content);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          const contentTextState = EditorState.createWithContent(contentState);
          this.setState({
            emailContent: contentTextState,
          });
        }
      }

      this.setState({ selectedRow: nextProps.selectedRow });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { selectedRow, emailContent } = this.state;

    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const params = {
          ...values,
        };

        if (params.status == "Active") {
          params.status = 1;
        } else if (params.status == "Inactive") {
          params.status = 0;
        }

        if (params.auto_send == "Off") {
          params.auto_send = 0;
        } else if (params.auto_send == "All") {
          params.auto_send = 1;
        } else if (params.auto_send == "Site Only") {
          params.auto_send = 2;
        } else {
          params.auto_send = 0;
        }

        params.content = draftToHtml(convertToRaw(emailContent.getCurrentContent()))

        params.event_id = this.props.event.id;
        params.id = selectedRow.id;
        await this.props
          .onUpdateEmailTemplate(params)
          .then((response) => {
            if (response.response.data.status === "success") {
              notify('success', 'Successfuly updated email template');
              this.handleCloseModal();
            } else {
              notify('error', response.response.data.message);
            }
          })
          .catch(error => {
            notify('error', error.message);
          });
      } else {
        notifyFormError(err);
      }
    });
  }

  convertAutoSend = value => {
    let returnData = "";
    switch (value) {
      case 0:
        returnData = "Off";
        break;

      case 1:
        returnData = "All";
        break;

      case 2:
        returnData = "Site Only";
        break;

      default:
        break;
    }

    return returnData;
  }

  handleContentChange = value => {
    this.setState({
      emailContent: value
    });
  }

  render() {
    const { showModal, selectedRow, emailContent } = this.state;
    const { isLoading, form: { getFieldDecorator }, } = this.props;

    return (
      <Modal
        title={selectedRow.id ? 'Update Email Template' : 'Create Email Template'}
        visible={showModal}
        onCancel={this.handleCloseModal}
        centered={true}
        footer={null}
        closable={false}
        maskClosable={false}
        width={"80%"}
        destroyOnClose={true}
      >
        <Layout className="overview-container">
          <Content className="overview-content" style={{ padding: "10px" }}>
            <Spin spinning={isLoading} key="create-event">
              <Form onSubmit={this.handleSubmit} style={{ width: "100%" }} disabled={isLoading}>
                <Row type="flex" gutter={30}>
                  <Col span={16}>
                    <Form.Item label="Title">
                      {getFieldDecorator("title", {
                        rules: [
                          {
                            required: true,
                            message: "Please enter Title",
                          },
                        ],
                        initialValue: selectedRow.id ? selectedRow.title : ""
                      })(<Input />)}
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item label="Auto Send">
                      {getFieldDecorator("auto_send", {
                        rules: [
                          {
                            required: true,
                            message: "Please select value",
                          },
                        ],
                        initialValue: selectedRow.id ? this.convertAutoSend(selectedRow.auto_send) : ""
                      })(
                        <Select>
                          <Select.Option value="Off">Off</Select.Option>
                          <Select.Option value="All">All</Select.Option>
                          <Select.Option value="Site Only">Site Only</Select.Option>
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row type="flex" gutter={30}>
                  <Col span={24}>
                    <Form.Item label="Content">
                      {getFieldDecorator("content", {
                        rules: [
                          {
                            required: true,
                            message: "Please select Content",
                          },
                        ],
                        initialValue: selectedRow.id ? selectedRow.content : ""
                      })(<Editor
                        ref={this.contentEditor}
                        editorState={emailContent}
                        wrapperClassName="wysiwyg-editor"
                        editorClassName="wysiwyg-editor-textarea"
                        onEditorStateChange={this.handleContentChange}
                        toolbarCustomButtons={[<CustomOption />]}
                      />)}
                    </Form.Item>
                  </Col>
                </Row>

                <Row type="flex" gutter={30}>
                  <Col span={24} align="right">
                    <Form.Item>
                      <Button
                        type="default"
                        disabled={isLoading}
                        loading={isLoading}
                        onClick={this.handleCloseModal}
                      >
                        <Icon type="close" />
                        Close
                      </Button>
                      &nbsp;&nbsp;
                      <Button
                        type="primary"
                        htmlType="submit"
                        disabled={isLoading}
                        loading={isLoading}
                      >
                        <Icon type="save" />
                        Save
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </Content>
        </Layout >
      </Modal>
    );
  }
}

const defaultTags = [
  {
    value: 'event-name',
    label: 'Event Name'
  },
  {
    value: 'date-start',
    label: 'Date Start'
  },
  {
    value: 'date-end',
    label: 'Date End'
  },
  {
    value: 'timezone',
    label: 'Timezone'
  },
  {
    value: 'ticket',
    label: 'Ticket'
  },
  {
    value: 'qr-code',
    label: 'QR Code'
  },
  {
    value: 'address1',
    label: 'Address1'
  },
  {
    value: 'address2',
    label: 'Address2'
  },
  {
    value: 'address3',
    label: 'Address3'
  }

];

class CustomOption extends Component {
  initialState = {
    selectedTag: '',
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  handleAddTag = () => {
    const { editorState, onChange } = this.props;
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      "{{" + this.state.selectedTag + "}}", //'⭐',
      editorState.getCurrentInlineStyle(),
    );
    onChange(EditorState.push(editorState, contentState, 'insert-characters'));
  };

  handleSelectTag = value => {
    this.setState({ selectedTag: value });
  }

  render() {
    const tags = defaultTags;
    tags.sort((a, b) => {
      let la = a.label.toLowerCase(),
        lb = b.label.toLowerCase();

      if (la < lb) {
        return -1;
      }
      if (la > lb) {
        return 1;
      }
      return 0;
    });

    return (
      <div style={{ display: "flex", margin: "0 10px" }}>
        <Select onChange={this.handleSelectTag} style={{ width: "150px" }}>
          {tags.map((row, index) => (
            <Select.Option value={row.value} key={index}>{row.label}</Select.Option>
          ))}
        </Select>
        <Button onClick={this.handleAddTag} style={{ marginLeft: "5px" }}><Icon type="plus" />Add Tag</Button>
      </div>
    );
  }
}

const TicketModalComponent = Form.create({ name: "ticket-modal" })(TicketModal);

export default TicketModalComponent;
