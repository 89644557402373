import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "antd";
import { getEvents, createEvent } from "./actions";
import Page from "./Page";

class LayoutHeaderCard extends Component {
  render() {
    return <Page {...this.props} />;
  }
}

const LayoutHeaderForm = Form.create({ name: "layout-header-form" })(LayoutHeaderCard);

function mapStateToProps(state) {
  return {
    isLoading: state.header.isLoading,
    eventsData: state.header.eventsData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onGetEvents: (data) => dispatch(getEvents(data)),
    onCreateEvent: (data) => dispatch(createEvent(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutHeaderForm);
