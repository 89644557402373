import React, { Component } from "react";
import { Form, Layout, Button, Row, Col, Popconfirm, Table, Tag, message, Icon, Tooltip } from "antd";
import "./index.scss";
import CreateUpdateModal from "./components/CreateUpdateModal";
import { checkPermission, slugify } from "../../utils/helper";
import { Redirect } from "react-router-dom";
import PageHeader from "../shared/PageHeader";
import { notify } from "../shared/Notification"

const { Content } = Layout;

class Ticket extends Component {
  initialState = {
    ticketsData: [],
    showCreateUpdateModal: false,
    selectedRow: {}
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  handleCreateButton = () => {
    this.setState({
      showCreateUpdateModal: true
    });
  };

  handleUpdateButton = (row) => {
    this.setState({
      showCreateUpdateModal: true,
      selectedRow: row
    });
  };

  handleDeleteButton = async (row) => {
    await this.props.onDeleteTicket(row);
    notify('success', 'Deleted Ticket: ' + row.ticket_name);
  }

  handleCloseModal = () => {
    this.setState({
      showCreateUpdateModal: false,
    });
  };

  componentDidMount() {
    const onGetParam = {
      event_id: this.props.event.id
    };
    this.props.onGetTickets(onGetParam);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.ticketsData !== nextProps.ticketsData) {
      this.setState({ ticketsData: nextProps.ticketsData });
    }
  }

  handleGenerateUrl = (row) => {
    const slug = this.props.event.page_details.event_slug;
    const queryString = "?ticket=" + row.id + "." + slugify(row.ticket_name);
    return process.env.REACT_APP_PORTAL_URL + "event/" + slug + queryString;
  }

  handleGoToLink = (row) => {
    window.open(this.handleGenerateUrl(row), "_blank");
  }

  handleCopyLink = (row) => {
    const url = this.handleGenerateUrl(row);
    navigator.clipboard.writeText(url);
    notify('info', 'Copied link to the clipboard \n ' + url);
  }

  render() {
    const { ticketsData, showCreateUpdateModal, selectedRow } = this.state;
    const { isLoading, } = this.props;
    const module = "ticket";

    if (!checkPermission(module, "view")) {
      return (<Redirect to="/403" />);
    }

    const columns = [
      {
        title: 'Ticket Name',
        dataIndex: 'ticket_name',
        key: 'ticket_name',
        render: (ticket_name, row) => (
          <span>
            {ticket_name}
            {
              row.description ?
                <Tooltip title={row.description}>
                  &nbsp;<Icon type="info-circle" theme="filled" className="info-font-color" />
                </Tooltip>
                : ''
            }
          </span>
        ),
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        align: 'center',
      },
      {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
        align: 'right',
      },
      {
        title: 'Count',
        dataIndex: 'issue_count',
        key: 'issue_count',
        align: 'right',
      },
      {
        title: 'Max Avail',
        dataIndex: 'max_avail',
        key: 'max_avail',
        align: 'right',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        render: status => (
          <span>
            {status == 1 ?
              (
                <Tag color='green' key='active'>
                  Active
                </Tag>
              ) : (
                <Tag color='gray' key='inactive'>
                  Inactive
                </Tag>
              )
            }
          </span>
        ),
      },
      {
        title: 'Action',
        key: 'action',
        align: 'center',
        render: (text, record) => (
          <span>
            {(checkPermission(module, "edit")) ?
              <Tooltip title="Edit">
                <Button className="button-icon" onClick={() => this.handleUpdateButton(record)}><Icon type="edit" /></Button>
              </Tooltip> : ''
            }
            &nbsp;&nbsp;
            {(checkPermission(module, "delete")) ?
              <Popconfirm
                title="Are you sure you want to delete?"
                onConfirm={() => this.handleDeleteButton(record)}
                okText="Proceed"
                cancelText="Cancel"
                disabled={record.registration.length > 0}
              >
                <Tooltip title="Delete">
                  <Button className="button-icon" disabled={record.registration.length > 0}>
                    <Icon type="delete" />
                  </Button>
                </Tooltip>
              </Popconfirm> : ''
            }
            &nbsp;&nbsp;
            <Tooltip title="Go to link">
              <Button className="button-icon" onClick={() => this.handleGoToLink(record)} placeholder="Go to link"><Icon type="export" /></Button>
            </Tooltip>
            &nbsp;&nbsp;
            <Tooltip title="Copy link to clipboard">
              <Button className="button-icon" onClick={() => this.handleCopyLink(record)} placeholder="Copy link to clipboard"><Icon type="paper-clip" /></Button>
            </Tooltip>
          </span>
        ),
      },
    ];

    return (
      <Layout className="ticket-container">
        <Content className="ticket-content" style={{ padding: "20px 60px" }}>
          <PageHeader
            rightPanel={checkPermission(module, "create") ? <Button type="primary" loading={isLoading} onClick={this.handleCreateButton}><Icon type="plus" />Add Ticket</Button> : ''}
          />

          <Row type="flex" gutter={30}>
            <Col span={24}>
              <Table columns={columns} dataSource={ticketsData} loading={isLoading} rowKey={"id"} />
            </Col>
          </Row>
        </Content>

        <CreateUpdateModal showModal={showCreateUpdateModal} selectedRow={selectedRow} handleCloseModal={this.handleCloseModal} {...this.props} />
      </Layout>
    );
  }
}

const TicketForm = Form.create({ name: "ticket-form" })(Ticket);

export default TicketForm;
