import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "antd";
import { getEmailTemplates, updateEmailTemplate } from "./actions";
import Page from "./Page";
import { getEvent } from "../../utils/token";

class EmailTemplateCard extends Component {
  render() {
    return <Page {...this.props} />;
  }
}

const EmailTemplateForm = Form.create({ name: "email-tempalte-form" })(EmailTemplateCard);

function mapStateToProps(state) {
  return {
    isLoading: state.emailTemplate.isLoading,
    emailTemplatesData: state.emailTemplate.emailTemplatesData,
    emailTemplateData: state.emailTemplate.emailTemplatesData,
    event: getEvent()
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onGetEmailTemplates: (data) => dispatch(getEmailTemplates(data)),
    onUpdateEmailTemplate: (data) => dispatch(updateEmailTemplate(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplateForm);
