import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "antd";
import { getRegistrationFields, createRegistrationField, updateRegistrationField, deleteRegistrationField } from "./actions";
import Page from "./Page";
import { getEvent } from "../../utils/token";

class RegistrationFieldCard extends Component {
  render() {
    return <Page {...this.props} />;
  }
}

const RegistrationFieldForm = Form.create({ name: "registration-field-form" })(RegistrationFieldCard);

function mapStateToProps(state) {
  return {
    isLoading: state.registrationField.isLoading,
    registrationFieldsData: state.registrationField.registrationFieldsData,
    registrationFieldData: state.registrationField.registrationFieldData,
    event: getEvent()
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onGetRegistrationFields: (data) => dispatch(getRegistrationFields(data)),
    onCreateRegistrationField: (data) => dispatch(createRegistrationField(data)),
    onUpdateRegistrationField: (data) => dispatch(updateRegistrationField(data)),
    onDeleteRegistrationField: (data) => dispatch(deleteRegistrationField(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationFieldForm);
