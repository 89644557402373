const initialData = {
  isLoading: false,
  ticketSalesData: [],
  ticketSaleData: {},
};

export default function admin(state = initialData, action) {
  switch (action.type) {
    case "GET_TICKET_SALES_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_TICKET_SALES_SUCCESS":
      return {
        ...state,
        ticketSalesData: action.payload.data,
        isLoading: false,
      };
    case "GET_TICKET_SALES_FAILED":
      return {
        ...state,
        ticketSalesData: [],
        isLoading: false,
      };

    case "UPDATE_PAYMENT_STATUS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "UPDATE_PAYMENT_STATUS_SUCCESS":
      return {
        ...state,
        ticketSaleData: action.payload.data,
        isLoading: false,
      };
    case "UPDATE_PAYMENT_STATUS_FAILED":
      return {
        ...state,
        ticketSaleData: {},
        isLoading: false,
      };

    default:
      return {
        ...state,
      };
  }
}
