import Axios from "../../../utils/axios";

export const updateProfile = (data) => {
    return (dispatch) => {
        return Axios
            .post("/profile", dispatch, "UPDATE_PROFILE", data)
            .then((response) => {
                return response;
            });
    };
};

export const updatePassword = (data) => {
    return (dispatch) => {
        return Axios
            .post("/profile/password", dispatch, "UPDATE_PASSWORD", data)
            .then((response) => {
                return response;
            });
    };
};