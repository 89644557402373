import React, { Component, PureComponent } from "react";
import { Spin, Form, Layout, Row, Col, Checkbox, DatePicker, Button, Icon } from "antd";
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import "./index.scss";
import { checkPermission } from "../../utils/helper";
import { Redirect } from "react-router-dom";
import moment from "moment";
import PageHeader from "../shared/PageHeader";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

const { Content } = Layout;
const { RangePicker } = DatePicker;
const COLORS = ["#7eb0d5", "#fd7f6f", "#b2e061", "#bd7ebe", "#ffb55a", "#ffee65", "#beb9db", "#fdcce5", "#8bd3c7"];

class TicketSales extends Component {
  initialState = {
    ticketSalesData: [],
    ticketFilter: null,
    paymentStatusFilter: null,
    paymentModeFilter: null,
    selectedTicket: [],
    selectedPaymentStatus: [],
    selectedPaymentMode: [],
    initialTrigger: false,
    selectedDateRange: [moment().subtract(7, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
    ticketQuantityData: [],
    ticketPriceData: [],
    paymentStatusQuantityData: [],
    paymentStatusPriceData: [],
    paymentModeQuantityData: [],
    paymentModePriceData: [],
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  handleCloseModal = () => {
    this.setState({
      showCreateUpdateModal: false,
    });
  };

  componentDidMount = async () => {
    const onGetParam = {
      event_id: this.props.event.id
    };
    await this.props.onGetSalesReportFilters(onGetParam);
    // this.handleGetSalesReport({});
  }

  componentWillReceiveProps = async (nextProps) => {
    if (this.props.ticketQuantityData !== nextProps.ticketQuantityData) {
      this.setState({ ticketQuantityData: nextProps.ticketQuantityData });
    }

    if (this.props.ticketPriceData !== nextProps.ticketPriceData) {
      this.setState({ ticketPriceData: nextProps.ticketPriceData });
    }

    if (this.props.paymentStatusQuantityData !== nextProps.paymentStatusQuantityData) {
      this.setState({ paymentStatusQuantityData: nextProps.paymentStatusQuantityData });
    }

    if (this.props.paymentStatusPriceData !== nextProps.paymentStatusPriceData) {
      this.setState({ paymentStatusPriceData: nextProps.paymentStatusPriceData });
    }

    if (this.props.paymentModeQuantityData !== nextProps.paymentModeQuantityData) {
      this.setState({ paymentModeQuantityData: nextProps.paymentModeQuantityData });
    }

    if (this.props.paymentModePriceData !== nextProps.paymentModePriceData) {
      this.setState({ paymentModePriceData: nextProps.paymentModePriceData });
    }

    const filter = {};
    if (this.props.ticketFilter !== nextProps.ticketFilter) {
      const selectedTicket = [];
      nextProps.ticketFilter.forEach(row => {
        selectedTicket.push(row.id);
      });
      filter.tickets = selectedTicket;
      this.setState({
        ticketFilter: nextProps.ticketFilter,
        selectedTicket: selectedTicket
      });
    }

    if (this.props.paymentStatusFilter !== nextProps.paymentStatusFilter) {
      const selectedPaymentStatus = [];
      nextProps.paymentStatusFilter.forEach((row, index) => {
        selectedPaymentStatus.push(index);
      });
      filter.payment_status = selectedPaymentStatus;
      this.setState({
        paymentStatusFilter: nextProps.paymentStatusFilter,
        selectedPaymentStatus: selectedPaymentStatus
      });
    }

    if (this.props.paymentModeFilter !== nextProps.paymentModeFilter) {
      filter.payment_modes = nextProps.paymentModeFilter;
      this.setState({
        paymentModeFilter: nextProps.paymentModeFilter,
        selectedPaymentMode: nextProps.paymentModeFilter
      });
    }

    const { initialTrigger } = this.state;
    if (!initialTrigger &&
      (filter.tickets) &&
      (filter.payment_status) &&
      (filter.payment_modes)
    ) {
      this.setState({
        initialTrigger: true
      });
      await this.handleGetSalesReport(filter);
    }
  }

  onTicketFilterChange = checklist => {
    this.setState({
      selectedTicket: checklist,
    });

    this.handleGetSalesReport({ tickets: checklist });
  }

  onPaymentStatusChange = checklist => {
    this.setState({
      selectedPaymentStatus: checklist,
    });

    this.handleGetSalesReport({ payment_status: checklist });
  }

  onPaymentModeChange = checklist => {
    this.setState({
      selectedPaymentMode: checklist,
    });

    this.handleGetSalesReport({ payment_modes: checklist });
  }

  onRangePickerChange = value => {
    const dateRange = [moment(value[0]).format('YYYY-MM-DD'), moment(value[1]).format('YYYY-MM-DD')];
    this.setState({
      selectedDateRange: dateRange
    });
    this.handleGetSalesReport({ date_range: dateRange });
  }

  handleGetSalesReport = async (filters) => {
    const { selectedTicket, selectedPaymentStatus, selectedPaymentMode, selectedDateRange } = this.state;
    const onGetParam = {
      event_id: this.props.event.id,
      tickets: selectedTicket,
      payment_status: selectedPaymentStatus,
      payment_modes: selectedPaymentMode,
      date_range: selectedDateRange,
      ...filters
    };

    this.setState({
      initialTrigger: true
    });
    await this.props.onGetSalesReport(onGetParam);
  }

  generateBarChartData = (data, key) => {
    const newData = [];
    data.forEach(row => {
      let hasMatch = false;
      newData.forEach(dataRow => {
        if (dataRow.datestamp === row.datestamp) {
          hasMatch = true;
          dataRow[row.ticket_name] = row.total
        }
      });

      if (!hasMatch) {
        let pushData = { datestamp: row.datestamp };
        pushData[row[key]] = row.total;
        newData.push(pushData);
      }
    });

    return newData;
  }

  handleDownload = async () => {
    const input = document.getElementById('sales-report-container');
    await html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF("p", "mm", "a4");
        const width = pdf.internal.pageSize.getWidth();
        // const height = pdf.internal.pageSize.getHeight();
        const selectedDate = this.state.selectedDateRange;
        pdf.setFontSize(20);
        pdf.text(this.props.event.event_name, 10, 10)
        pdf.setFontSize(15);
        pdf.text('Sales Report', 10, 18)
        pdf.setFontSize(10);
        pdf.text('Date: ' + selectedDate[0] + ' to ' + selectedDate[1], 10, 23)
        pdf.addImage(imgData, 'JPEG', 0, 30, width, 228.6);
        // pdf.output('dataurlnewwindow');
        pdf.save("sales-report.pdf");
      });
  }

  render() {
    const { ticketFilter, paymentStatusFilter, paymentModeFilter, selectedTicket, selectedPaymentStatus, selectedPaymentMode, selectedDateRange,
      ticketQuantityData,
      ticketPriceData,
      paymentStatusQuantityData,
      paymentStatusPriceData,
      paymentModeQuantityData,
      paymentModePriceData, } = this.state;
    const { isLoading } = this.props;
    const module = "ticket-sales";
    const dateFormat = 'YYYY/MM/DD';

    if (!checkPermission(module, "view")) {
      return (<Redirect to="/403" />);
    }

    if (!ticketFilter && !paymentStatusFilter && !paymentModeFilter) {
      return (
        <Layout className="ticket-sales-container">
          <Content className="ticket-sales-content" style={{ padding: "20px 60px" }}>
            <Spin spinning={this.props.isLoading}></Spin>
          </Content>
        </Layout>);
    }

    const data = [
      {
        name: 'Page A',
        uv: 4000,
        pv: 2400,
        amt: 2400,
      },
      {
        name: 'Page B',
        uv: 3000,
        pv: 1398,
        amt: 2210,
      },
      {
        name: 'Page C',
        uv: 2000,
        pv: 9800,
        amt: 2290,
      },
      {
        name: 'Page D',
        uv: 2780,
        pv: 3908,
        amt: 2000,
      },
      {
        name: 'Page E',
        uv: 1890,
        pv: 4800,
        amt: 2181,
      },
      {
        name: 'Page F',
        uv: 2390,
        pv: 3800,
        amt: 2500,
      },
      {
        name: 'Page G',
        uv: 3490,
        pv: 4300,
        amt: 2100,
      },
    ];

    const ticketQuantity = this.generateBarChartData(ticketQuantityData, 'ticket_name');
    const ticketPrice = this.generateBarChartData(ticketPriceData, 'ticket_name');
    const paymentStatusQuantity = this.generateBarChartData(paymentStatusQuantityData, 'status_value');
    const paymentStatusPrice = this.generateBarChartData(paymentStatusPriceData, 'status_value');
    const paymentModeQuantity = this.generateBarChartData(paymentModeQuantityData, 'payment_mode');
    const paymentModePrice = this.generateBarChartData(paymentModePriceData, 'payment_mode');

    return (
      <Layout className="ticket-sales-container">
        <Content className="ticket-sales-content" style={{ padding: "20px 60px" }}>
          <Spin spinning={this.props.isLoading}>
            <PageHeader rightPanel={
              <>
                <Button type="primary" onClick={this.handleDownload}><Icon type="file-pdf" />Download PDF</Button>
                &nbsp; &nbsp;
                <RangePicker
                  onChange={this.onRangePickerChange}
                  defaultValue={[
                    moment(selectedDateRange[0], dateFormat),
                    moment(selectedDateRange[1], dateFormat)
                  ]} format={dateFormat} />
              </>
            } />

            <Row type="flex" gutter={30} id="sales-report-container" style={{ minWidth: "1110px", maxWidth: "1110px" }}>
              <Col span={24}>
                <Row type="flex" gutter={30} style={{ marginTop: "20px", marginBottom: "60px" }}>
                  <Col span={24}>
                    <h3>Ticket</h3>
                  </Col>
                  <Col span={24}>
                    <Checkbox.Group
                      onChange={this.onTicketFilterChange}
                      style={{ width: "100%" }}
                      defaultValue={selectedTicket}
                    >
                      <Row type="flex">
                        {ticketFilter.map(row => (
                          <Col span={4} key={row.id}>
                            <Checkbox value={row.id}>{row.ticket_name}</Checkbox>
                          </Col>
                        ))}
                      </Row>
                    </Checkbox.Group>
                  </Col>

                  <Col span={12} className="chart-col">
                    <div className="bar-chart-label">Quantity By Ticket</div>
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart width="100%" height="100%" data={ticketQuantity} >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="datestamp" />
                        <YAxis />
                        <Legend />
                        <Tooltip />
                        {ticketFilter.map((row, index) => (
                          <Bar key={`cell-${index}`} type="monotone" dataKey={row.ticket_name} stackId={index} stroke={COLORS[index % COLORS.length]} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </BarChart>
                    </ResponsiveContainer>
                  </Col>

                  <Col span={12} className="chart-col">
                    <div className="bar-chart-label">Amount By Ticket</div>
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart width="100%" height="100%" data={ticketPrice}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="datestamp" />
                        <YAxis />
                        <Legend />
                        <Tooltip />
                        {ticketFilter.map((row, index) => (
                          <Bar key={`cell-${index}`} type="monotone" dataKey={row.ticket_name} stackId={index} stroke={COLORS[index % COLORS.length]} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </BarChart>
                    </ResponsiveContainer>
                  </Col>
                </Row>

                <Row type="flex" gutter={30} style={{ marginTop: "20px", marginBottom: "60px" }}>
                  <Col span={24}>
                    <h3>Payment Status</h3>
                  </Col>
                  <Col span={24}>
                    <Checkbox.Group
                      onChange={this.onPaymentStatusChange}
                      style={{ width: "100%" }}
                      defaultValue={selectedPaymentStatus}
                    >
                      <Row type="flex">
                        {paymentStatusFilter.map((row, index) => (
                          <Col span={4} key={index}>
                            <Checkbox value={index}>{row}</Checkbox>
                          </Col>
                        ))}
                      </Row>
                    </Checkbox.Group>
                  </Col>

                  <Col span={12} className="chart-col">
                    <div className="bar-chart-label">Quantity By Payment Status</div>
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart width="100%" height="100%" data={paymentStatusQuantity}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="datestamp" />
                        <YAxis />
                        <Legend />
                        <Tooltip />
                        {paymentStatusFilter.map((row, index) => (
                          <Bar key={`cell-${index}`} type="monotone" dataKey={row} stackId={index} stroke={COLORS[index % COLORS.length]} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </BarChart>
                    </ResponsiveContainer>
                  </Col>

                  <Col span={12} className="chart-col">
                    <div className="bar-chart-label">Amount By Payment Status</div>
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart width="100%" height="100%" data={paymentStatusPrice}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="datestamp" />
                        <YAxis />
                        <Legend />
                        <Tooltip />
                        {paymentStatusFilter.map((row, index) => (
                          <Bar key={`cell-${index}`} type="monotone" dataKey={row} stackId={index} stroke={COLORS[index % COLORS.length]} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </BarChart>
                    </ResponsiveContainer>
                  </Col>
                </Row>

                <Row type="flex" gutter={30} style={{ marginTop: "20px", marginBottom: "60px" }}>
                  <Col span={24}>
                    <h3>Mode</h3>
                  </Col>
                  <Col span={24}>
                    <Checkbox.Group
                      onChange={this.onPaymentModeChange}
                      style={{ width: "100%" }}
                      defaultValue={selectedPaymentMode}
                    >
                      <Row type="flex">
                        {paymentModeFilter.map(row => (
                          <Col span={4} key={row}>
                            <Checkbox value={row}>{row}</Checkbox>
                          </Col>
                        ))}
                      </Row>
                    </Checkbox.Group>
                  </Col>

                  <Col span={12} className="chart-col">
                    <div className="bar-chart-label">Quantity By Mode</div>
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart width="100%" height="100%" data={paymentModeQuantity}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="datestamp" />
                        <YAxis />
                        <Legend />
                        <Tooltip />
                        {paymentModeFilter.map((row, index) => (
                          <Bar key={`cell-${index}`} type="monotone" dataKey={row} stackId={index} stroke={COLORS[index % COLORS.length]} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </BarChart>
                    </ResponsiveContainer>
                  </Col>

                  <Col span={12} className="chart-col">
                    <div className="bar-chart-label">Amount By Mode</div>
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart width="100%" height="100%" data={paymentModePrice}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="datestamp" />
                        <YAxis />
                        <Legend />
                        <Tooltip />
                        {paymentModeFilter.map((row, index) => (
                          <Bar key={`cell-${index}`} type="monotone" dataKey={row} stackId={index} stroke={COLORS[index % COLORS.length]} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </BarChart>
                    </ResponsiveContainer>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Spin>
        </Content >
      </Layout >
    );
  }
}

const TicketSalesForm = Form.create({ name: "ticket-sales-form" })(TicketSales);

export default TicketSalesForm;
