import React, { Component } from "react";
import { Form, Modal, Layout, Button, Row, Col, Input, Icon } from "antd";
import "./index.scss";
import { notify, notifyFormError } from "../../../shared/Notification";

const { Content } = Layout;

class ChangePasswordModal extends Component {
  initialState = {
    showModal: false,
    show: {
      current: false,
      new: false,
      confim: false,
    }
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  handleCloseModal = () => {
    this.setState({
      showModal: false,
    });
    this.props.form.resetFields();
    this.props.handleCloseModal();
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.showModal !== nextProps.showModal) {
      this.setState({
        showModal: nextProps.showModal,
      });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { selectedRow, settingsData } = this.state;

    this.props.form.validateFields(['current_password', 'new_password', 'confirm_password'], async (err, values) => {
      if (!err) {
        if (values.new_password !== values.confirm_password) {
          notify('error', "New and confirm password does not match");
          return;
        }

        const params = {
          current_password: values?.current_password,
          new_password: values?.new_password,
        }

        await this.props
          .onUpdatePassword(params)
          .then((response) => {
            if (response.response.data.status === "success") {
              notify('success', 'Updated raffle settings');
              this.handleCloseModal();
            } else {
              notify('error', response.response.data.message);
            }
          })
          .catch(error => {
            notify('error', error.message);
            console.log("error", error);
          });
      } else {
        notifyFormError(err);
        console.log("err", err);
      }
    });
  }

  render() {
    const { showModal } = this.state;
    const { isLoading, form: { getFieldDecorator } } = this.props;

    return (
      <Modal
        title={'Change Password'}
        visible={showModal}
        onCancel={this.handleCloseModal}
        centered={true}
        footer={null}
        closable={false}
        maskClosable={false}
        width={"30%"}
      >
        <Layout className="overview-container">
          <Content className="overview-content" style={{ padding: "10px" }}>
            <Form onSubmit={this.handleSubmit} style={{ width: "100%" }} disabled={isLoading}>
              <Row type="flex" gutter={30}>
                <Col span={24}>
                  <Form.Item label="Current Password">
                    {getFieldDecorator("current_password", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter Current Password",
                        }
                      ],
                      initialValue: ""
                    })(<Input.Password />)}
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item label="New Password">
                    {getFieldDecorator("new_password", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter New Password",
                        }
                      ],
                      initialValue: ""
                    })(<Input.Password />)}
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item label="Confirm Password">
                    {getFieldDecorator("confirm_password", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter Confirm Password",
                        },
                      ],
                      initialValue: ""
                    })(<Input.Password />)}
                  </Form.Item>
                </Col>
              </Row>

              <Row type="flex" gutter={30}>
                <Col span={24} align="right">
                  <Form.Item>
                    <Button
                      type="default"
                      disabled={isLoading}
                      loading={isLoading}
                      onClick={this.handleCloseModal}
                    >
                      <Icon type="close" />
                      Close
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={isLoading}
                      loading={isLoading}
                    >
                      <Icon type="save" />
                      Save
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Content>
        </Layout >
      </Modal >
    );
  }
}

const ChangePasswordModalComponent = Form.create({ name: "change-password-modal" })(ChangePasswordModal);

export default ChangePasswordModalComponent;
