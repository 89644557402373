import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "antd";
import { getUserRoles, createUserRole, updateUserRole, deleteUserRole } from "./actions";
import Page from "./Page";
import { getEvent } from "../../utils/token";

class UserRoleCard extends Component {
  render() {
    return <Page {...this.props} />;
  }
}

const UserRoleForm = Form.create({ name: "user-form" })(UserRoleCard);

function mapStateToProps(state) {
  return {
    isLoading: state.userRole.isLoading,
    userRolesData: state.userRole.userRolesData,
    userRoleData: state.userRole.userRoleData,
    roleAccessData: state.userRole.roleAccessData,
    event: getEvent()
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onGetUserRoles: (data) => dispatch(getUserRoles(data)),
    onCreateUserRole: (data) => dispatch(createUserRole(data)),
    onUpdateUserRole: (data) => dispatch(updateUserRole(data)),
    onDeleteUserRole: (data) => dispatch(deleteUserRole(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserRoleForm);
