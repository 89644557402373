const initialData = {
  isLoading: false,
  eventData: {},
};

export default function admin(state = initialData, action) {
  switch (action.type) {
    case "UPDATE_EVENT_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "UPDATE_EVENT_SUCCESS":
      return {
        ...state,
        eventData: action.payload.data,
        isLoading: false,
      };
    case "UPDATE_EVENT_FAILED":
      return {
        ...state,
        eventData: {},
        isLoading: false,
      };

    default:
      return {
        ...state,
      };
  }
}
