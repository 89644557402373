import React, { Component } from "react";
import { Form, Modal, Layout, Button, Row, Col, Input, DatePicker, Select, message, Spin, Icon, Tooltip, TimePicker, Checkbox } from "antd";
import "../index.scss";
import moment from "moment";

const { Content } = Layout;
const { Option } = Select;

class RaffleCollectionModal extends Component {
    initialState = {
        showModal: false,
        selectedRow: {},
        defaultCondition: {
            type: "",
            conditions: {
                condition: "",
                value: ""
            },
        },
        conditions: [{
            type: "",
            conditions: {
                condition: "",
                value: ""
            },
        }],
        conditionTypes: [""],
        filterTypes: ["Login", "Passport Complete", "Passport Count", "Ticket"],
        conditionOptions: [
            {
                value: "Before",
                for: ["Login"]
            },
            {
                value: "After",
                for: ["Login"]
            },
            {
                value: "Between",
                for: ["Login"]
            },
            {
                value: "Equal",
                for: ["Passport Complete", "Passport Count", "Ticket"]
            },
            {
                value: "Not Equal",
                for: ["Passport Complete", "Passport Count", "Ticket"]
            },
            {
                value: "Has",
                for: ["Passport Complete"]
            },
            {
                value: "Has Not",
                for: ["Passport Complete"]
            },
            {
                value: "Less Than",
                for: ["Passport Count"]
            },
            {
                value: "Less Than Or Equal",
                for: ["Passport Count"]
            },
            {
                value: "Greater Than",
                for: ["Passport Count"]
            },
            {
                value: "Greater Than Or Equal",
                for: ["Passport Count"]
            },
        ],
    };

    constructor(props) {
        super(props);
        this.state = this.initialState;
    }

    handleCloseModal = () => {
        this.setState({
            showModal: false,
            selectedRow: {},
            conditions: [{
                type: "",
                conditions: {
                    condition: "",
                    value: ""
                },
            }]
        });
        this.props.form.resetFields();
        this.props.handleCloseModal();
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const { conditions } = this.state
        const { selectedRow } = this.state;

        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                const event = this.props.event;

                const params = {
                    ...values,
                    event_id: event.id,
                    filters: conditions
                };

                if (params.status == "Active") {
                    params.status = 1;
                } else if (params.status == "Inactive") {
                    params.status = 0;
                }

                if (selectedRow?.id) {
                    params.id = selectedRow.id;
                    await this.props
                        .OnUpdateCollection(params)
                        .then((response) => {
                            if (response.response.data.status === "success") {
                                message.success('Successfuly updated raffle collection');
                                this.handleCloseModal();
                            } else {
                                message.error('Error occured: ' + response.response.data.message);
                            }
                        })
                        .catch(error => {
                            console.log("error", error);
                        });
                } else {
                    await this.props
                        .OnCreateCollection(params)
                        .then((response) => {
                            if (response.response.data.status === "success") {
                                message.success('Successfuly created collection');
                                this.props.getData()
                                this.handleCloseModal();
                            }
                            else {
                                message.error('Error occured: ' + response.response.data.message);
                            }
                        })
                        .catch(error => {
                            console.log("error", error);
                        });
                }
            } else {
                console.log("error", err);
            }
        });
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.showModal !== nextProps.showModal) {
            this.setState({ showModal: nextProps.showModal });
        }

        if (this.props.selectedRow !== nextProps.selectedRow) {
            this.setState({
                selectedRow: nextProps.selectedRow,
                conditions: nextProps.selectedRow.filter
            });
        }
    }

    handleAddCondition = () => {
        const conditions = this.state.conditions;
        conditions.push(this.state.defaultCondition);

        const conditionTypes = this.state.conditionTypes;
        conditionTypes.push("");
        this.setState({ conditions, conditionTypes });
    }

    handleRemoveFilter = (type, index) => {
        const conditions = this.state.conditions.filter(row => row.type !== type);
        const conditionTypes = this.state.conditionTypes;
        conditionTypes.splice(index, 1);
        this.setState({ conditions, conditionTypes });
    }

    handleFilterTypeChange = (e, index) => {
        const conditions = this.state.conditions;
        conditions[index] = {
            ...conditions[index],
            type: e,
            conditions: {
                condition: "",
                value: ""
            },
        };

        const conditionTypes = this.state.conditionTypes;
        conditionTypes[index] = e;
        this.setState({ ...conditions, conditionTypes });
    }

    handleFilterConditionChange = (e, type) => {
        const conditions = this.state.conditions;
        conditions.forEach(row => {
            if (row.type === type) {
                row.conditions.condition = e;
            }
        });

        this.setState({ ...conditions });
    }

    handleFilterValueChange = (e, type) => {
        const conditions = this.state.conditions;
        conditions.forEach(row => {
            if (row.type === type) {
                row.conditions.value = e;
                if (row.type === "Login") {
                    row.conditions.value = moment(e).format("hh:mm A");
                }
            }
        });

        // console.log('conditions', conditions)

        this.setState({ ...conditions });
    }

    render() {
        const { showModal, selectedRow, conditions, conditionTypes, filterTypes, conditionOptions } = this.state;
        const { isLoading, form: { getFieldDecorator }, passportsData, ticketsData } = this.props;

        // console.log("conditions", conditions);
        return (
            <Modal
                title={'Create Raffle Collection'}
                visible={showModal}
                onCancel={this.handleCloseModal}
                centered={true}
                footer={null}
                closable={true}
                maskClosable={false}
                destroyOnClose={true}
                width={"50%"}>
                <Layout className="overview-container">
                    <Content className="overview-content" style={{ padding: "10px" }}>
                        <Spin spinning={isLoading} key="create-event">
                            <Form onSubmit={this.handleSubmit} style={{ width: "100%" }} disabled={isLoading}>
                                <Row type="flex" gutter={30}>
                                    <Col span={24}>
                                        <Form.Item label="Collection Name">
                                            {getFieldDecorator("name", {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Please enter Collection Name",
                                                    },
                                                ],
                                                initialValue: selectedRow.id ? selectedRow.name : ""
                                            })(<Input />)}
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row type="flex" gutter={30}>
                                    <Col span={12}>
                                        <Form.Item label="Collection">
                                            {getFieldDecorator('collection', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please select Collection'
                                                    }],
                                                initialValue: selectedRow?.id ? selectedRow.collection : ""
                                            })(
                                                <Select>
                                                    <Option value="Registrants">Registrants</Option>
                                                    <Option value="QR Code">QR Code</Option>
                                                </Select>
                                            )}
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item label="Status">
                                            {getFieldDecorator("status", {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Please select Status",
                                                    },
                                                ],
                                                initialValue: selectedRow.status == 1 ? "Active" : "Inactive"
                                            })(
                                                <Select>
                                                    <Select.Option key="1">Active</Select.Option>
                                                    <Select.Option key="0">Inactive</Select.Option>
                                                </Select>
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row type="flex" gutter={30}>
                                    <Col span={7} style={{ fontWeight: "bold" }}>Type</Col>
                                    <Col span={7} style={{ fontWeight: "bold" }}>Condition</Col>
                                    <Col span={7} style={{ fontWeight: "bold" }}>Value</Col>
                                    <Col span={3}>
                                        <Tooltip title="Add Filter">
                                            <Button className="button-icon primary-font-color" onClick={this.handleAddCondition} disabled={conditionTypes.length === filterTypes.length}>
                                                <Icon type="plus-circle" />
                                            </Button>
                                        </Tooltip>
                                    </Col>
                                </Row>

                                {conditions.map((row, index) => {
                                    // console.log('row', row)
                                    return (
                                        <Row type="flex" gutter={30} style={{ marginTop: "10px" }} key={index}>
                                            <Col span={7}>
                                                <Select onChange={e => this.handleFilterTypeChange(e, index)} initialvalue={row.type}>
                                                    {filterTypes.map((filterType) => {
                                                        return (
                                                            <Option value={filterType} key={filterType} style={{ display: conditionTypes.indexOf(filterType) === -1 ? "block" : "none" }}>{filterType}</Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Col>
                                            <Col span={7}>
                                                <Select onChange={e => this.handleFilterConditionChange(e, row.type)} initialvalue={row.conditions.condition}>
                                                    {conditionOptions.map((option) => {
                                                        return (
                                                            <Option value={option.value} key={option.value} style={{ display: option.for.indexOf(row.type) > -1 ? "block" : "none" }}>{option.value}</Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Col>
                                            <Col span={7}>
                                                {row.type === "Login" ?
                                                    <>
                                                        {row.conditions.condition === 'Between' ?
                                                            <>
                                                                <TimePicker onChange={e => this.handleFilterValueChange(e, row.type)} use12Hours format={'hh:mm A'} initialvalue={moment("2022-01-01 " + row.conditions.value)} />
                                                                <TimePicker onChange={e => this.handleFilterValueChange(e, row.type)} use12Hours format={'hh:mm A'} initialvalue={moment("2022-01-01 " + row.conditions.value)} />
                                                            </> :
                                                            <TimePicker onChange={e => this.handleFilterValueChange(e, row.type)} use12Hours format={'hh:mm A'} initialvalue={moment("2022-01-01 " + row.conditions.value)} />
                                                        }
                                                    </>
                                                    : ''}
                                                {row.type === "Passport Count" ? <Input onChange={e => this.handleFilterValueChange(e, row.type)} type="number" min={0} max={passportsData.length} initialvalue={row.conditions.value} /> : ''}
                                                {row.type === "Passport Complete" ?
                                                    <Checkbox.Group
                                                        onChange={e => this.handleFilterValueChange(e, row.type)}
                                                        style={{ width: "100%" }}
                                                        initialvalue={row.conditions.value}>
                                                        <Row>
                                                            {passportsData.map(row => {
                                                                return (
                                                                    <Col span={24} key={row.id}><Checkbox value={row.id}>{row.name}</Checkbox></Col>
                                                                );
                                                            })}
                                                        </Row>
                                                    </Checkbox.Group>
                                                    : ''}
                                                {row.type === "Ticket" ? <Checkbox.Group
                                                    onChange={e => this.handleFilterValueChange(e, row.type)}
                                                    style={{ width: "100%" }}
                                                    initialvalue={row.conditions.value}>
                                                    <Row>
                                                        {ticketsData.map(row => {
                                                            return (
                                                                <Col span={24} key={row.id}><Checkbox value={row.id}>{row.ticket_name}</Checkbox></Col>
                                                            );
                                                        })}
                                                    </Row>
                                                </Checkbox.Group>
                                                    : ''}
                                            </Col>
                                            <Col span={3}>
                                                <Tooltip title="Remove Filter">
                                                    <Button className="button-icon danger-font-color" onClick={() => this.handleRemoveFilter(conditionTypes[index], index)}>
                                                        <Icon type="close-circle" />
                                                    </Button>
                                                </Tooltip>
                                            </Col>
                                        </Row>

                                    );
                                })}
                                <Row type="flex" gutter={30} style={{ marginTop: "30px" }}>
                                    <Col span={24} align="right">
                                        <Form.Item>
                                            <Button
                                                type="default"
                                                disabled={isLoading}
                                                loading={isLoading}
                                                onClick={this.handleCloseModal}>
                                                Close
                                            </Button>
                                            &nbsp;&nbsp;
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                disabled={isLoading}
                                                loading={isLoading}>
                                                Save
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Spin>
                    </Content>
                </Layout>
            </Modal>
        );
    }
}

const RaffleCollectionModalComponent = Form.create({ name: "raffle-collection-modal" })(RaffleCollectionModal);

export default RaffleCollectionModalComponent;
