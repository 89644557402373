import React, { Component } from "react";
import { Form, Modal, message, Layout, Button, Row, Col, Input, Spin, Select, Checkbox, Icon } from "antd";
import "./index.scss";
import { parseToJson } from "../../../../utils/helper";
import { notify, notifyFormError } from "../../../shared/Notification";

const { Content } = Layout;

class UserModal extends Component {
  initialState = {
    showModal: false,
    selectedRow: {},
    eventOptions: [],
    selectedEvent: [],
    userRolesData: [],
    selectedUserRoleId: null
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  handleCloseModal = () => {
    this.setState({
      showModal: false,
      selectedRow: {},
      selectedEvent: []
    });
    this.props.form.resetFields();
    this.props.handleCloseModal();
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.showModal !== nextProps.showModal) {
      this.setState({ showModal: nextProps.showModal });
    }

    if (this.props.selectedRow !== nextProps.selectedRow) {
      this.setState({
        selectedRow: nextProps.selectedRow,
        selectedUserRoleId: nextProps.selectedRow.user_role_id,
        selectedEvent: parseToJson(nextProps.selectedRow.events)
      });
    }

    if (this.props.userRolesData !== nextProps.userRolesData) {
      this.setState({ userRolesData: nextProps.userRolesData });
    }

    if (this.props.eventsData !== nextProps.eventsData) {
      // this.setState({ eventsData: nextProps.eventsData });
      let events = [];
      nextProps.eventsData.forEach(row => {
        events.push({ label: row.event_name, value: row.id });
      });

      this.setState({ eventOptions: events });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { selectedRow, selectedEvent, selectedUserRoleId } = this.state;

    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const params = {
          ...values,
        };

        if (params.status == "Active") {
          params.status = 1;
        } else if (params.status == "Inactive") {
          params.status = 0;
        }

        if (params.is_super_admin == "Yes") {
          params.is_super_admin = 1;
        } else if (params.is_super_admin == "No") {
          params.is_super_admin = 0;
        }

        params.user_role_id = selectedUserRoleId;
        params.event_id = this.props.event.id;
        params.events = selectedEvent;
        if (selectedRow.id) {
          params.id = selectedRow.id;
          await this.props
            .onUpdateUser(params)
            .then((response) => {
              if (response.response.data.status === "success") {
                notify('success', 'Updated user: ' + response.response.data.data.username);
                this.handleCloseModal();
              } else {
                notify('error', response.response.data.message);
              }
            })
            .catch(error => {
              notify('error', error.message);
            });
        } else {
          await this.props
            .onCreateUser(params)
            .then((response) => {
              if (response.response.data.status === "success") {
                notify('success', 'Successfuly created user: ' + response.response.data.data.username);
                this.handleCloseModal();
              } else {
                notify('error', response.response.data.message);
              }
            })
            .catch(error => {
              notify('error', error.message);
            });
        }
      } else {
        notifyFormError(err);
      }
    });
  }

  onChange = checklist => {
    const { eventOptions } = this.state;

    this.setState({
      selectedEvent: checklist,
      indeterminate: !!checklist.length && checklist.length < eventOptions.length,
      checkAll: checklist.length === eventOptions.length,
    });
  };

  onCheckAllChange = e => {
    const { eventOptions } = this.state;
    this.setState({
      checkedList: e.target.checked ? eventOptions : [],
      indeterminate: false,
      checkAll: e.target.checked,
    });
  };

  onUserRoleChange = e => {
    this.setState({
      selectedUserRoleId: e
    })
  }

  render() {
    const { showModal, selectedRow, eventOptions, selectedEvent, userRolesData } = this.state;
    const { isLoading, form: { getFieldDecorator }, eventsData } = this.props;

    return (
      <Modal
        title={selectedRow.id ? 'Update User' : 'Create User'}
        visible={showModal}
        onCancel={this.handleCloseModal}
        centered={true}
        footer={null}
        closable={false}
        maskClosable={false}
        width={"50%"}
      >
        <Layout className="overview-container">
          <Content className="overview-content" style={{ padding: "10px" }}>
            <Spin spinning={isLoading} key="create-event">
              <Form onSubmit={this.handleSubmit} style={{ width: "100%" }} disabled={isLoading}>
                <Row type="flex" gutter={30}>
                  <Col span={12}>
                    <Form.Item label="Username">
                      {getFieldDecorator("username", {
                        rules: [
                          {
                            required: true,
                            message: "Please enter Username",
                          },
                        ],
                        initialValue: selectedRow.id ? selectedRow.username : ""
                      })(<Input />)}
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="Email Address">
                      {getFieldDecorator("email", {
                        rules: [
                          {
                            required: true,
                            message: "Please enter Email Address",
                          },
                        ],
                        initialValue: selectedRow.id ? selectedRow.email : ""
                      })(<Input />)}
                    </Form.Item>
                  </Col>
                </Row>

                <Row type="flex" gutter={30}>
                  <Col span={12}>
                    <Form.Item label="First Name">
                      {getFieldDecorator("first_name", {
                        rules: [
                          {
                            required: true,
                            message: "Please enter First Name",
                          },
                        ],
                        initialValue: selectedRow.id ? selectedRow.first_name : ""
                      })(<Input />)}
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="Last Name">
                      {getFieldDecorator("last_name", {
                        rules: [
                          {
                            required: true,
                            message: "Please enter Last Name",
                          },
                        ],
                        initialValue: selectedRow.id ? selectedRow.last_name : ""
                      })(<Input />)}
                    </Form.Item>
                  </Col>
                </Row>

                <Row type="flex" gutter={30}>
                  <Col span={12}>
                    <Form.Item label="User Type">
                      {getFieldDecorator("user_role_id", {
                        rules: [
                          {
                            required: true,
                            message: "Please select User Type",
                          },
                        ],
                        initialValue: selectedRow.role && selectedRow.role.role_name ? selectedRow.role.role_name : ""
                      })(
                        <Select onChange={this.onUserRoleChange}>
                          {userRolesData.map(row => (
                            <Select.Option key={row.id} value={row.id}>{row.role_name}</Select.Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="Is Super Admin">
                      {getFieldDecorator("is_super_admin", {
                        rules: [
                          {
                            required: true,
                            message: "Please select",
                          },
                        ],
                        initialValue: selectedRow.is_super_admin == 1 ? "Yes" : "No"
                      })(
                        <Select>
                          <Select.Option key="1">Yes</Select.Option>
                          <Select.Option key="0">No</Select.Option>
                        </Select>
                      )}
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="Status">
                      {getFieldDecorator("status", {
                        rules: [
                          {
                            required: true,
                            message: "Please select Status",
                          },
                        ],
                        initialValue: selectedRow.status == 1 ? "Active" : "Inactive"
                      })(
                        <Select>
                          <Select.Option key="1">Active</Select.Option>
                          <Select.Option key="0">Inactive</Select.Option>
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row type="flex" gutter={30}>
                  <Col span={24}>
                    <Form.Item label="Events">
                      {getFieldDecorator("events", {
                        rules: [
                          {
                            required: false,
                            message: "Please select Event/s",
                          },
                        ],
                        initialValue: selectedEvent
                      })(
                        <Checkbox.Group
                          // options={eventOptions}
                          onChange={this.onChange}
                        >
                          <Row>
                            {eventOptions.map(row => (
                              <Col span={12} key={row.value}>
                                <Checkbox value={row.value}>{row.label}</Checkbox>
                              </Col>
                            ))}
                          </Row>
                        </Checkbox.Group>
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row type="flex" gutter={30}>
                  <Col span={24} align="right">
                    <Form.Item>
                      <Button
                        type="default"
                        disabled={isLoading}
                        loading={isLoading}
                        onClick={this.handleCloseModal}
                      >
                        <Icon type="close" />
                        Close
                      </Button>
                      &nbsp;&nbsp;
                      <Button
                        type="primary"
                        htmlType="submit"
                        disabled={isLoading}
                        loading={isLoading}
                      >
                        <Icon type="save" />
                        Save
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </Content>
        </Layout >
      </Modal>
    );
  }
}

const UserModalComponent = Form.create({ name: "user-modal" })(UserModal);

export default UserModalComponent;
