import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "antd";

import { getCollections, createCollection, deleteCollection, updateCollection } from "./actions"
import { getEvent } from "../../utils/token";

import Page from "./Page";

class RaffleCollectionCard extends Component {
  render() {
    return <Page {...this.props} />;
  }
}

const RaffleCollectionForm = Form.create({ name: "raffle-collection-form" })(RaffleCollectionCard);

function mapStateToProps(state) {
  return {
    isLoading: state.rcollection.isLoading,
    rCollectionsData: state.rcollection.rCollectionsData,
    rCollectionData: state.rcollection.rCollectionData,
    delRCollectionData: state.rcollection.delRCollectionData,
    putRCollectionData: state.rcollection.putRCollectionData,
    passportsData: state.rcollection.passportsData,
    ticketsData: state.rcollection.ticketsData,
    event: getEvent()
  };
}

function mapDispatchToProps(dispatch) {
  return {
    OnGetCollections: (data) => dispatch(getCollections(data)),
    OnCreateCollection: (data) => dispatch(createCollection(data)),
    OnDeleteCollection: (data) => dispatch(deleteCollection(data)),
    OnUpdateCollection: (data) => dispatch(updateCollection(data))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RaffleCollectionForm);
