import Axios from "../../../utils/axios";

export const getRegistrations = (data) => {
    return (dispatch) => {
        Axios.get("/registrations", dispatch, "GET_REGISTRATIONS", data);
    };
};

export const exportRegistrations = (data) => {
    return (dispatch) => {
        Axios.get("/export-registrations", dispatch, "EXPORT_REGISTRATIONS", data)
            .then((response) => {
                window.location.assign(`${process.env.REACT_APP_S3_BUCKET}${response.response.data.url}`);
            });
    };
};

export const createRegistration = (data) => {
    return (dispatch) => {
        return Axios
            .post("/registration", dispatch, "CREATE_REGISTRATION", data)
            .then((response) => {
                if (response.response.data.status === "success") {
                    const param = {
                        event_id: response.response.data.data.event_id
                    };
                    dispatch(getRegistrations(param));
                }
                return response;
            });
    };
};

export const updateRegistration = (data) => {
    return (dispatch) => {
        return Axios
            .post("/registration/" + data.id, dispatch, "UPDATE_REGISTRATION", data)
            .then((response) => {
                if (response.response.data.status === "success") {
                    const param = {
                        event_id: response.response.data.data.event_id
                    };
                    dispatch(getRegistrations(param));
                }
                return response;
            });
    };
};

export const batchUpdateStatusRegistration = (data) => {
    return (dispatch) => {
        return Axios
            .post("/registration-status", dispatch, "UPDATE_REGISTRATION_STATUS", data)
            .then((response) => {
                if (response.response.data.status === "success" && response.response.data.data.event_id) {
                    const param = {
                        event_id: response.response.data.data.event_id
                    };
                    dispatch(getRegistrations(param));
                }
                return response;
            });
    };
};

export const deleteRegistration = (data) => {
    return (dispatch) => {
        return Axios
            .delete("/registration", dispatch, "DELETE_REGISTRATION", data)
            .then((response) => {
                if (response.response.data.status === "success") {
                    const param = {
                        event_id: data.event_id
                    };
                    dispatch(getRegistrations(param));
                }
                return response;
            });
    };
};

export const processRegistrationBatch = (data) => {
    return (dispatch) => {
        return Axios
            .post("/registration-batch", dispatch, "PROCESS_REGISTRATION_BATCH", data)
            .then((response) => {
                if (response.response.data.status === "success") {
                    const param = {
                        event_id: data.event_id
                    };
                    dispatch(getRegistrations(param));
                }
                return response;
            });
    };
};

export const emailConfirmation = (data) => {
    return (dispatch) => {
        return Axios
            .post("/email/confirmation", dispatch, "EMAIL", data)
            .then((response) => {
                if (response.response.data.status === "success") {
                    const param = {
                        event_id: data.event_id
                    };
                    dispatch(getRegistrations(param));
                }
                return response;
            });
    };
};

export const emailQrCode = (data) => {
    return (dispatch) => {
        return Axios
            .post("/email/qr-code", dispatch, "EMAIL", data)
            .then((response) => {
                if (response.response.data.status === "success") {
                    const param = {
                        event_id: data.event_id
                    };
                    dispatch(getRegistrations(param));
                }
                return response;
            });
    };
};

export const emailNotify = (data) => {
    return (dispatch) => {
        return Axios
            .post("/email/notify", dispatch, "EMAIL", data)
            .then((response) => {
                if (response.response.data.status === "success") {
                    const param = {
                        event_id: data.event_id
                    };
                    dispatch(getRegistrations(param));
                }
                return response;
            });
    };
};