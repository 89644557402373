import React, { Component } from "react";
import { Form, Modal, Layout, Button, Row, Col, Input, InputNumber, Spin, message, Select, Icon } from "antd";
import "../index.scss";
import ImageUploader from "../../shared/ImageUploader";

const { Content } = Layout;
const { Option } = Select;

class RaffleItemModal extends Component {
  initialState = {
    showModal: false,
    imagePath: "uploads/raffleitems",
    selectedRow: {}
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
    this.bgPhotoUploader = React.createRef();
    this.pbgPhotoUploader = React.createRef();
    this.pImgbgPhotoUploader = React.createRef();
  }

  handleCloseModal = () => {
    this.setState({
      showModal: false,
    });
    this.props.form.resetFields();
    this.props.handleCloseModal();
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { selectedRow } = this.state;

    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const params = {
          ...values,
        };

        params.event_id = this.props.event.id;
        if (params.status == "Active") {
          params.status = 1;
        } else if (params.status == "Inactive") {
          params.status = 0;
        }

        const prizeImg = await this.pbgPhotoUploader.current.handleUploadRequest()
        if (prizeImg) {
          params.prize_image = prizeImg.url;
        }

        if (selectedRow.id) {
          params.id = selectedRow.id;
          await this.props
            .OnUpdatePrize(params)
            .then((response) => {
              if (response.response.data.status === "success") {
                message.success('Successfuly updated raffle prize');
                this.handleCloseModal();
              } else {
                message.error('Error occured: ' + response.response.data.message);
              }
            })
            .catch(error => {
              console.log("error", error);
            });
        } else {
          await this.props
            .OnCreatePrize(params)
            .then((response) => {
              if (response.response.data.status === "success") {
                message.success('Successfuly created prize');
                this.props.getData()
                this.handleCloseModal();
              }
              else {
                message.error('Error occured: ' + response.response.data.message);
              }
            })
            .catch(error => {
              console.log("error", error);
            });
        }
      } else {
        console.log("error", err);
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.showModal !== nextProps.showModal) {
      this.setState({
        showModal: nextProps.showModal,
        imagePath: "uploads/events/" + this.props.event.id + "/raffle-prize"
      });
    }

    if (this.props.selectedRow !== nextProps.selectedRow) {

      this.setState({
        selectedRow: nextProps.selectedRow,
      });
    }
  }

  render() {
    const { showModal, imagePath } = this.state;
    const { form: { getFieldDecorator }, match, rPrizesData, selectedRow } = this.props;
    const isLoading = false;
    return (
      <Modal
        title={selectedRow.id ? 'Update Raffle Prize' : 'Create Raffle Prize'}
        visible={showModal}
        onCancel={this.handleCloseModal}
        centered={true}
        footer={null}
        closable={true}
        maskClosable={false}
        width={"50%"}
      >
        <Layout className="overview-container">
          <Content className="overview-content" style={{ padding: "10px" }}>
            <Spin spinning={isLoading} key="create-event">
              <Form onSubmit={this.handleSubmit} style={{ width: "100%" }} disabled={isLoading}>

                <Row type="flex" gutter={30}>
                  <Col span={24}>
                    <Form.Item label="Item Prize">
                      {getFieldDecorator('label', {
                        rules: [{ required: true, message: 'Please input Item Prize!' }],
                        initialValue: selectedRow.id ? selectedRow.label : ""
                      })(<Input />)}
                    </Form.Item>
                  </Col>
                </Row>

                <Row type="flex" gutter={30}>
                  <Col span={24}>
                    <Form.Item label="Description">
                      {getFieldDecorator('description', {
                        rules: [{ required: false, message: 'Please input name!' }],
                        initialValue: selectedRow.id ? selectedRow.description : ""
                      })(<Input />)}
                    </Form.Item>
                  </Col>
                </Row>

                <Row type="flex" gutter={30}>
                  <Col span={24}>
                    <Form.Item label="Image">
                      <ImageUploader
                        match={match}
                        ref={this.pbgPhotoUploader}
                        fileFolder={imagePath}
                        buttonDesc="Click here to select file to be uploaded"
                        imageUrl={selectedRow && selectedRow.prize_image ? selectedRow.prize_image : null}
                        filename={selectedRow && selectedRow.prize_image ? selectedRow.prize_image : null}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row type="flex" gutter={30}>
                  <Col span={24}>
                    <Form.Item label="Category">
                      {getFieldDecorator('name', {
                        rules: [{ required: true, message: 'Please input Category' }],
                        initialValue: selectedRow.id ? selectedRow.name : ""
                      })(
                        <Input />,
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row type="flex" gutter={30}>
                  <Col span={12}>
                    <Form.Item label="Draw Count">
                      {getFieldDecorator('draw_count', {
                        rules: [{ required: true, message: 'Please input Draw Count' }],
                        initialValue: selectedRow.id ? selectedRow.draw_count : ""
                      })(
                        <InputNumber style={{ width: '100%' }} />,
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Status">
                      {getFieldDecorator('status', {
                        rules: [{ required: true, message: 'Please select Status' }],
                        initialValue: selectedRow.status == 1 ? "Active" : "Inactive"
                      })(
                        <Select>
                          <Select.Option key="1">Active</Select.Option>
                          <Select.Option key="0">Inactive</Select.Option>
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row type="flex" gutter={30}>
                  <Col span={24} align="right">
                    <Form.Item>
                      <Button
                        type="default"
                        disabled={isLoading}
                        loading={isLoading}
                        onClick={this.handleCloseModal}
                      >
                        <Icon type="close" />
                        Close
                      </Button>
                      &nbsp;&nbsp;
                      <Button
                        type="primary"
                        htmlType="submit"
                        disabled={isLoading}
                        loading={isLoading}
                      >
                        <Icon type="save" />
                        Save
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </Content>
        </Layout>
        {/* <Row>
          <Col xs={24} className='item-form-container'>
            <Form className="item-form" onSubmit={this.handleSubmit}>
              <Row type='flex' gutter={24} justify="space-around">
                <Col xs={24} md={11} className='fields-container'>
                  <div>
                    <div className='label'>Name</div>
                    <Form.Item>
                      {getFieldDecorator('name', {
                        rules: [{ required: true, message: 'Please input name!' }],
                        initialValue: selectedRow.id ? selectedRow.name : ""
                      })(
                        <Input />,
                      )}
                    </Form.Item>
                  </div>
                  <div>
                    <div className='label'>Background</div>
                    <Form.Item>
                      <ImageUploader
                        match={match}
                        ref={this.bgPhotoUploader}
                        fileFolder={imagePath}
                        buttonDesc="Click here to select file to be uploaded"
                        imageUrl={rPrizesData && rPrizesData.background_image ? rPrizesData.background_image : null}
                        filename={rPrizesData && rPrizesData.background_image ? rPrizesData.background_image : null}
                      />
                    </Form.Item>
                  </div>
                  <div>
                    <div className='label'>Draw Count</div>
                    <Form.Item>
                      {getFieldDecorator('draw_count', {
                        rules: [{ required: true, message: 'Please input draw count!' }],
                        initialValue: selectedRow.id ? selectedRow.draw_count : ""
                      })(
                        <InputNumber style={{ width: '100%' }} />,
                      )}
                    </Form.Item>
                  </div>
                  <div>
                    <div className='label'>Status</div>
                    <Form.Item>
                      {getFieldDecorator('status', {
                        rules: [{ required: true, message: 'Please select status!' }],
                        initialValue: selectedRow.id ? selectedRow.status : ""
                      })(
                        <Select>
                          <Select.Option key={1}>Active</Select.Option>
                          <Select.Option key={0}>Inactive</Select.Option>
                        </Select>
                      )}
                    </Form.Item>
                  </div>
                </Col>
                <Col xs={24} md={11} className='fields-container'>
                  <div>
                    <div className='label'>Prize Name</div>
                    <Form.Item>
                      {getFieldDecorator('prize_name', {
                        rules: [{ required: true, message: 'Please input name!' }],
                        initialValue: selectedRow.id ? selectedRow.label : ""
                      })(
                        <Input />,
                      )}
                    </Form.Item>
                  </div>
                  <div>
                    <div className='label'>Prize Description</div>
                    <Form.Item>
                      {getFieldDecorator('prize_desc', {
                        rules: [{ required: true, message: 'Please input name!' }],
                        initialValue: selectedRow.id ? selectedRow.description : ""
                      })(
                        <Input />,
                      )}
                    </Form.Item>
                  </div>
                  <div>
                    <div className='label'>Prize Image</div>
                    <Form.Item>
                      <ImageUploader
                        match={match}
                        ref={this.pbgPhotoUploader}
                        fileFolder={imagePath}
                        buttonDesc="Click here to select file to be uploaded"
                        imageUrl={rPrizesData && rPrizesData.prize_image ? rPrizesData.prize_image : null}
                        filename={rPrizesData && rPrizesData.prize_image ? rPrizesData.prize_image : null}
                      />
                    </Form.Item>
                  </div>
                  <div>
                    <div className='label'>Prize Background</div>
                    <Form.Item>
                      <ImageUploader
                        match={match}
                        ref={this.pImgbgPhotoUploader}
                        fileFolder={imagePath}
                        buttonDesc="Click here to select file to be uploaded"
                        imageUrl={rPrizesData && rPrizesData.splash_image ? rPrizesData.splash_image : null}
                        filename={rPrizesData && rPrizesData.splash_image ? rPrizesData.splash_image : null}
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={24}>
                  <Form.Item>
                    <Button type="primary" htmlType='submit' icon="save" className='page-btn'>REFRESH & UPDATE</Button>
                </Form.Item>
                </Col >
              </Row >
            </Form >
          </Col >
        </Row > */}
      </Modal >
    );
  }
}

const RaffleItemModalComponent = Form.create({ name: "raffle-item-modal" })(RaffleItemModal);

export default RaffleItemModalComponent;
