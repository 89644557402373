const initialData = {
  isLoading: false,
};

export default function admin(state = initialData, action) {
  switch (action.type) {
    case "UPDATE_PROFILE_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "UPDATE_PROFILE_SUCCESS":
      return {
        ...state,
        isLoading: false,
      };
    case "UPDATE_PROFILE_FAILED":
      return {
        ...state,
        isLoading: false,
      };

    case "UPDATE_PASSWORD_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "UPDATE_PASSWORD_SUCCESS":
      return {
        ...state,
        isLoading: false,
      };
    case "UPDATE_PASSWORD_FAILED":
      return {
        ...state,
        isLoading: false,
      };

    default:
      return {
        ...state,
      };
  }
}
