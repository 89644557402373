import Axios from "axios";
import { headers } from "./token";
import { serialize } from "./helper";
const API_URL = process.env.REACT_APP_API_URL;

const axios = {
  //
  // @params url = string
  // @params dispatch = method - redux dispatcj
  // @params type = string - action type
  // @params data = object - optional / default value is undefined
  // @params hasHeader = boolean - optional / default value is true
  //
  get: (url, dispatch, type, data = undefined, hasHeader = true) => {
    const queryString = data !== undefined ? serialize(data) : null;
    const newUrl = `${API_URL}${url}${queryString ? queryString : ""}`;

    dispatch({
      type: `${type}_REQUEST`,
    });

    return Axios.get(newUrl, hasHeader ? { headers: headers } : {})
      .then(function (response) {
        dispatch({
          type: `${type}_SUCCESS`,
          payload: response.data,
        });
        return { isError: false, response };
      })
      .catch(function (error) {
        dispatch({
          type: `${type}_FAILED`,
          payload: error,
        });
        return { isError: true, error };
      });
  },

  //
  // @params url = string
  // @params dispatch = method - redux dispatcj
  // @params type = string - action type
  // @params data = object - optional / default value is {}
  // @params hasHeader = boolean - optional / default value is true
  //
  post: (url, dispatch, type, data = {}, hasHeader = true) => {
    dispatch({
      type: `${type}_REQUEST`,
    });
    const newUrl = `${API_URL}${url}`;

    return Axios.post(newUrl, data, hasHeader ? { headers: headers } : {})
      .then(function (response) {
        dispatch({
          type: `${type}_SUCCESS`,
          payload: response.data,
        });
        return { isError: false, response };
      })
      .catch(function (error) {
        dispatch({
          type: `${type}_FAILED`,
          payload: error,
        });
        return { isError: true, error };
      });
  },

  delete: (url, dispatch, type, data = undefined, hasHeader = true) => {
    const queryString = data !== undefined ? serialize(data) : null;
    const newUrl = `${API_URL}${url}${queryString ? queryString : ""}`;

    dispatch({
      type: `${type}_REQUEST`,
    });

    return Axios.delete(newUrl, hasHeader ? { headers: headers } : {})
      .then(function (response) {
        dispatch({
          type: `${type}_SUCCESS`,
          payload: response.data,
        });
        return { isError: false, response };
      })
      .catch(function (error) {
        dispatch({
          type: `${type}_FAILED`,
          payload: error,
        });
        return { isError: true, error };
      });
  },
};

export default axios;
