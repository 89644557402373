import Axios from "../../../utils/axios";

export const getEmailTemplates = (data) => {
  return (dispatch) => {
    Axios.get("/email-template", dispatch, "GET_EMAIL_TEMPLATE", data);
  };
};

export const updateEmailTemplate = (data) => {
  return (dispatch) => {
    return Axios
      .post("/email-template/" + data.id, dispatch, "UPDATE_EMAIL_TEMPLATE", data)
      .then((response) => {
        if (response.response.data.status === "success") {
          const param = {
            event_id: response.response.data.data.event_id
          };
          dispatch(getEmailTemplates(param));
        }
        return response;
      });
  };
};