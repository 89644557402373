const initialData = {
  isLoading: false,
  rCollectionsData: [],
  rCollectionData: {},
  delRCollectionData: {},
  putRCollectionData: {},
  passportsData: [],
  ticketsData: [],
};

export default function rcollection(state = initialData, action) {
  switch (action.type) {
    case "GET_COLLECTIONS":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_COLLECTIONS_SUCCESS":
      return {
        ...state,
        rCollectionsData: action.payload.data,
        passportsData: action.payload.passports,
        ticketsData: action.payload.tickets,
        isLoading: false,
      };
    case "GET_COLLECTIONS_FAILED":
      return {
        ...state,
        rCollectionsData: [],
        isLoading: false,
      };

    case "CREATE_COLLECTION":
      return {
        ...state,
        isLoading: true,
      };
    case "CREATE_COLLECTION_SUCCESS":
      return {
        ...state,
        rCollectionData: action.payload.data,
        isLoading: false,
      };
    case "CREATE_COLLECTION_FAILED":
      return {
        ...state,
        rCollectionData: {},
        isLoading: false,
      };

    case "DELETE_COLLECTION":
      return {
        ...state,
        isLoading: true,
      };
    case "DELETE_COLLECTION_SUCCESS":
      return {
        ...state,
        delRCollectionData: action.payload.data,
        isLoading: false,
      };
    case "DELETE_COLLECTION_FAILED":
      return {
        ...state,
        delRCollectionData: {},
        isLoading: false,
      };

    case "UPDATE_COLLECTION":
      return {
        ...state,
        isLoading: true,
      };
    case "UPDATE_COLLECTION_SUCCESS":
      return {
        ...state,
        putRCollectionData: action.payload.data,
        isLoading: false,
      };
    case "UPDATE_COLLECTION_FAILED":
      return {
        ...state,
        putRCollectionData: {},
        isLoading: false,
      };

    default:
      return {
        ...state,
      };
  }
}
