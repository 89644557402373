import React, { Component } from "react";
import { Form, Modal, message, Layout, Button, Row, Col, Input, Spin, Select, Checkbox, Tabs, Icon } from "antd";
import "./index.scss";
import { notify, notifyFormError } from "../../../shared/Notification";

const { TabPane } = Tabs;
const { Content } = Layout;

class UserModal extends Component {
  initialState = {
    showModal: false,
    selectedRow: {},
    eventOptions: [],
    selectedRoleAccess: [],
    roleAccess: [],
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  handleCloseModal = () => {
    this.setState({
      showModal: false,
      selectedRow: {},
      selectedRoleAccess: []
    });
    this.props.form.resetFields();
    this.props.handleCloseModal();
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.showModal !== nextProps.showModal) {
      this.setState({ showModal: nextProps.showModal });
    }

    if (this.props.roleAccessData !== nextProps.roleAccessData) {
      this.setState({ roleAccess: nextProps.roleAccessData });
    }


    if (this.props.selectedRow !== nextProps.selectedRow) {
      this.setState({
        selectedRow: nextProps.selectedRow,
        selectedRoleAccess: JSON.parse(nextProps.selectedRow.access)
      });
    }

    if (this.props.eventsData !== nextProps.eventsData) {
      // this.setState({ eventsData: nextProps.eventsData });
      let events = [];
      nextProps.eventsData.forEach(row => {
        events.push({ label: row.event_name, value: row.id });
      });

      this.setState({ eventOptions: events });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { selectedRow, selectedRoleAccess } = this.state;

    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const params = {
          ...values,
        };

        if (params.status == "Active") {
          params.status = 1;
        } else if (params.status == "Inactive") {
          params.status = 0;
        }

        params.event_id = this.props.event.id;
        params.access = selectedRoleAccess;
        if (selectedRow.id) {
          params.id = selectedRow.id;
          await this.props
            .onUpdateUserRole(params)
            .then((response) => {
              if (response.response.data.status === "success") {
                notify('success', 'Successfuly updated user role: ' + response.response.data.data.role_name);
                this.handleCloseModal();
              } else {
                notify('error', response.response.data.message);
              }
            })
            .catch(error => {
              notify('error', error.message);
            });
        } else {
          await this.props
            .onCreateUserRole(params)
            .then((response) => {
              if (response.response.data.status === "success") {
                notify('success', 'Successfuly created user role: ' + response.response.data.data.role_name);
                this.handleCloseModal();
              } else {
                notify('error', response.response.data.message);
              }
            })
            .catch(error => {
              notify('error', error.message);
            });
        }
      } else {
        notifyFormError(err);
      }
    });
  }

  onChange = checklist => {
    const { roleAccess } = this.state;

    this.setState({
      selectedRoleAccess: checklist,
      indeterminate: !!checklist.length && checklist.length < roleAccess.length,
      checkAll: checklist.length === roleAccess.length,
    });
  };

  onCheckAllChange = e => {
    const { roleAccess } = this.state;
    this.setState({
      checkedList: e.target.checked ? roleAccess : [],
      indeterminate: false,
      checkAll: e.target.checked,
    });


  };

  render() {
    const { showModal, selectedRow, selectedRoleAccess, roleAccess } = this.state;
    const { isLoading, form: { getFieldDecorator }, eventsData } = this.props;

    let prevPlatform = '';
    let prevModule = '';

    let platforms = [];
    let modules = [];

    roleAccess.forEach(row => {
      if (platforms.indexOf(row.platform) == -1) {
        platforms.push(row.platform);
      }

      if (modules.indexOf(row.module) == -1) {
        modules.push(row.module);
      }
    });

    return (
      <Modal
        title={selectedRow.id ? 'Update User Role' : 'Create User Role'}
        visible={showModal}
        onCancel={this.handleCloseModal}
        centered={true}
        footer={null}
        closable={false}
        maskClosable={false}
        width={"70%"}
      >
        <Layout className="user-role-container">
          <Content className="overview-content" style={{ padding: "10px" }}>
            <Spin spinning={isLoading} key="create-event">
              <Form onSubmit={this.handleSubmit} style={{ width: "100%" }} disabled={isLoading}>
                <Row type="flex" gutter={30}>
                  <Col span={12}>
                    <Form.Item label="Role Name">
                      {getFieldDecorator("role_name", {
                        rules: [
                          {
                            required: true,
                            message: "Please enter Role Name",
                          },
                        ],
                        initialValue: selectedRow.id ? selectedRow.role_name : ""
                      })(<Input />)}
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="Status">
                      {getFieldDecorator("status", {
                        rules: [
                          {
                            required: true,
                            message: "Please select Status",
                          },
                        ],
                        initialValue: selectedRow.status == 1 ? "Active" : "Inactive"
                      })(
                        <Select>
                          <Select.Option key="1">Active</Select.Option>
                          <Select.Option key="0">Inactive</Select.Option>
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row type="flex" gutter={30}>
                  <Col span={24}>
                    <Form.Item>
                      {getFieldDecorator("role_access", {
                        rules: [
                          {
                            required: false,
                            message: "Please select Role Access",
                          },
                        ],
                        initialValue: selectedRoleAccess
                      })(
                        <Checkbox.Group
                          // options={eventOptions}
                          onChange={this.onChange}
                        >
                          <Tabs defaultActiveKey={platforms[0]} style={{ textTransform: "capitalize" }}>
                            {platforms.map(platform => (
                              <TabPane tab={platform} key={platform}>
                                <Row>
                                  {modules.map(module => (
                                    <Col span={12} key={module} style={{ padding: "3px" }}>
                                      <Row style={{ marginBottom: "10px", borderRadius: "5px", border: "1px #ccc solid", padding: "5px" }}>
                                        <Col span={24} style={{ fontWeight: "bold" }}>{module.replace("-", " ")}</Col>
                                        {roleAccess.map((row, index) => {
                                          if (row.platform == platform && row.module == module) {
                                            return (
                                              <Col span={8} key={index}>
                                                <Checkbox value={`${row.platform}-${row.module}-${row.action}`}>{row.action.replace("-", " ")}</Checkbox>
                                              </Col>
                                            );
                                          }
                                        })}
                                      </Row>
                                    </Col>
                                  ))}
                                </Row>
                              </TabPane>
                            ))}
                          </Tabs>
                        </Checkbox.Group>
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row type="flex" gutter={30}>
                  <Col span={24} align="right">
                    <Form.Item>
                      <Button
                        type="default"
                        disabled={isLoading}
                        loading={isLoading}
                        onClick={this.handleCloseModal}
                      >
                        <Icon type="close"></Icon>
                        Close
                      </Button>
                      &nbsp;&nbsp;
                      <Button
                        type="primary"
                        htmlType="submit"
                        disabled={isLoading}
                        loading={isLoading}
                      >
                        <Icon type="save" />
                        Save
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </Content>
        </Layout >
      </Modal >
    );
  }
}

const UserModalComponent = Form.create({ name: "user-modal" })(UserModal);

export default UserModalComponent;
