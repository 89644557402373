import React, { Component } from "react";
import { Form, Layout, Button, Row, Col, Popconfirm, Table, Tag, Dropdown, Menu, Icon } from "antd";
import "./index.scss";
import { checkPermission } from "../../utils/helper";
import { Redirect } from "react-router-dom";
import moment from "moment";
import PageHeader from "../shared/PageHeader";
import { notify } from "../shared/Notification";

const { Content } = Layout;

class TicketSales extends Component {
  initialState = {
    ticketSalesData: [],
    showCreateUpdateModal: false,
    selectedRow: {},
    paymentStatus: ["Denied", "For Confirmation", "Approved", "Refunded", "Cancelled"],
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  handleCloseModal = () => {
    this.setState({
      showCreateUpdateModal: false,
    });
  };

  componentDidMount() {
    const onGetParam = {
      event_id: this.props.event.id
    };
    this.props.onGetTicketSales(onGetParam);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.ticketSalesData !== nextProps.ticketSalesData) {
      this.setState({ ticketSalesData: nextProps.ticketSalesData });
    }
  }

  handleChangeStatus = (e, row) => {
    const params = {
      ...row,
      status: e.key
    };

    this.handleChangePaymentStatus(params)
  }

  handleChangePaymentStatus = async (params) => {
    await this.props.onUpdatePaymentStatus(params);
    notify('success', 'Updated Payment Status: ' + params.payment_id);
  }

  render() {
    const { ticketSalesData, showCreateUpdateModal, selectedRow, paymentStatus } = this.state;
    const { isLoading, } = this.props;
    const module = "ticket-sales";

    if (!checkPermission(module, "view")) {
      return (<Redirect to="/403" />);
    }

    const columns = [
      {
        title: 'Email Address',
        dataIndex: 'email',
        key: 'email'
      },
      {
        title: 'Registrant',
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: 'Ticket',
        dataIndex: 'ticket_name',
        key: 'ticket_name'
      },
      {
        title: 'Mode',
        dataIndex: 'payment_mode',
        key: 'payment_mode',
        align: 'center'
      },
      {
        title: 'Payment ID',
        dataIndex: 'payment_id',
        key: 'payment_id',
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        align: 'right'
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        render: status => {

          let color = "yellow";
          let label = paymentStatus[status];

          switch (status) {
            case 0:
              color = "red";
              break;

            case 1:
              color = "yellow";
              break;

            case 2:
              color = "green";
              break;

            case 3:
              color = "gray";
              break;

            case 4:
              color = "gray";
              break;

            default:
              break;
          }

          return (
            <span>
              <Tag color={color}>
                {label}
              </Tag>
            </span>
          )
        },
      },
      {
        title: 'Timestamp',
        dataIndex: 'created_at',
        key: 'created_at',
        render: (created_at) => (
          <span>
            {moment(created_at).format('MMMM DD YYYY')}
            <br />
            {moment(created_at).format('h:mm A')}
          </span>
        )
      },
      {
        title: 'Action',
        key: 'action',
        align: 'center',
        render: (text, row) => (
          <Dropdown overlay={
            <Menu onClick={e => this.handleChangeStatus(e, row)}>
              {paymentStatus.map((value, index) =>
                <Menu.Item key={index} disabled={index == row.status}>{value}</Menu.Item>
              )}
            </Menu>
          }>
            <Button type="primary">
              Update Status <Icon type="down" />
            </Button>
          </Dropdown>
        ),
      },
    ];

    return (
      <Layout className="ticket-sales-container">
        <Content className="ticket-sales-content" style={{ padding: "20px 60px" }}>
          <PageHeader />

          <Row type="flex" gutter={30}>
            <Col span={24}>
              <Table columns={columns} dataSource={ticketSalesData} loading={isLoading} rowKey={"id"} />
            </Col>
          </Row>
        </Content>

        {/* <CreateUpdateModal showModal={showCreateUpdateModal} selectedRow={selectedRow} handleCloseModal={this.handleCloseModal} {...this.props} /> */}
      </Layout>
    );
  }
}

const TicketSalesForm = Form.create({ name: "ticket-sales-form" })(TicketSales);

export default TicketSalesForm;
