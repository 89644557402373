const initialData = {
  isLoading: false,
  rSettingsData: [],
};

export default function rsetting(state = initialData, action) {
  switch (action.type) {
    case "GET_SETTINGS":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_SETTINGS_SUCCESS":
      return {
        ...state,
        rSettingsData: action.payload,
        isLoading: false,
      };
    case "GET_SETTINGS_FAILED":
      return {
        ...state,
        rSettingsData: [],
        isLoading: false,
      };

    default:
      return {
        ...state,
      };
  }
}
