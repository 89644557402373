import Axios from "../../../utils/axios";

export const getEventPage = (data) => {
  return (dispatch) => {
    Axios.get("/event-page", dispatch, "GET_EVENT_PAGE", data);
  };
};

export const updateEventPage = (data) => {
  return (dispatch) => {
    return Axios
      .post("/event-page/" + data.id, dispatch, "UPDATE_EVENT_PAGE", data)
      .then((response) => {
        if (response.response.data.status === "success") {
          const param = {
            event_id: response.response.data.data.event_id
          };
          dispatch(getEventPage(param));
        }
        return response;
      });
  };
};