import { combineReducers } from "redux";
import { reducer as form } from "redux-form";

import login from "../components/Login/reducers";
import header from "../components/LayoutHeader/reducers";
import overview from "../components/Overview/reducers";
import registrationField from "../components/RegistrationField/reducers";
import ticket from "../components/Ticket/reducers";
import eventPage from "../components/EventPage/reducers";
import registration from "../components/Registration/reducers";
import emailTemplate from "../components/EmailTemplate/reducers";
import attendance from "../components/Attendance/reducers";
import scan from "../components/Scan/reducers";
import user from "../components/User/reducers";
import userRole from "../components/UserRole/reducers";
import auditLog from "../components/AuditLog/reducers";
import emailLog from "../components/EmailLog/reducers";
import home from "../components/Home/reducers";
import rcollection from "../components/RaffleCollection/reducers";
import rprize from "../components/RafflePrize/reducers";
import rcontroller from "../components/RaffleController/reducers";
import rsetting from "../components/RaffleViewer/reducers";
import raffleWinner from "../components/RaffleWinners/reducers";
import passport from "../components/Passports/reducers";
import passportClaims from "../components/PassportClaims/reducers";
import payments from "../components/Payments/reducers";
import salesReport from "../components/SalesReport/reducers";
import profile from "../components/Profile/reducers";

const rootReducer = combineReducers({
  form,
  login,
  header,
  overview,
  registrationField,
  ticket,
  eventPage,
  registration,
  emailTemplate,
  attendance,
  scan,
  user,
  userRole,
  auditLog,
  emailLog,
  home,
  rcollection,
  rprize,
  rcontroller,
  rsetting,
  raffleWinner,
  passport,
  passportClaims,
  payments,
  salesReport,
  profile,
});

export default rootReducer;
