import React, { Component } from "react";
import { Form, Layout, Button, Row, Col, Popconfirm, Table, Tag, Icon, Tooltip } from "antd";
import "./index.scss";
import CreateUpdateModal from "./components/CreateUpdateModal";
import { checkPermission } from "../../utils/helper";
import { Redirect } from "react-router-dom";
import PageHeader from "../shared/PageHeader";
import { notify } from "../shared/Notification";


const { Content } = Layout;

class Passport extends Component {
  initialState = {
    passportsData: [],
    showCreateUpdateModal: false,
    selectedRow: {}
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  handleCreateButton = () => {
    this.setState({
      showCreateUpdateModal: true
    });
  };

  handleUpdateButton = (row) => {
    this.setState({
      showCreateUpdateModal: true,
      selectedRow: row
    });
  };

  handleDeleteButton = async (row) => {
    await this.props.onDeletePassport(row);
    notify('success', 'Deleted Passport: ' + row.name);
  }

  handleCloseModal = () => {
    this.setState({
      showCreateUpdateModal: false,
    });
  };

  componentDidMount() {
    const onGetParam = {
      event_id: this.props.event.id
    };
    this.props.onGetPassports(onGetParam);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.passportsData !== nextProps.passportsData) {
      this.setState({ passportsData: nextProps.passportsData });
    }
  }

  render() {
    const { passportsData, showCreateUpdateModal, selectedRow } = this.state;
    const { isLoading, ticketsData } = this.props;
    const module = "passport";

    if (!checkPermission(module, "view")) {
      return (<Redirect to="/403" />);
    }

    const columns = [
      {
        title: 'Passport Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: 'Claim Count',
        dataIndex: 'claim_count',
        key: 'claim_count',
        align: 'center',
      },
      {
        title: 'Ticket/s',
        dataIndex: 'event_tickets_ids',
        key: 'event_tickets_ids',
        align: 'left',
        render: tickets => (
          <span>
            {ticketsData.map((row, index) => {
              if (tickets.indexOf(row.id) > -1) {
                return (<Tag key={index} style={{ marginBottom: "10px" }}>{row.ticket_name}</Tag>);
              }
              return '';
            })}
          </span>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        render: status => (
          <span>
            {status == 1 ?
              (
                <Tag color='green' key='active'>
                  Active
                </Tag>
              ) : (
                <Tag color='gray' key='inactive'>
                  Inactive
                </Tag>
              )
            }
          </span>
        ),
      },
      {
        title: 'Action',
        key: 'action',
        align: 'center',
        render: (text, record) => (
          <span>
            {(checkPermission(module, "edit")) ?
              <Tooltip title="Edit"><Button type="default" onClick={() => this.handleUpdateButton(record)}><Icon type="edit" /></Button></Tooltip> : ''
            }
            &nbsp;&nbsp;
            {/* <Divider type="vertical" /> */}
            {(checkPermission(module, "delete")) ?
              <Popconfirm
                title="Are you sure you want to delete?"
                onConfirm={() => this.handleDeleteButton(record)}
                okText="Proceed"
                cancelText="Cancel"
                disabled={record.claims.length > 0}
              >
                <Tooltip title="Delete"><Button type="danger" disabled={record.claims.length > 0}><Icon type="delete" /></Button></Tooltip>
              </Popconfirm> : ''
            }
          </span>
        ),
      },
    ];

    return (
      <Layout className="passport-container">
        <Content className="passport-content" style={{ padding: "20px 60px" }}>
          <PageHeader rightPanel={(checkPermission(module, "create")) ? <Button type="primary" loading={isLoading} onClick={this.handleCreateButton}><Icon type="plus" />Add Passport</Button> : ''} />

          <Row type="flex" gutter={30}>
            <Col span={24}>
              <Table columns={columns} dataSource={passportsData} loading={isLoading} rowKey={"id"} />
            </Col>
          </Row>
        </Content>

        <CreateUpdateModal showModal={showCreateUpdateModal} selectedRow={selectedRow} handleCloseModal={this.handleCloseModal} {...this.props} />
      </Layout>
    );
  }
}

const PassportForm = Form.create({ name: "passport-form" })(Passport);

export default PassportForm;
