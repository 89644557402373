import Axios from "../../../utils/axios";

// export const getEvents = (data) => {
//     return (dispatch) => {
//         Axios.get("/event-list-with-details", dispatch, "GET_EVENTS", data);
//     };
// };

export const getRegistration = (data) => {
    return (dispatch) => {
        Axios.get("/registration", dispatch, "GET_REGISTRATION", data);
    };
};

export const acceptAttendance = (data) => {
    return (dispatch) => {
        return Axios
            .post("/attendance", dispatch, "ACCEPT_ATTENDANCE", data)
            .then((response) => {
                return response;
            });
    };
};