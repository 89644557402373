import React, { Component } from "react";
import { Form, Modal, message, Layout, Button, Row, Col, Input, Switch, Spin, Select, InputNumber, Icon } from "antd";
import "./index.scss";
import { notify, notifyFormError } from "../../../shared/Notification";

const { Content } = Layout;

class RegistrationFieldModal extends Component {
  initialState = {
    showModal: false,
    selectedRow: {},
    selectedType: ""
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  handleCloseModal = () => {
    this.setState({
      showModal: false,
      selectedRow: {}
    });
    this.props.form.resetFields();
    this.props.handleCloseModal();
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.showModal !== nextProps.showModal) {
      this.setState({ showModal: nextProps.showModal });
    }

    if (this.props.selectedRow !== nextProps.selectedRow) {
      this.setState({
        selectedRow: nextProps.selectedRow,
        selectedType: nextProps.selectedRow.type
      });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { selectedRow } = this.state;

    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const params = {
          ...values,
        };

        if (!params.is_required) {
          params.is_required = false
        }

        params.event_id = this.props.event.id;
        if (selectedRow.id) {
          params.id = selectedRow.id;
          await this.props
            .onUpdateRegistrationField(params)
            .then((response) => {
              if (response.response.data.status === "success") {
                notify('success', 'Successfuly updated registration field: ' + response.response.data.data.label);
                this.handleCloseModal();
              } else {
                notify('error', response.response.data.message);
              }
            })
            .catch(error => {
              notify('error', error.message);
            });
        } else {
          await this.props
            .onCreateRegistrationField(params)
            .then((response) => {
              if (response.response.data.status === "success") {
                notify('success', 'Successfuly created registration field: ' + response.response.data.data.label);
                this.handleCloseModal();
              } else {
                notify('error', response.response.data.message);
              }
            })
            .catch(error => {
              notify('error', error.message);
            });
        }
      } else {
        notifyFormError(err);
      }
    });
  }

  handleChangeType = e => {
    this.setState({
      selectedType: e
    });
  }

  render() {
    const { showModal, selectedRow, selectedType } = this.state;
    const { isLoading, form: { getFieldDecorator }, } = this.props;

    return (
      <Modal
        title={selectedRow.id ? 'Update Registration Field' : 'Create Registration Field'}
        visible={showModal}
        onCancel={this.handleCloseModal}
        centered={true}
        footer={null}
        closable={false}
        maskClosable={false}
        width={"50%"}
      >
        <Layout className="overview-container">
          <Content className="overview-content" style={{ padding: "10px" }}>
            <Spin spinning={isLoading} key="create-event">
              <Form onSubmit={this.handleSubmit} style={{ width: "100%" }} disabled={isLoading}>
                <Row type="flex" gutter={30}>
                  <Col span={12}>
                    <Form.Item label="Field Name">
                      {getFieldDecorator("label", {
                        rules: [
                          {
                            required: true,
                            message: "Please enter Field Name",
                          },
                        ],
                        initialValue: selectedRow.id ? selectedRow.label : ""
                      })(<Input />)}
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <Form.Item label="Order">
                      {getFieldDecorator("ordinality", {
                        rules: [
                          {
                            required: true,
                            message: "Please enter Order",
                          },
                        ],
                        initialValue: selectedRow.id ? selectedRow.ordinality : 1
                      })(<InputNumber />)}
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <Form.Item label="Required Field">
                      {getFieldDecorator("is_required", {
                        rules: [],
                        valuePropName: 'checked',
                        initialValue: selectedRow.id && selectedRow.is_required == 1 ? true : false
                      })(<Switch />)}
                    </Form.Item>
                  </Col>
                </Row>

                <Row type="flex" gutter={30}>
                  <Col span={12}>
                    <Form.Item label="Type">
                      {getFieldDecorator("type", {
                        rules: [
                          {
                            required: true,
                            message: "Please select Type",
                          },
                        ],
                        initialValue: selectedRow.id ? selectedRow.type : ""
                      })(
                        <Select onChange={e => this.handleChangeType(e)}>
                          <Select.Option key="Checkbox">Checkbox</Select.Option>
                          <Select.Option key="Date">Date</Select.Option>
                          <Select.Option key="Dropdown">Dropdown</Select.Option>
                          <Select.Option key="Email">Email</Select.Option>
                          <Select.Option key="File Upload">File Upload</Select.Option>
                          <Select.Option key="Mobile">Mobile</Select.Option>
                          <Select.Option key="Number">Number</Select.Option>
                          <Select.Option key="Radio">Radio</Select.Option>
                          <Select.Option key="Text">Text</Select.Option>
                        </Select>
                      )}
                    </Form.Item>
                  </Col>

                  {["Dropdown", "Radio", "Checkbox"].indexOf(selectedType) > -1 ? (
                    <Col span={12}>
                      <Form.Item label="Options">
                        {getFieldDecorator("options", {
                          rules: [
                            {
                              required: true,
                              message: "Please enter options separated by comma",
                            },
                          ],
                          initialValue: selectedRow.id ? selectedRow.options : ""
                        })(
                          <Input />
                        )}
                      </Form.Item>
                    </Col>
                  ) : ''}
                </Row>

                <Row type="flex" gutter={30}>
                  <Col span={24} align="right">
                    <Form.Item>
                      <Button
                        type="default"
                        disabled={isLoading}
                        loading={isLoading}
                        onClick={this.handleCloseModal}
                      >
                        <Icon type="close" />
                        Close
                      </Button>
                      &nbsp;&nbsp;
                      <Button
                        type="primary"
                        htmlType="submit"
                        disabled={isLoading}
                        loading={isLoading}
                      >
                        <Icon type="save" />
                        Save
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </Content>
        </Layout >
      </Modal>
    );
  }
}

const RegistrationFieldModalComponent = Form.create({ name: "registration-field-modal" })(RegistrationFieldModal);

export default RegistrationFieldModalComponent;
