const initialData = {
  isLoading: false,
  eventsData: [],
  registrationData: {},
};

export default function admin(state = initialData, action) {
  switch (action.type) {
    // case "GET_EVENTS_REQUEST":
    //   return {
    //     ...state,
    //     isLoading: true,
    //   };
    // case "GET_EVENTS_SUCCESS":
    //   return {
    //     ...state,
    //     eventsData: action.payload.data,
    //     isLoading: false,
    //   };
    // case "GET_EVENTS_FAILED":
    //   return {
    //     ...state,
    //     eventsData: [],
    //     isLoading: false,
    //   };

    case "GET_REGISTRATION_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_REGISTRATION_SUCCESS":
      return {
        ...state,
        registrationData: action.payload.data,
        isLoading: false,
      };
    case "GET_REGISTRATION_FAILED":
      return {
        ...state,
        registrationData: {},
        isLoading: false,
      };

    // case "REGISTER_REQUEST":
    //   return {
    //     ...state,
    //     isLoading: true,
    //   };
    // case "REGISTER_SUCCESS":
    //   return {
    //     ...state,
    //     eventData: action.payload.data,
    //     isLoading: false,
    //   };
    // case "REGISTER_FAILED":
    //   return {
    //     ...state,
    //     eventData: {},
    //     isLoading: false,
    // };

    default:
      return {
        ...state,
      };
  }
}
