import { purgeStoredState } from "redux-persist";

export const setToken = (accessToken) => {
  window.localStorage.setItem("TOKEN", accessToken);

  return accessToken;
};

export const getToken = () => {
  return window.localStorage.getItem("TOKEN");
};

export const deleteToken = () => {
  return window.localStorage.removeItem("TOKEN");
};

export const setUser = (accessToken) => {
  window.localStorage.setItem("USER", accessToken);

  return accessToken;
};

export const getUser = () => {
  return window.localStorage.getItem("USER");
};

export const deleteUser = () => {
  return window.localStorage.removeItem("USER");
};

export const setEvent = (event) => {
  window.localStorage.setItem("event", JSON.stringify(event));

  return event;
};

export const getEvent = () => {
  return JSON.parse(window.localStorage.getItem("event"));
};

export const deleteEvent = () => {
  return window.localStorage.removeItem("event");
};

export const setClient = (clientId) => {
  window.localStorage.setItem("CLIENT", clientId);

  return clientId;
};

export const getClient = () => {
  return window.localStorage.getItem("CLIENT");
};

export const deleteClient = () => {
  return window.localStorage.removeItem("CLIENT");
};

export const setClients = (clients) => {
  let filterClients = [];

  clients.forEach((row) => {
    if (row.status === 1) {
      filterClients.push({
        id: row.id,
        name: row.name,
        domain: row.domain,
      });
    }
  });

  window.localStorage.setItem("CLIENTS", JSON.stringify(filterClients));

  return filterClients;
};

export const getClients = () => {
  return JSON.parse(window.localStorage.getItem("CLIENTS"));
};

export const backToLoginPage = () => {
  deleteToken();
  deleteUser();
  deleteClient();
  purgeStoredState();
  window.localStorage.clear();
  window.location.href = "/";
};

export const checkError = (response) => {
  if (response.isError && response.error) {
    console.log("Error: ", response.error);

    if (response.error.response && response.error.response.status) {
      const errorCode = response.error.response.status;
      const errorText = response.error.response.statusText;

      switch (errorCode) {
        case 401:
          console.log(errorText);
          deleteToken();
          deleteUser();
          deleteClient();
          window.localStorage.clear();
          window.location.href = "/";
          break;

        default:
          break;
      }
    }
  }
};

export const headers = {
  Authorization: `Bearer ${getToken()}`,
  Accept: "application/json",
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Credentials": true
};
