import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "antd";
import {
  getRegistrations,
  exportRegistrations,
  createRegistration,
  updateRegistration,
  batchUpdateStatusRegistration,
  deleteRegistration,
  processRegistrationBatch,
  emailConfirmation,
  emailQrCode,
  emailNotify,
} from "./actions";
import { getRegistrationFields } from "../RegistrationField/actions"
import Page from "./Page";
import { getEvent } from "../../utils/token";


class RegistrationCard extends Component {
  render() {
    return <Page {...this.props} />;
  }
}

const RegistrationForm = Form.create({ name: "registration-form" })(RegistrationCard);

function mapStateToProps(state) {
  return {
    isLoading: state.registration.isLoading,
    registrationsData: state.registration.registrationsData,
    registrationData: state.registration.registrationData,
    registrationFieldsData: state.registrationField.registrationFieldsData,
    fieldsData: state.registration.fieldsData,
    registrationsTotal: state.registration.registrationsTotal,
    event: getEvent()
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onGetRegistrations: (data) => dispatch(getRegistrations(data)),
    onExportRegistrations: (data) => dispatch(exportRegistrations(data)),
    onCreateRegistration: (data) => dispatch(createRegistration(data)),
    onUpdateRegistration: (data) => dispatch(updateRegistration(data)),
    onBatchUpdateStatusRegistration: (data) => dispatch(batchUpdateStatusRegistration(data)),
    onDeleteRegistration: (data) => dispatch(deleteRegistration(data)),
    onProcessRegistrationBatch: (data) => dispatch(processRegistrationBatch(data)),
    onGetRegistrationFields: (data) => dispatch(getRegistrationFields(data)),
    onEmailConfirmation: (data) => dispatch(emailConfirmation(data)),
    onEmailQrCode: (data) => dispatch(emailQrCode(data)),
    onEmailNotify: (data) => dispatch(emailNotify(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationForm);
