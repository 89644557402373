import React, { Component } from "react";
import { Form, Layout, Button, Row, Col, Popconfirm, Table, Tag, Icon, Tooltip } from "antd";
import "./index.scss";
import CreateUpdateModal from "./components/CreateUpdateModal";
import { checkPermission } from "../../utils/helper";
import { Redirect } from "react-router-dom";
import PageHeader from "../shared/PageHeader";
import { notify } from "../shared/Notification"

const { Content } = Layout;

class User extends Component {
  initialState = {
    usersData: [],
    showCreateUpdateModal: false,
    selectedRow: {}
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  handleCreateButton = () => {
    this.setState({
      showCreateUpdateModal: true
    });
  };

  handleResetButton = async (row) => {
    await this.props.onResetPassword(row);
    notify('success', 'Reset password of user: ' + row.username);
  };

  handleUpdateButton = (row) => {
    this.setState({
      showCreateUpdateModal: true,
      selectedRow: row
    });
  };

  handleDeleteButton = async (row) => {
    await this.props.onDeleteUser(row);
    notify('success', 'Deleted user: ' + row.username);
  }

  handleCloseModal = () => {
    this.setState({
      showCreateUpdateModal: false,
    });
  };

  componentDidMount() {
    const onGetParam = {
      event_id: this.props.event.id
    };
    this.props.onGetUsers(onGetParam);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.usersData !== nextProps.usersData) {
      this.setState({ usersData: nextProps.usersData });
    }
  }

  render() {
    const { usersData, showCreateUpdateModal, selectedRow } = this.state;
    const { isLoading, } = this.props;
    const module = "user";

    if (!checkPermission(module, "view")) {
      return (<Redirect to="/403" />);
    }

    const columns = [
      {
        title: 'Email Address',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Username',
        dataIndex: 'username',
        key: 'username',
      },
      {
        title: 'Name',
        dataIndex: 'first_name',
        key: 'first_name',
        render: (column, row) => (
          <span>{`${row.first_name} ${row.last_name}`}</span>
        )
      },
      {
        title: 'User Role',
        dataIndex: 'user_role_id',
        key: 'user_role_id',
        align: 'center',
        render: (column, record) => (
          <span>{record.role && record.role.role_name ? record.role.role_name : ''}</span>
        )
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        render: status => (
          <span>
            {status == 1 ?
              (
                <Tag color='green' key='active'>
                  Active
                </Tag>
              ) : (
                <Tag color='gray' key='inactive'>
                  Inactive
                </Tag>
              )
            }
          </span>
        ),
      },
      {
        title: 'Action',
        key: 'action',
        align: 'center',
        render: (text, record) => (
          <span>
            {(checkPermission(module, "edit")) ?
              <Popconfirm
                title="Are you sure you want to reset password?"
                onConfirm={() => this.handleResetButton(record)}
                okText="Proceed"
                cancelText="Cancel"
              >
                <Tooltip title="Reset Password">
                  <Button type="default"><Icon type="sync" /></Button>
                </Tooltip>
              </Popconfirm> : ''
            }
            &nbsp;&nbsp;
            {(checkPermission(module, "edit")) ?
              <Tooltip title="Edit">
                <Button type="default" onClick={() => this.handleUpdateButton(record)}><Icon type="edit" /></Button>
              </Tooltip> : ''
            }
            &nbsp;&nbsp;
            {/* <Divider type="vertical" /> */}
            {(checkPermission(module, "delete")) ?
              <Popconfirm
                title="Are you sure you want to delete?"
                onConfirm={() => this.handleDeleteButton(record)}
                okText="Proceed"
                cancelText="Cancel"
              >
                <Tooltip title="Delete">
                  <Button type="danger"><Icon type="delete" /></Button>
                </Tooltip>
              </Popconfirm> : ''
            }
          </span>
        ),
      },
    ];

    return (
      <Layout className="user-container">
        <Content className="user-content" style={{ padding: "20px 60px" }}>
          <PageHeader rightPanel={(checkPermission(module, "create")) ?
            <Button type="primary" loading={isLoading} onClick={this.handleCreateButton}><Icon type="plus" />Add User</Button>
            : ''
          } />

          <Row type="flex" gutter={30}>
            <Col span={24}>
              <Table columns={columns} dataSource={usersData} loading={isLoading} rowKey={"id"} />
            </Col>
          </Row>
        </Content>

        <CreateUpdateModal showModal={showCreateUpdateModal} selectedRow={selectedRow} handleCloseModal={this.handleCloseModal} {...this.props} />
      </Layout>
    );
  }
}

const UserForm = Form.create({ name: "user-form" })(User);

export default UserForm;
