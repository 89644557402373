import React, { Component } from "react";
import { Form, Modal, message, Layout, Button, Row, Col, Input, Spin, Select, Icon, Tooltip } from "antd";
import "./index.scss";
import { notify, notifyFormError } from "../../../shared/Notification";

const { Content } = Layout;

class TicketModal extends Component {
  initialState = {
    showModal: false,
    selectedRow: {}
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  handleCloseModal = () => {
    this.setState({
      showModal: false,
      selectedRow: {}
    });
    this.props.form.resetFields();
    this.props.handleCloseModal();
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.showModal !== nextProps.showModal) {
      this.setState({ showModal: nextProps.showModal });
    }

    if (this.props.selectedRow !== nextProps.selectedRow) {
      this.setState({ selectedRow: nextProps.selectedRow });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { selectedRow } = this.state;

    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const params = {
          ...values,
        };

        if (!params.is_required) {
          params.is_required = false
        }

        if (params.status == "Active" || params.status == 1) {
          params.status = 1;
        } else if (params.status == "Inactive") {
          params.status = 0;
        }

        if (params.is_multiple_email == "Yes" || params.is_multiple_email == 1) {
          params.is_multiple_email = 1;
        } else if (params.is_multiple_email == "No") {
          params.is_multiple_email = 0;
        }

        params.event_id = this.props.event.id;
        if (selectedRow.id) {
          params.id = selectedRow.id;
          await this.props
            .onUpdateTicket(params)
            .then((response) => {
              if (response.response.data.status === "success") {
                notify('success', 'Updated ticket: ' + response.response.data.data.ticket_name);
                this.handleCloseModal();
              } else {
                notify('error', response.response.data.message);
              }
            })
            .catch(error => {
              notify('error', error.message);
            });
        } else {
          await this.props
            .onCreateTicket(params)
            .then((response) => {
              if (response.response.data.status === "success") {
                notify('success', 'Created ticket: ' + response.response.data.data.ticket_name);
                this.handleCloseModal();
              } else {
                notify('error', response.response.data.message);
              }
            })
            .catch(error => {
              notify('error', error.message);
            });
        }
      } else {
        notifyFormError(err);
      }
    });
  }

  render() {
    const { showModal, selectedRow } = this.state;
    const { isLoading, form: { getFieldDecorator }, } = this.props;

    return (
      <Modal
        title={selectedRow.id ? 'Update Ticket' : 'Create Ticket'}
        visible={showModal}
        onCancel={this.handleCloseModal}
        centered={true}
        footer={null}
        closable={false}
        maskClosable={false}
        width={"50%"}
      >
        <Layout className="overview-container">
          <Content className="overview-content" style={{ padding: "10px" }}>
            <Spin spinning={isLoading} key="create-event">
              <Form onSubmit={this.handleSubmit} style={{ width: "100%" }} disabled={isLoading}>
                <Row type="flex" gutter={30}>
                  <Col span={12}>
                    <Form.Item label="Ticket Name">
                      {getFieldDecorator("ticket_name", {
                        rules: [
                          {
                            required: true,
                            message: "Please enter Ticket Name",
                          },
                        ],
                        initialValue: selectedRow.id ? selectedRow.ticket_name : ""
                      })(<Input />)}
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="Type">
                      {getFieldDecorator("type", {
                        rules: [
                          {
                            required: true,
                            message: "Please select Type",
                          },
                        ],
                        initialValue: selectedRow.type ? selectedRow.type : ""
                      })(
                        <Select>
                          <Select.Option key="Private">Private</Select.Option>
                          <Select.Option key="Public">Public</Select.Option>
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row type="flex" gutter={30}>
                  <Col span={24}>
                    <Form.Item label="Description">
                      {getFieldDecorator("description", {
                        rules: [
                          {
                            required: false,
                            message: "Please select Price",
                          },
                        ],
                        initialValue: selectedRow.id ? selectedRow.description : ""
                      })(<Input />)}
                    </Form.Item>
                  </Col>
                </Row>

                <Row type="flex" gutter={30}>
                  <Col span={12}>
                    <Form.Item label="Price">
                      {getFieldDecorator("price", {
                        rules: [
                          {
                            required: true,
                            message: "Please select Price",
                          },
                        ],
                        initialValue: selectedRow.id ? selectedRow.price : ""
                      })(<Input type="number" />)}
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="Issue Count">
                      {getFieldDecorator("issue_count", {
                        rules: [
                          {
                            required: true,
                            message: "Please enter Issue Count",
                          },
                        ],
                        initialValue: selectedRow.id ? selectedRow.issue_count : ""
                      })(<Input type="number" />)}
                    </Form.Item>
                  </Col>
                </Row>

                <Row type="flex" gutter={30}>
                  <Col span={12}>
                    <Form.Item label={
                      <span>
                        Same Email Registration&nbsp;
                        <Tooltip title="Will allow this ticket to be avail multiple times using same email.">
                          <Icon type="question-circle-o" theme="filled" className="warning-font-color" />
                        </Tooltip>
                      </span>
                    }>
                      {getFieldDecorator("is_multiple_email", {
                        rules: [
                          {
                            required: true,
                            message: "Please select",
                          },
                        ],
                        initialValue: selectedRow.is_multiple_email == 1 ? "Yes" : "No"
                      })(
                        <Select>
                          <Select.Option key="Yes">Yes</Select.Option>
                          <Select.Option key="No">No</Select.Option>
                        </Select>
                      )}
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label={
                      <span>
                        Max Avail Per Transaction&nbsp;
                        <Tooltip title="Indicate the max number of avail of this ticket per registration. Enter 0 for no limit else with limit.">
                          <Icon type="question-circle-o" theme="filled" className="warning-font-color" />
                        </Tooltip>
                      </span>
                    }>
                      {getFieldDecorator("max_avail", {
                        rules: [
                          {
                            required: true,
                            message: "Please enter Max Avail Per Transaction",
                          },
                        ],
                        initialValue: selectedRow.id ? selectedRow.max_avail : ""
                      })(<Input type="number" />)}
                    </Form.Item>
                  </Col>
                </Row>

                <Row type="flex" gutter={30}>
                  <Col span={12}>
                    <Form.Item label="Status">
                      {getFieldDecorator("status", {
                        rules: [
                          {
                            required: true,
                            message: "Please select Status",
                          },
                        ],
                        initialValue: selectedRow.status == 1 ? "Active" : "Inactive"
                      })(
                        <Select>
                          <Select.Option key="Active">Active</Select.Option>
                          <Select.Option key="Inactive">Inactive</Select.Option>
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row type="flex" gutter={30}>
                  <Col span={24} align="right">
                    <Form.Item>
                      <Button
                        type="default"
                        disabled={isLoading}
                        loading={isLoading}
                        onClick={this.handleCloseModal}
                      >
                        <Icon type="close" />
                        Close
                      </Button>
                      &nbsp;&nbsp;
                      <Button
                        type="primary"
                        htmlType="submit"
                        disabled={isLoading}
                        loading={isLoading}
                      >
                        <Icon type="save" />
                        Save
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </Content>
        </Layout >
      </Modal>
    );
  }
}

const TicketModalComponent = Form.create({ name: "ticket-modal" })(TicketModal);

export default TicketModalComponent;
