const initialData = {
  isLoading: false,
  auditLogsData: [],
  auditLogData: {},
  auditLogTotal: 0
};

export default function admin(state = initialData, action) {
  switch (action.type) {
    case "GET_AUDIT_LOGS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_AUDIT_LOGS_SUCCESS":
      return {
        ...state,
        auditLogsData: action.payload.data,
        auditLogTotal: action.payload.total_count,
        isLoading: false,
      };
    case "GET_AUDIT_LOGS_FAILED":
      return {
        ...state,
        auditLogsData: [],
        isLoading: false,
      };

    case "CREATE_AUDIT_LOG_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "CREATE_AUDIT_LOG_SUCCESS":
      return {
        ...state,
        auditLogData: action.payload.data,
        isLoading: false,
      };
    case "CREATE_AUDIT_LOG_FAILED":
      return {
        ...state,
        auditLogData: {},
        isLoading: false,
      };

    case "UPDATE_AUDIT_LOG_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "UPDATE_AUDIT_LOG_SUCCESS":
      return {
        ...state,
        auditLogData: action.payload.data,
        isLoading: false,
      };
    case "UPDATE_AUDIT_LOG_FAILED":
      return {
        ...state,
        auditLogData: {},
        isLoading: false,
      };

    case "DELETE_AUDIT_LOG_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "DELETE_AUDIT_LOG_SUCCESS":
      return {
        ...state,
        auditLogData: action.payload.data,
        isLoading: false,
      };
    case "DELETE_AUDIT_LOG_FAILED":
      return {
        ...state,
        auditLogData: {},
        isLoading: false,
      };

    default:
      return {
        ...state,
      };
  }
}
