import React, { Component } from "react";
import { Form, Layout, Input, Row, Col, Modal, Table, Button, Icon, Menu, Dropdown } from "antd";
import "./index.scss";
import moment from "moment";
import RegisterModal from "./components/RegisterModal";
import { checkPermission } from "../../utils/helper";
import { Redirect } from "react-router-dom";
import PageHeader from "../shared/PageHeader";
import DataTable from "../shared/DataTable";

const { Content } = Layout;

class Attendance extends Component {
  initialState = {
    event: [],
    selectedRow: {},
    selectedRowKeys: [],
    tableData: [],
    showRegistrationModal: false,
    totalData: 0,
    columns: [
      {
        title: 'Email Address',
        dataIndex: 'email',
        key: 'email',
        render: (column, row) => {
          return (row.registration.email);
        },
        searchKey: 'email',
      },
      {
        title: 'Registrant',
        dataIndex: 'name',
        key: 'name',
        searchKey: 'name',
        render: (text, record) => (
          <span>{`${record.registration.first_name ?? ""} ${record.registration.last_name ?? ""} ${record.registration.name ?? ""}`}</span>
        )
      },
      {
        title: 'Ticket',
        dataIndex: 'ticket.ticket_name',
        key: 'ticket.ticket_name',
        align: 'left',
        // sorter: (a, b) => a.ticket.length - b.ticket.length,
        // sortDirections: ['descend', 'ascend'],
        render: (column, row) => {
          return (row.ticket.ticket_name);
        },
      },
      {
        title: 'QR Code',
        dataIndex: 'registration_ticket.qr_code',
        key: 'registration_ticket.qr_code',
        align: 'center',
        // sorter: (a, b) => a.ticket.length - b.ticket.length,
        // sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Timestamp',
        dataIndex: 'created_at',
        key: 'created_at',
        align: 'center',
        render: (created_at) => {
          return (moment(created_at).format("hh:mm:s A"))
        },
        // sorter: (a, b) => a.ticket.length - b.ticket.length,
        // sortDirections: ['descend', 'ascend'],
      },
    ]
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  handleCloseModal = () => {
    this.setState({
      showRegistrationModal: false,
    });
  }

  componentDidMount() {
    const onGetParam = {
      event_id: this.props.event.id,
      results: 10
    };
    this.props.onGetAttendance(onGetParam);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.attendanceData !== nextProps.attendanceData) {
      this.setState({
        tableData: nextProps.attendanceData
      });
    }

    if (this.props.attendanceTotal !== nextProps.attendanceTotal) {
      this.setState({
        totalData: nextProps.attendanceTotal
      });
    }
  }

  onTableChange = params => {
    this.props.onGetAttendance(params);
  }

  handleMenuClick = (e) => {
    const { selectedRowKeys } = this.state;
    const key = e.key;
    switch (key) {
      case "register":
        this.setState({
          showRegistrationModal: true
        });
        break;

      case "export":
        const onGetParam = {
          event_id: this.props.event.id,
          event_name: this.props.event.event_name
        };
        this.props.onExportAttendance(onGetParam);
        break;

      default:
        break;
    }
  }

  expandedRow = attendance => (
    <Row type="flex" gutter={30} key={"expanded-row-" + attendance.id}>
      {attendance.registration.values.map(field => {
        return (
          <Col span={8} style={{ marginBottom: "10px" }} key={field.id}>
            <span style={{ fontWeight: "bold" }}>{field.field.label}:</span>&nbsp;&nbsp;
            <span>{field.field_value}</span>
          </Col>
        );
      })}
    </Row>
  );

  render() {
    const { tableData, showRegistrationModal, totalData, columns } = this.state;
    const { isLoading } = this.props;
    const module = "attendance";

    if (!checkPermission(module, "view")) {
      return (<Redirect to="/403" />);
    }

    return (
      <Layout className="registration-container">
        <Content className="registration-content" style={{ padding: "20px 60px" }}>
          <PageHeader rightPanel=
            {checkPermission(module, "manual-register") || checkPermission(module, "export-data") ? (
              <Dropdown loading={isLoading} overlay={
                <Menu onClick={this.handleMenuClick}>
                  {/* {checkPermission(module, "manual-register") ? (<Menu.Item key="register">Register Manually</Menu.Item>) : ''} */}
                  {checkPermission(module, "export-data") ? (<Menu.Item key="export"><Icon type="export" />Export Data</Menu.Item>) : ''}
                </Menu>
              }>
                <Button type="primary">
                  Actions <Icon type="down" />
                </Button>
              </Dropdown>
            ) : ''} />

          <Row type="flex" gutter={30}>
            <Col span={24}>
              <DataTable
                isLoading={isLoading}
                columns={columns}
                dataSource={tableData}
                total={totalData}
                onTableChange={this.onTableChange}
                expandedRowRender={this.expandedRow} />
            </Col>
          </Row>
        </Content>

        <RegisterModal showModal={showRegistrationModal} handleCloseModal={this.handleCloseModal} {...this.props} />
      </Layout>
    );
  }
}

const AttendanceForm = Form.create({ name: "attendance-form" })(Attendance);

export default AttendanceForm;
