import Axios from "../../../utils/axios";

export const getRegistrationFields = (data) => {
  return (dispatch) => {
    Axios.get("/registration-fields", dispatch, "GET_REGISTRATION_FIELDS", data);
  };
};

export const createRegistrationField = (data) => {
  return (dispatch) => {
    return Axios
      .post("/registration-field", dispatch, "CREATE_REGISTRATION_FIELD", data)
      .then((response) => {
        if (response.response.data.status === "success") {
          const param = {
            event_id: response.response.data.data.event_id
          };
          dispatch(getRegistrationFields(param));
        }
        return response;
      });
  };
};

export const updateRegistrationField = (data) => {
  return (dispatch) => {
    return Axios
      .post("/registration-field/" + data.id, dispatch, "UPDATE_REGISTRATION_FIELD", data)
      .then((response) => {
        if (response.response.data.status === "success") {
          const param = {
            event_id: response.response.data.data.event_id
          };
          dispatch(getRegistrationFields(param));
        }
        return response;
      });
  };
};

export const deleteRegistrationField = (data) => {
  return (dispatch) => {
    return Axios
      .delete("/registration-field/" + data.id, dispatch, "DELETE_REGISTRATION_FIELD", data)
      .then((response) => {
        if (response.response.data.status === "success") {
          const param = {
            event_id: data.event_id
          };
          dispatch(getRegistrationFields(param));
        }
        return response;
      });
  };
};