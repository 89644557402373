import React, { Component } from "react";
import { Form, Modal, message, Layout, Button, Row, Col, Spin } from "antd";
import ImageUploader from "../../../shared/ImageUploader";
import "./index.scss";

const { Content } = Layout;

class UploadModal extends Component {
  initialState = {
    showModal: false,
    imagePath: "uploads/templates"
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
    this.templateUploader = React.createRef();
  }

  handleCloseModal = () => {
    this.setState({
      showModal: false,
    });
    this.props.form.resetFields();
    this.props.handleCloseModal();
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.showModal !== nextProps.showModal) {
      this.setState({ showModal: nextProps.showModal });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields([], async (err, values) => {
      if (!err) {
        const params = {
          ...values,
        };

        const template = await this.templateUploader.current.handleUploadRequest();
        if (template) {
          params.template = template.url;
        }

        params.event_id = this.props.event.id;

        await this.props
          .onProcessRegistrationBatch(params)
          .then((response) => {
            if (response.response.data.status === "success") {
              message.success('Successfuly upload and process template');
              this.handleCloseModal();
            } else {
              message.error('Error occured: ' + response.response.data.message);
            }
          })
          .catch(error => {
            console.log("error", error);
          });
      } else {
        console.log("error", err);
      }
    });
  }

  render() {
    const { showModal, imagePath } = this.state;
    const { isLoading, match } = this.props;

    return (
      <Modal
        title={`Upload Template`}
        visible={showModal}
        onCancel={this.handleCloseModal}
        centered={true}
        footer={null}
        width={"400px"}
        destroyOnClose={true}
      >
        <Layout className="overview-container">
          <Content className="overview-content" style={{ padding: "10px" }}>
            <Spin spinning={isLoading} key="create-event-spin">
              <Form onSubmit={this.handleSubmit} style={{ width: "100%" }} disabled={isLoading}>
                <Row type="flex" gutter={30}>
                  <Col span={12}>
                    <Form.Item label="">
                      <ImageUploader
                        match={match}
                        accept="application/*excel*"
                        ref={this.templateUploader}
                        fileFolder={imagePath}
                        buttonDesc="Click here to select file to be uploaded"
                        imageUrl={null}
                        filename={null}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row type="flex" gutter={30}>
                  <Col span={24} align="right">
                    <Form.Item>
                      <Button
                        type="default"
                        disabled={isLoading}
                        loading={isLoading}
                        onClick={this.handleCloseModal}
                      >
                        Close
                      </Button>
                      &nbsp;&nbsp;
                      <Button
                        type="primary"
                        htmlType="submit"
                        disabled={isLoading}
                        loading={isLoading}
                      >
                        Upload
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </Content>
        </Layout >
      </Modal>
    );
  }
}

const UploadModalComponent = Form.create({ name: "upload-registration-modal" })(UploadModal);

export default UploadModalComponent;
