const initialData = {
  isLoading: false,
  emailLogsData: [],
  emailLogData: {},
};

export default function admin(state = initialData, action) {
  switch (action.type) {
    case "GET_EMAIL_LOGS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_EMAIL_LOGS_SUCCESS":
      return {
        ...state,
        emailLogsData: action.payload.data,
        isLoading: false,
      };
    case "GET_EMAIL_LOGS_FAILED":
      return {
        ...state,
        emailLogsData: [],
        isLoading: false,
      };

    case "CREATE_EMAIL_LOG_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "CREATE_EMAIL_LOG_SUCCESS":
      return {
        ...state,
        emailLogData: action.payload.data,
        isLoading: false,
      };
    case "CREATE_EMAIL_LOG_FAILED":
      return {
        ...state,
        emailLogData: {},
        isLoading: false,
      };

    case "UPDATE_EMAIL_LOG_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "UPDATE_EMAIL_LOG_SUCCESS":
      return {
        ...state,
        emailLogData: action.payload.data,
        isLoading: false,
      };
    case "UPDATE_EMAIL_LOG_FAILED":
      return {
        ...state,
        emailLogData: {},
        isLoading: false,
      };

    case "DELETE_EMAIL_LOG_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "DELETE_EMAIL_LOG_SUCCESS":
      return {
        ...state,
        emailLogData: action.payload.data,
        isLoading: false,
      };
    case "DELETE_EMAIL_LOG_FAILED":
      return {
        ...state,
        emailLogData: {},
        isLoading: false,
      };

    default:
      return {
        ...state,
      };
  }
}
