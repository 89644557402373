import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "antd";
import Page from "./Page";
import { getPrizes, createPrize, deletePrize, updatePrize } from "./actions"
import { getEvent } from "../../utils/token";

class RaffleItemCard extends Component {
  render() {
    return <Page {...this.props} />;
  }
}

const RaffleItemForm = Form.create({ name: "raffle-prize-form" })(RaffleItemCard);

function mapStateToProps(state) {
  return {
    rPrizesData: state.rprize.rPrizesData,
    rPrizeData: state.rprize.rPrizeData,
    delRPrizeData: state.rprize.delRPrizeData,
    putRPrizeData: state.rprize.putRPrizeData,
    event: getEvent()
  };
}

function mapDispatchToProps(dispatch) {
  return {
    OnGetPrizes: (data) => dispatch(getPrizes(data)),
    OnCreatePrize: (data) => dispatch(createPrize(data)),
    OnDeletePrize: (data) => dispatch(deletePrize(data)),
    OnUpdatePrize: (data) => dispatch(updatePrize(data))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RaffleItemForm);
