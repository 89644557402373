import React, { Component } from "react";
import { Form, Modal, message, Layout, Button, Row, Col, Input, Radio, Switch, DatePicker, TimePicker, Spin, Select, Icon } from "antd";
import moment from "moment";
import { setEvent } from "../../../../utils/token";
import "./index.scss";

const { Content } = Layout;
const { RangePicker } = DatePicker;

const timezone = [
  "GMT-14",
  "GMT-13",
  "GMT-12",
  "GMT-11",
  "GMT-10",
  "GMT-9",
  "GMT-8",
  "GMT-7",
  "GMT-6",
  "GMT-5",
  "GMT-4",
  "GMT-3",
  "GMT-1",
  "GMT+0",
  "GMT+1",
  "GMT+2",
  "GMT+3",
  "GMT+4",
  "GMT+5",
  "GMT+6",
  "GMT+7",
  "GMT+8",
  "GMT+9",
  "GMT+10",
  "GMT+11",
  "GMT+12"
];

class CreateEventModal extends Component {
  initialState = {
    showModal: false
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  handleCloseModal = () => {
    this.setState({
      showModal: false,
    });
    this.props.form.resetFields();
    this.props.handleCloseModal();
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.showModal !== nextProps.showModal) {
      this.setState({ showModal: nextProps.showModal });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const params = {
          ...values,
        };

        if (!params.enable_briefcase) {
          params.enable_briefcase = false
        }

        // if (!params.enable_email_sending) {
        //   params.enable_email_sending = false
        // }

        if (!params.enable_passport) {
          params.enable_passport = false
        }

        params.event_date_start = moment(params.event_date[0]).format('YYYY-MM-DD');
        params.event_date_end = moment(params.event_date[1]).format('YYYY-MM-DD');
        params.event_time_start = moment(params.event_time_start).format("hh:mm A");
        params.event_time_end = moment(params.event_time_end).format("hh:mm A");

        delete params.event_date;
        await this.props
          .onCreateEvent(params)
          .then((response) => {
            if (response.response.data.status === "success") {
              message.success('Successfuly created event: ' + response.response.data.data.event_name);
              this.handleCloseModal();
              setEvent(response.response.data.data);
              window.location = "/overview";
            } else {
              message.error('Error occured: ' + response.response.data.message);
            }
          })
          .catch(error => {
            console.log("error", error);
          });
      } else {
        console.log("error", err);
      }
    });
  }

  render() {
    const { showModal } = this.state;
    const { isLoading, form: { getFieldDecorator }, } = this.props;

    const radioStyle = {
      display: 'block',
      // height: '30px',
      lineHeight: '30px',
      marginBottom: '10px',
    };

    return (
      <Modal
        title={`Create Event`}
        visible={showModal}
        onCancel={this.handleCloseModal}
        centered={true}
        footer={null}
        width={"80%"}
      >
        <Layout className="overview-container">
          <Content className="overview-content" style={{ padding: "10px" }}>
            <Spin spinning={isLoading} key="create-event-spin">
              <Form onSubmit={this.handleSubmit} style={{ width: "100%" }} disabled={isLoading}>
                <Row type="flex" gutter={30}>
                  <Col span={12}>
                    <Form.Item label="Event Name">
                      {getFieldDecorator("event_name", {
                        rules: [
                          {
                            required: true,
                            message: "Please enter Event Name",
                          },
                        ],
                      })(<Input />)}
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="Timezone">
                      {getFieldDecorator("timezone", {
                        rules: [
                          {
                            required: true,
                            message: "Please select Timezone",
                          },
                        ],
                      })(
                        <Select>
                          {timezone.map(row => (
                            <Select.Option value={row} key={row}>{row}</Select.Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row type="flex" gutter={30}>
                  <Col span={12}>
                    <Form.Item label="Event Date">
                      {getFieldDecorator("event_date", {
                        rules: [
                          {
                            required: true,
                            message: "Please enter Event Date",
                          },
                        ],
                      })(<RangePicker />)}
                    </Form.Item>
                  </Col>

                  <Col span={6}>
                    <Form.Item label="Time Start">
                      {getFieldDecorator("event_time_start", {
                        rules: [
                          {
                            required: true,
                            message: "Please enter Time Start",
                          },
                        ],
                      })(<TimePicker use12Hours format={'hh:mm A'} defaultOpenValue={moment('2022-01-01 00:00')} />)}
                    </Form.Item>
                  </Col>

                  <Col span={6}>
                    <Form.Item label="Time End">
                      {getFieldDecorator("event_time_end", {
                        rules: [
                          {
                            required: true,
                            message: "Please enter Time End",
                          },
                        ],
                      })(<TimePicker use12Hours format={'hh:mm A'} defaultOpenValue={moment('2022-01-01 00:00')} />)}
                    </Form.Item>
                  </Col>
                </Row>

                <Row type="flex" gutter={30}>
                  <Col span={12}>
                    <Form.Item label="Address 1">
                      {getFieldDecorator("address1", {
                        rules: [
                          {
                            required: false,
                            message: "Please enter Address 1",
                          },
                        ],
                        initialValue: ""
                      })(<Input />)}
                    </Form.Item>

                    <Form.Item label="Address 2">
                      {getFieldDecorator("address2", {
                        rules: [
                          {
                            required: false,
                            message: "Please enter Address 2",
                          },
                        ],
                        initialValue: ""
                      })(<Input />)}
                    </Form.Item>

                    <Form.Item label="Address 3">
                      {getFieldDecorator("address3", {
                        rules: [
                          {
                            required: false,
                            message: "Please enter Address 3",
                          },
                        ],
                        initialValue: ""
                      })(<Input />)}
                    </Form.Item>
                  </Col>

                  {/* <Col span={4}>
                    <Form.Item label="Enable Passport">
                      {getFieldDecorator("enable_passport", {
                        rules: [],
                      })(<Switch />)}
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <Form.Item label="Enable Briefcase">
                      {getFieldDecorator("enable_briefcase", {
                        rules: [],
                      })(<Switch />)}
                    </Form.Item>
                  </Col> */}

                  {/* <Col span={4}>
                    <Form.Item label="Enable Email Sending">
                      {getFieldDecorator("enable_email_sending", {
                        rules: [],
                      })(<Switch />)}
                    </Form.Item>
                  </Col> */}
                </Row>

                <Row type="flex" gutter={30}>
                  <Col span={24}>
                    <Form.Item label="Event Type">
                      {getFieldDecorator("event_type", {
                        rules: [
                          {
                            required: true,
                            message: "Select Event Type",
                          },
                        ],
                      })(<Radio.Group value={2}>
                        <Radio style={radioStyle} value={"1"}>
                          <span>Public</span>
                          <br />
                          <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</span>
                        </Radio>
                        <Radio style={radioStyle} value={"2"}>
                          <span>Private</span>
                          <br />
                          <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</span>
                        </Radio>
                        <Radio style={radioStyle} value={"3"}>
                          <span>Hidden</span>
                          <br />
                          <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</span>
                        </Radio>
                      </Radio.Group>)}
                    </Form.Item>
                  </Col>
                </Row>

                <Row type="flex" gutter={30}>
                  <Col span={24} align="right">
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        disabled={isLoading}
                        loading={isLoading}
                      >
                        <Icon type="save" />
                        Save
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </Content>
        </Layout >
      </Modal>
    );
  }
}

const CreateEventModalComponent = Form.create({ name: "create-event-modal" })(CreateEventModal);

export default CreateEventModalComponent;
