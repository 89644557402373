import React, { Component } from "react";
import { Form, Layout, Button, Row, Col, Icon, Table, Popconfirm, Tag } from "antd";
import "./index.scss";
import RaffleFilterModal from "./components/RaffleFilterModal.js";
import PageHeader from "../shared/PageHeader";
import { checkPermission } from "../../utils/helper";

const { Content } = Layout;

class RaffleCollection extends Component {
  initialState = {
    rCollectionsData: [],
    showRaffleFilterModal: false,
    selectedRow: {},
    pagination: {},
    passportsData: [],
    ticketsData: [],
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  getData = async () => {
    const onGetParam = {
      event_id: this.props.event.id,
      results: 10
    };
    await this.props.OnGetCollections(onGetParam);
  }

  async componentDidMount() {
    await this.getData()
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.rCollectionsData !== nextProps.rCollectionsData) {
      this.setState({
        rCollectionsData: nextProps.rCollectionsData,
      });
    }

    if (this.props.passportsData !== nextProps.passportsData) {
      this.setState({
        passportsData: nextProps.passportsData,
      });
    }

    if (this.props.ticketsData !== nextProps.ticketsData) {
      this.setState({
        ticketsData: nextProps.ticketsData,
      });
    }
  }

  handleCreateButton = () => {
    this.setState({
      showRaffleFilterModal: true
    });
  };

  handleUpdateButton = (row) => {
    this.setState({
      showRaffleFilterModal: true,
      selectedRow: row
    });
  };

  handleCloseModal = () => {
    this.setState({
      showRaffleFilterModal: false,
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;

    this.setState({
      pagination: pager,
    });

    this.props.OnGetCollections({
      results: pagination.pageSize,
      page: pagination.current,
      event_id: this.props.event.id,
      ...filters,
    });
  };

  handleDeleteButton = async (row) => {
    await this.props.OnDeleteCollection({
      id: row.id,
      event_id: this.props.event.id,
    })
  }

  render() {
    const { rCollectionsData, showRaffleFilterModal, selectedRow, pagination } = this.state;
    const { isLoading, } = this.props;

    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Collection',
        dataIndex: 'collection',
        key: 'collection',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: status => (
          <span>
            {status == 1 ?
              (
                <Tag color='green' key='active'>
                  Active
                </Tag>
              ) : (
                <Tag color='gray' key='inactive'>
                  Inactive
                </Tag>
              )
            }
          </span>
        ),
      },
      {
        title: 'Action',
        key: 'id',
        align: 'center',
        render: (text, record) => (
          <span>
            {/* {(checkPermission(module, "edit")) ? */}
            <Button className="button-icon" onClick={() => this.handleUpdateButton(record)}>
              <Icon type="edit" />
            </Button>
            &nbsp;&nbsp;
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => this.handleDeleteButton(text)}
              okText="Proceed"
              cancelText="Cancel"
            >
              <Button className="button-icon">
                <Icon type="delete" />
              </Button>
            </Popconfirm>
            {/* : ''
            } */}
          </span>
        ),
        // render: (text, record) => <div>
        //   <Icon type="edit" theme="filled" className='action-icon'/>
        //   <Icon type="delete" theme="filled" className='action-icon'/>
        //   <Icon type="download" className='action-icon'/>
        //   {/* <div className='download-icon'><Icon type="download" className='action-icon'/></div> */}
        // </div>
      },
    ];

    return (
      <Layout className="raffle-collection-container">
        <Content className="raffle-collection-content" style={{ padding: "20px 60px" }}>
          <PageHeader rightPanel={(checkPermission(module, "create")) ? <Button type="primary" loading={isLoading} onClick={this.handleCreateButton}><Icon type="plus" />Add Collection</Button> : ''} />

          <Row type="flex" gutter={30}>
            <Col span={24}>
              <Table columns={columns} dataSource={rCollectionsData} loading={isLoading} rowKey={"id"} pagination={pagination} onChange={this.handleTableChange} />
            </Col>
          </Row>
        </Content>
        <RaffleFilterModal showModal={showRaffleFilterModal} selectedRow={selectedRow} handleCloseModal={this.handleCloseModal} {...this.props} getData={this.getData} />
      </Layout>
    );
  }
}

const RaffleCollectionForm = Form.create({ name: "raffle-collection-form" })(RaffleCollection);

export default RaffleCollectionForm;
