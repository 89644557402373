import React, { Component } from "react";
import moment from 'moment'
import { Form, Layout, Button, Row, Col, Icon, Table, Select, Input, Tag, Radio, Tooltip } from "antd";
import "./index.scss";
import PageHeader from "../shared/PageHeader";
import { notify } from "../shared/Notification";
import CreateUpdateModal from "./components/CreateUpdateModal";

const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;

class RaffleController extends Component {
  initialState = {
    rafflePrizeId: null,
    raffleCollectionId: null,
    drawCount: null,
    showPage: '',
    selectedRafflePrize: {},
    batchDrawCount: null,
    settingsData: [],
    prizesData: [],
    collectionsData: [],
    showSettingsModal: false
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  handleCreateButton = () => {
    this.setState({
      showSettingsModal: true
    });
  };

  handleCloseModal = () => {
    this.setState({
      showSettingsModal: false,
    });
  };

  async componentDidMount() {
    const onGetParam = {
      event_id: this.props.event.id
    };
    this.props.OnGetControllerSetup(onGetParam);
    this.props.OnGetRaffleDraws(onGetParam);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.settingsData !== nextProps.settingsData) {
      this.setState({
        settingsData: nextProps.settingsData,
      });
    }

    if (this.props.prizesData !== nextProps.prizesData) {
      this.setState({
        prizesData: nextProps.prizesData,
      });
    }

    if (this.props.collectionsData !== nextProps.collectionsData) {
      this.setState({
        collectionsData: nextProps.collectionsData,
      });
    }

    if (this.props.raffleDrawList !== nextProps.raffleDrawList) {
      this.setState({
        raffleDrawList: nextProps.raffleDrawList,
      });
    }
  }

  async startRaffle() {
    const { rafflePrizeId, raffleCollectionId, batchDrawCount } = this.state

    const data = {
      event_id: this.props.event?.id,
      raffle_collection_id: raffleCollectionId,
      raffle_prize_id: rafflePrizeId,
      batch_draw_count: batchDrawCount
    }

    await this.props.OnCreateRaffleDraw(data)
      .then((response) => {
        if (response.response.data.status === "success") {
          // this.getData()
        }
      })
      .catch(error => {
        console.log("error", error);
      });
  }

  async clearDraw() {
    const data = {
      event_id: this.props.event?.id,
    }

    await this.props.OnClearDraw(data)
      .then((response) => {

      })
      .catch(error => {
        console.log("error", error);
      });
  }

  async onChangeRadio(e) {
    this.setState({
      showPage: e.target.value
    })

    await this.props.OnUpdateRaffleViewer({
      event_id: this.props.event?.id,
      value: e.target.value
    })
      .then((response) => {
        if (response.response.data.status === "success") {
          notify("info", "Updated show page to " + e.target.value)
        }
      })
      .catch(error => {
        console.log("error", error);
        notify("error", JSON.stringify(error))
      });
  }

  handlePrizeChange = e => {
    let selectedRafflePrize = {};
    this.state.prizesData.forEach(row => {
      if (row.id === e) {
        selectedRafflePrize = row;
      }
    });
    this.setState({
      rafflePrizeId: e,
      batchDrawCount: null,
      selectedRafflePrize
    });
  }

  handleClaim = async (row, is_claim) => {
    const params = {
      event_id: this.props.event.id,
      is_claim: is_claim,
      id: row.id
    };

    await this.props
      .onUpdateRaffleDraw(params)
      .then((response) => {
        if (response.response.data.status === "success") {
          notify('success', 'Claim status updated: ');
          this.handleCloseModal();
        } else {
          notify('error', response.response.data.message);
        }
      })
      .catch(error => {
        notify('error', error.message);
        console.log("error", error);
      });
  }

  render() {
    const { settingsData, prizesData, collectionsData, raffleDrawList, rafflePrizeId, raffleCollectionId, showPage, selectedRafflePrize, batchDrawCount, showSettingsModal } = this.state;
    const { isLoading, form: { getFieldDecorator } } = this.props;

    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Prize',
        dataIndex: 'prize',
        key: 'prize',
        render: (prize, row) => <div>{prize} [{row.prize_category}]</div>
      },
      {
        title: 'Claim Status',
        dataIndex: 'is_claim',
        key: 'is_claim',
        render: is_claim => (
          <span>
            {is_claim == 0 ? <Tag color='gray' key='active'>Not Yet</Tag> : ''}
            {is_claim == 1 ? <Tag color='green' key='active'>Claimed</Tag> : ''}
            {is_claim == 2 ? <Tag color='red' key='active'>Not Claimed</Tag> : ''}
          </span>
        ),
      },
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        align: 'center',
        render: (value) => <div>{moment(value).format('h:m A')}</div>
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        render: (text, row) => (
          <span key={row.id}>
            &nbsp;&nbsp;
            <Tooltip title="No Claimed">
              <Button className="button-icon" disabled={row.is_claim == 2} onClick={() => this.handleClaim(row, 2)}><Icon type="close" /></Button>
            </Tooltip>
            &nbsp;&nbsp;
            <Tooltip title="Not Yet">
              <Button className="button-icon" disabled={row.is_claim == 0} onClick={() => this.handleClaim(row, 0)}><Icon type="exclamation" /></Button>
            </Tooltip>
            &nbsp;&nbsp;
            <Tooltip title="Claimed">
              <Button className="button-icon" disabled={row.is_claim == 1} onClick={() => this.handleClaim(row, 1)}><Icon type="check" /></Button>
            </Tooltip>
          </span>
        ),
      },
    ];

    let activePage = {};
    let winners = [];
    let prize = {};

    settingsData.forEach(row => {
      if (row.name == "show_page") {
        activePage = row;
      }

      if (row.name == "winner") {
        winners = row;
      }

      if (row.name == "prize") {
        prize = row;
      }
    });

    return (
      <Layout className="raffle-controller-container">
        <Content className="raffle-controller-content" style={{ padding: "20px 60px" }}>
          <Form onSubmit={this.handleSubmit} style={{ width: "100%" }} disabled={isLoading}>
            <PageHeader rightPanel={<>
              <Button type="primary" href={`${process.env.REACT_APP_PORTAL_URL}event-raffle/${this.props.event.page_details.event_slug}`} target="_blank">
                <Icon type="export" />
                Raffle Page
              </Button>
              &nbsp;&nbsp;
              <Button
                type="primary"
                loading={isLoading}
                onClick={this.handleCreateButton}
              >
                <Icon type="setting" />
                Settings
              </Button>
            </>} />

            <Row type="flex" gutter={30}>
              <Col span={8}>
                <Form.Item label="Prize">
                  <Select className="select-field"
                    onChange={(e) => this.handlePrizeChange(e)}
                    optionLabelProp="label">
                    {prizesData?.map((prize, index) =>
                      <Option key={index} value={prize?.id} label={`${prize.label} [${prize.name}]`}>
                        <Row type="flex" gutter={30}>
                          <Col span={6} style={{ position: "relative", height: "70px" }}>
                            <img height="100%" src={process.env.REACT_APP_S3_BUCKET + prize.prize_image} />
                          </Col>
                          <Col span={18}>
                            <div style={{ fontWeight: "bold" }}>{prize?.label}</div>
                            <div>{prize?.name}</div>
                            <div>Win Draw Ratio: {prize?.win_count} / {prize?.draw_count}</div>
                          </Col>
                        </Row>
                      </Option>
                    )}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label="Collection">
                  <Select className="select-field" onChange={(e) => this.setState({ raffleCollectionId: e })}>
                    {collectionsData?.map((collection, index) => <Option key={index} value={collection?.id}>{collection?.name}</Option>)}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label="Batch Draw Count">
                  <Input type="number"
                    min={selectedRafflePrize && selectedRafflePrize.draw_count ? 1 : 0}
                    max={selectedRafflePrize && selectedRafflePrize.draw_count ? selectedRafflePrize.draw_count - selectedRafflePrize.win_count : 0}
                    onChange={(e) => this.setState({ batchDrawCount: e.target.value })}
                    disabled={selectedRafflePrize && selectedRafflePrize.draw_count - selectedRafflePrize.win_count > 0 ? false : true}
                    readOnly={selectedRafflePrize && selectedRafflePrize.draw_count - selectedRafflePrize.win_count > 0 ? false : true}
                    defaultValue={batchDrawCount} value={batchDrawCount} />
                </Form.Item>
              </Col>
            </Row>

            <Row type="flex" gutter={30}>
              <Col span={8}>
                <Form.Item label="Win Count">
                  <Input type="number" value={selectedRafflePrize ? selectedRafflePrize.win_count : ''} disabled={true} readOnly={true} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Max Draw Count">
                  <Input type="number" value={selectedRafflePrize ? selectedRafflePrize.draw_count : ''} disabled={true} readOnly={true} />
                </Form.Item>
              </Col>
              <Col span={8} align="right" style={{ height: "60px", lineHeight: "60px" }}>
                <div style={{ lineHeight: "1.5", display: "inline-block", verticalAlign: "bottom", marginRight: 20 }}>
                  <Button type="primary" icon="rollback" onClick={() => this.clearDraw()}>Clear Draw</Button>
                </div>

                <div style={{ lineHeight: "1.5", display: "inline-block", verticalAlign: "bottom" }}>
                  <Button type="primary" icon="caret-right" onClick={() => this.startRaffle()} disabled={!rafflePrizeId || !raffleCollectionId || !batchDrawCount}>Draw</Button>
                </div>
              </Col>
            </Row>

            <hr />

            <Row type="flex" gutter={30}>
              <Col span={12}>
                <Form.Item label="Show Page">
                  <Radio.Group
                    onChange={(e) => this.onChangeRadio(e)}
                    value={activePage.value} >
                    <Radio.Button value={'Blank'}>Blank</Radio.Button>
                    <Radio.Button value={'Splash'}>Splash</Radio.Button>
                    <Radio.Button value={'Prize'}>Prize</Radio.Button>
                    <Radio.Button value={'Winner'}>Winner</Radio.Button>
                    <Radio.Button value={'Thank You'}>Thank You</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item label="Winner/s to be displayed">
                  <b>{winners && winners.value ? winners.value.join(', ') : ''}</b>
                </Form.Item>
              </Col>
            </Row>

            <Row type="flex" gutter={30}>
              <Col span={24}>
                <Table columns={columns} dataSource={raffleDrawList} loading={isLoading} rowKey={"id"} />
              </Col>
            </Row>
          </Form>

          <Row type="flex" gutter={30}>
            {/* <Col span={24}>
              <div className="main-container">
                <div className="container-title">Raffle</div>
                <Row>
                  <Col span={24}>
                    <div className="field-container">
                      <div className="field-label">Raffle Mode</div>
                      <div className="switch-div">
                        <Switch defaultChecked />
                        <div className="switch-text">Active</div>
                      </div>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="field-container">
                      <Row type="flex" gutter={30} justify="start">
                        <Col xs={24} md={12}>
                          <div className="field-label">Prize</div>
                          <Select className="select-field" onChange={(e) => this.setState({ rafflePrizeId: e })}>
                            {activePrizesData?.map((prize, index) => <Option key={index} value={prize?.id}>{prize?.name} ({prize?.win_count} / {prize?.draw_count})</Option>)}
                          </Select>
                        </Col>
                        <Col xs={24} md={12}>
                          <div className="field-label">Filter</div>
                          <Select className="select-field" onChange={(e) => this.setState({ raffleCollectionId: e })}>
                            {
                              activeCollectionData?.map((collection, index) => <Option key={index} value={collection?.id}>{collection?.name}</Option>)
                            }
                          </Select>
                        </Col>
                      </Row>
                      <Row type="flex" gutter={30} justify="space-between">
                        <Col xs={24} md={12}>
                          <Radio.Group onChange={(e) => this.onChangeRadio(e)} value={showPage} className="show-radios-grp">
                            <Radio.Button value={'Blank'}>blank</Radio.Button>
                            <Radio.Button value={'Splash'}>splash</Radio.Button>
                            <Radio.Button value={'Prize'}>prize</Radio.Button>
                            <Radio.Button value={'Winner'}>winner</Radio.Button>
                          </Radio.Group>
                        </Col>
                        <Col xs={24} md={12}>
                          <div className="field-label">Draw Count</div>
                          <Input defaultValue={0} onChange={(e) => this.setState({ drawCount: e.target.value })} />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div>
                      <Button icon="caret-right" className='page-btn-disabled' onClick={() => this.startRaffle()} disabled={!rafflePrizeId && !raffleCollectionId}>START RAFFLE DRAW</Button>
                      <Button type="primary" icon="arrows-alt" className='page-btn' onClick={() => window.open(`/raffle-viewer/${this.props.event?.id}`, "_blank")}>OPEN RAFFLE PAGE</Button>
                      <Button type="primary" icon="cloud-download" className='page-btn'>DOWNLOAD PRIZE WINNERS</Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col> */}
            {/* <Col span={24}>
              <h2>Winners(2)</h2>
            </Col>
            <Col span={24}>
              <Search
                placeholder="Search"
                onSearch={value => console.log(value)}
                className="search-winner-field"
              />
            </Col>
            <Col span={24}>
              <Table columns={columns} dataSource={raffleDrawList} loading={isLoading} rowKey={"id"} />
            </Col> */}
          </Row>
        </Content>
        <CreateUpdateModal showModal={showSettingsModal} settingsData={settingsData} handleCloseModal={this.handleCloseModal} {...this.props} />
      </Layout>
    );
  }
}

const RaffleControllerForm = Form.create({ name: "raffle-controller-form" })(RaffleController);

export default RaffleControllerForm;
