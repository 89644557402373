import React, { Component } from "react";
import { Form, message, Layout, Button, Row, Col, Input, Switch, Spin, Icon, Tabs } from "antd";
import "./index.scss";
import ImageUploader from "../shared/ImageUploader";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { checkPermission } from "../../utils/helper";
import { Redirect } from "react-router-dom";
import PageHeader from "../shared/PageHeader";
import { notify, notifyFormError } from "../shared/Notification";

const { Content } = Layout;
const { TabPane } = Tabs;

const html = '';
const contentBlock = htmlToDraft(html);
const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
const editorState = EditorState.createWithContent(contentState);

class Overview extends Component {
  initialState = {
    event: {},
    eventPageData: {},
    primaryColor: "#1155B3",
    secondaryColor: "#F4202A",
    primaryFontColor: "#000000",
    secondaryFontColor: "#000000",
    imagePath: "uploads/events",
    detailsText: editorState,
    thankYouText: editorState,
    popupText: editorState,
    disclaimerText: editorState,
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
    this.coverPhotoUploader = React.createRef();
    this.logoUploader = React.createRef();
    this.detailsEditor = React.createRef();
    this.thankyouEditor = React.createRef();
    this.popupEditor = React.createRef();
    this.disclaimerEditor = React.createRef();

    this.mobileCoverPhotoUploader = React.createRef();
    this.mobileLogoUploader = React.createRef();
  }

  handleSubmit = (e) => {
    const { detailsText, thankYouText, popupText, disclaimerText } = this.state;
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const params = {
          ...values,
        };

        params.details = draftToHtml(convertToRaw(detailsText.getCurrentContent()))
        params.thank_you_text = draftToHtml(convertToRaw(thankYouText.getCurrentContent()))
        params.popup_text = draftToHtml(convertToRaw(popupText.getCurrentContent()))
        params.disclaimer_text = draftToHtml(convertToRaw(disclaimerText.getCurrentContent()))

        const coverPhoto = await this.coverPhotoUploader?.current?.handleUploadRequest();
        const logo = await this.logoUploader?.current?.handleUploadRequest();

        const event = this.state.eventPageData;
        params.id = event.id;
        params.event_id = event.event_id;
        if (coverPhoto) {
          params.cover_photo = coverPhoto.url;
        }
        if (logo) {
          params.display_photo = logo.url;
        }

        if (!params.enable_hosted_by) {
          params.enable_hosted_by = false
        }

        if (!params.enable_pop) {
          params.enable_pop = false
        }

        if (!params.enable_countdown) {
          params.enable_countdown = false
        }

        if (!params.enable_date_time) {
          params.enable_date_time = false
        }

        if (!params.enable_address) {
          params.enable_address = false
        }

        // MOBILE SETTINGS
        const mobileCoverPhoto = await this.mobileCoverPhotoUploader?.current?.handleUploadRequest();
        const mobileLogo = await this.mobileLogoUploader?.current?.handleUploadRequest();
        let mobileSettings = {};

        if (mobileCoverPhoto) {
          mobileSettings.cover_photo = mobileCoverPhoto?.url;
        }

        if (mobileLogo) {
          mobileSettings.display_photo = mobileLogo?.url;
        }

        params.mobile = JSON.stringify(mobileSettings);
        // END MOBILE SETTINGS

        await this.props
          .onUpdateEventPage(params)
          .then((response) => {
            if (response.response.data.status === "success") {
              notify('success', 'Successfuly updated event page');
            } else {
              notify('error', response.response.data.message);
            }
          })
          .catch(error => {
            notify('error', error.message);
          });
      } else {
        notifyFormError(err);
        console.log("error", err);
      }
    });
  }

  componentDidMount() {
    const onGetParam = {
      event_id: this.props.event.id
    };
    this.props.onGetEventPage(onGetParam);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.eventPageData !== nextProps.eventPageData && nextProps.eventPageData) {
      const eventPageData = { ...nextProps.eventPageData };

      if (nextProps.eventPageData.details && nextProps.eventPageData.details.trim() != "") {
        const detailsBlock = htmlToDraft(nextProps.eventPageData.details);
        if (detailsBlock) {
          const detailsState = ContentState.createFromBlockArray(detailsBlock.contentBlocks);
          const detailsTextState = EditorState.createWithContent(detailsState);
          this.setState({
            detailsText: detailsTextState,
          });
        }
      }

      if (nextProps.eventPageData.thank_you_text && nextProps.eventPageData.thank_you_text.trim() != "") {
        const thankyouBlock = htmlToDraft(nextProps.eventPageData.thank_you_text);
        if (thankyouBlock) {
          const thankyouState = ContentState.createFromBlockArray(thankyouBlock.contentBlocks);
          const thankYouTextState = EditorState.createWithContent(thankyouState);
          this.setState({
            thankYouText: thankYouTextState,
          });
        }
      }

      if (nextProps.eventPageData.popup_text && nextProps.eventPageData.popup_text.trim() != "") {
        const popupBlock = htmlToDraft(nextProps.eventPageData.popup_text);
        if (popupBlock) {
          const popupState = ContentState.createFromBlockArray(popupBlock.contentBlocks);
          const popupTextState = EditorState.createWithContent(popupState);
          this.setState({
            popupText: popupTextState,
          });
        }
      }

      if (nextProps.eventPageData.disclaimer_text && nextProps.eventPageData.disclaimer_text.trim() != "") {
        const disclaimerBlock = htmlToDraft(nextProps.eventPageData.disclaimer_text);
        if (disclaimerBlock) {
          const disclaimerState = ContentState.createFromBlockArray(disclaimerBlock.contentBlocks);
          const disclaimerTextState = EditorState.createWithContent(disclaimerState);
          this.setState({
            disclaimerText: disclaimerTextState,
          });
        }
      }

      eventPageData.primaryColor = nextProps.eventPageData.primary_color ?? "#1155B3";
      eventPageData.primaryFontColor = nextProps.eventPageData.primary_font_color ?? "#000000";
      eventPageData.secondaryColor = nextProps.eventPageData.secondary_color ?? "#F4202A";
      eventPageData.secondaryFontColor = nextProps.eventPageData.secondary_font_color ?? "#000000";

      this.setState({
        eventPageData: eventPageData,
        primaryColor: eventPageData.primary_color ?? "#1155B3",
        primaryFontColor: eventPageData.primary_font_color ?? "#000000",
        secondaryColor: eventPageData.secondary_color ?? "#F4202A",
        secondaryFontColor: eventPageData.secondary_font_color ?? "#000000",
        imagePath: "uploads/events/" + eventPageData.event_id + "/details"
      })
    }
  }

  handleDetailsChange = value => {
    this.setState({
      detailsText: value
    });
  }

  handleThankYouChange = value => {
    this.setState({
      thankYouText: value
    });
  }

  handlePopupChange = value => {
    this.setState({
      popupText: value
    });
  }

  handleDisclaimerChange = value => {
    this.setState({
      disclaimerText: value
    });
  }

  handleChangeColor = (e, type) => {
    const color = e.target.value;

    if (type == "primary") {
      this.setState({
        primaryColor: color
      });
    } else if (type == "secondary") {
      this.setState({
        secondaryColor: color
      });
    } else if (type == "primary_font") {
      this.setState({
        primaryFontColor: color
      });
    } else if (type == "secondary_font") {
      this.setState({
        secondaryFontColor: color
      });
    }
  }

  render() {
    const { eventPageData, primaryColor, secondaryColor, primaryFontColor, secondaryFontColor, imagePath, detailsText, thankYouText, popupText, disclaimerText } = this.state;
    const { isLoading, form: { getFieldDecorator }, match, event } = this.props;
    const module = "event-page";

    if (!checkPermission(module, "view")) {
      return (<Redirect to="/403" />);
    }

    if (!eventPageData) {
      return null
    }

    const mobileSettings = eventPageData.mobile ? JSON.parse(eventPageData.mobile) : null;

    return (
      <Layout className="event-page-container">
        <Content className="event-page-content" style={{ padding: "20px 60px" }}>
          <Spin spinning={isLoading} key="event-page-spin">
            <Form onSubmit={this.handleSubmit} style={{ width: "100%" }} disabled={isLoading}>
              <PageHeader rightPanel={<>
                <Button type="primary" href={`${process.env.REACT_APP_PORTAL_URL}event/${eventPageData.event_slug}`} target="_blank">
                  <Icon type="export" />
                  Go to Page
                </Button>
                &nbsp;&nbsp;
                {(checkPermission(module, "edit")) ?
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={isLoading}
                    loading={isLoading}
                  >
                    <Icon type="save" />
                    Save
                  </Button> : ''}
              </>} />

              {/* <Row type="flex" gutter={30}>
                <Col span={12} style={{ textAlign: "right" }}>
                  <Button type="primary" href={`${process.env.REACT_APP_PORTAL_URL}event/${eventPageData.event_slug}`} target="_blank">
                    <Icon type="export" />
                    Go to Event Page
                  </Button>
                </Col>
              </Row> */}

              <Tabs>
                <TabPane tab="Settings" key="settings">
                  <Row type="flex" gutter={30}>
                    <Col span={12}>
                      <Form.Item label="Event Name">
                        <Input readOnly={true} value={event ? event.event_name : ""} />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item label="Custom URL">
                        {getFieldDecorator("event_slug", {
                          rules: [
                            {
                              required: true,
                              message: "Please enter Custom URL",
                            },
                          ],
                          initialValue: eventPageData ? eventPageData.event_slug : ""
                        })(<Input />)}
                      </Form.Item>
                    </Col>
                  </Row>
                </TabPane>

                <TabPane tab="Landing Page" key="landing_page">
                  <Row type="flex" gutter={30}>
                    <Col span={24}>
                      <Form.Item label="Cover Photo">
                        <ImageUploader
                          match={match}
                          ref={this.coverPhotoUploader}
                          fileFolder={imagePath}
                          buttonDesc="Click here to select file to be uploaded"
                          imageUrl={eventPageData && eventPageData.cover_photo ? eventPageData.cover_photo : null}
                          filename={eventPageData.cover_photo}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row type="flex" gutter={30}>
                    <Col span={12}>
                      <Form.Item label="Logo">
                        <ImageUploader
                          match={match}
                          ref={this.logoUploader}
                          fileFolder={imagePath}
                          buttonDesc="Click here to select file to be uploaded"
                          imageUrl={eventPageData && eventPageData.display_photo ? eventPageData.display_photo : null}
                          filename={eventPageData.display_photo}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={4}>
                      <Form.Item label="Primary Color">
                        {getFieldDecorator("primary_color", {
                          rules: [
                            {
                              required: true,
                              message: "Please enter Primary Color",
                            },
                          ],
                          initialValue: eventPageData ? eventPageData.primary_color : primaryColor
                        })(<Input onChange={e => this.handleChangeColor(e, "primary")} />)}
                        <div style={{ backgroundColor: primaryColor }}>&nbsp;</div>
                      </Form.Item>

                      <Form.Item label="Primary font Color">
                        {getFieldDecorator("primary_font_color", {
                          rules: [
                            {
                              required: true,
                              message: "Please enter Primary Font Color",
                            },
                          ],
                          initialValue: eventPageData ? eventPageData.primary_font_color : primaryFontColor
                        })(<Input onChange={e => this.handleChangeColor(e, "primary_font")} />)}
                        <div style={{ backgroundColor: primaryFontColor }}>&nbsp;</div>
                      </Form.Item>
                    </Col>

                    <Col span={4}>
                      <Form.Item label="Secondary Color">
                        {getFieldDecorator("secondary_color", {
                          rules: [
                            {
                              required: true,
                              message: "Please enter Secondary Color",
                            },
                          ],
                          initialValue: eventPageData ? eventPageData.secondary_color : secondaryColor
                        })(<Input onChange={e => this.handleChangeColor(e, "secondary")} />)}
                        <div style={{ backgroundColor: secondaryColor }}>&nbsp;</div>
                      </Form.Item>

                      <Form.Item label="Secondary Font Color">
                        {getFieldDecorator("secondary_font_color", {
                          rules: [
                            {
                              required: true,
                              message: "Please enter Secondary Font Color",
                            },
                          ],
                          initialValue: eventPageData ? eventPageData.secondary_font_color : secondaryFontColor
                        })(<Input onChange={e => this.handleChangeColor(e, "secondary_font")} />)}
                        <div style={{ backgroundColor: secondaryFontColor }}>&nbsp;</div>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row type="flex" gutter={30}>
                    <Col span={24}>
                      <Form.Item label="Details">
                        {getFieldDecorator("details", {
                          rules: [
                          ],
                          // initialValue: eventPageData ? eventPageData.details : ""
                        })(<Editor
                          ref={this.detailsEditor}
                          editorState={detailsText}
                          wrapperClassName="wysiwyg-editor"
                          editorClassName="wysiwyg-editor-textarea"
                          onEditorStateChange={this.handleDetailsChange}
                        />)}
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row type="flex" gutter={30}>
                    <Col span={12}>
                      <Form.Item label="Join Button Text">
                        {getFieldDecorator("join_button_text", {
                          rules: [
                            {
                              required: true,
                              message: "Please enter Join Button Text",
                            },
                          ],
                          initialValue: eventPageData ? eventPageData.join_button_text : ""
                        })(<Input />)}
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row type="flex" gutter={30}>
                    <Col span={4}>
                      <Form.Item label="Enable Hosted By">
                        {getFieldDecorator("enable_hosted_by", {
                          rules: [],
                          valuePropName: 'checked',
                          initialValue: eventPageData && eventPageData.enable_hosted_by == 1 ? true : false
                        })(<Switch />)}
                      </Form.Item>
                    </Col>

                    <Col span={4}>
                      <Form.Item label="Enable Popup">
                        {getFieldDecorator("enable_pop", {
                          rules: [],
                          valuePropName: 'checked',
                          initialValue: eventPageData && eventPageData.enable_pop == 1 ? true : false
                        })(<Switch />)}
                      </Form.Item>
                    </Col>

                    <Col span={4}>
                      <Form.Item label="Show Date Time">
                        {getFieldDecorator("enable_date_time", {
                          rules: [],
                          valuePropName: 'checked',
                          initialValue: eventPageData && eventPageData.enable_date_time == 1 ? true : false
                        })(<Switch />)}
                      </Form.Item>
                    </Col>

                    <Col span={4}>
                      <Form.Item label="Show Address">
                        {getFieldDecorator("enable_address", {
                          rules: [],
                          valuePropName: 'checked',
                          initialValue: eventPageData && eventPageData.enable_address == 1 ? true : false
                        })(<Switch />)}
                      </Form.Item>
                    </Col>

                    <Col span={4}>
                      <Form.Item label="Show Countdown">
                        {getFieldDecorator("enable_countdown", {
                          rules: [],
                          valuePropName: 'checked',
                          initialValue: eventPageData && eventPageData.enable_countdown == 1 ? true : false
                        })(<Switch />)}
                      </Form.Item>
                    </Col>
                  </Row>
                </TabPane>

                <TabPane tab="Popup" key="popup">
                  <Row type="flex" gutter={30}>
                    <Col span={24}>
                      <Form.Item label="Popup Title">
                        {getFieldDecorator("popup_title", {
                          rules: [
                            {
                              required: false,
                              message: "Please enter Popup Title",
                            },
                          ],
                          initialValue: eventPageData ? eventPageData.popup_title : ""
                        })(<Input />)}
                      </Form.Item>

                      <Form.Item label="Popup Text">
                        {getFieldDecorator("popup_text", {
                          rules: [
                            {
                              required: false,
                              message: "Please enter Popup Text",
                            },
                          ],
                          // initialValue: eventPageData ? eventPageData.popup_text : ""
                        })(<Editor
                          ref={this.popupEditor}
                          editorState={popupText}
                          wrapperClassName="wysiwyg-editor"
                          editorClassName="wysiwyg-editor-textarea"
                          onEditorStateChange={this.handlePopupChange}
                        />)}
                      </Form.Item>
                    </Col>
                  </Row>
                </TabPane>

                <TabPane tab="Disclaimer" key="disclaimer">
                  <Row type="flex" gutter={30}>
                    <Col span={24}>
                      <Form.Item label="Disclaimer Title">
                        {getFieldDecorator("disclaimer_title", {
                          rules: [
                            {
                              required: true,
                              message: "Please enter Disclaimer Title",
                            },
                          ],
                          initialValue: eventPageData ? eventPageData.disclaimer_title : ""
                        })(<Input />)}
                      </Form.Item>

                      <Form.Item label="Disclaimer Text">
                        {getFieldDecorator("disclaimer_text", {
                          rules: [
                            // {
                            //   required: true,
                            //   message: "Please enter Disclaimer Text",
                            // },
                          ],
                          // initialValue: eventPageData ? eventPageData.disclaimer_text : ""
                        })(<Editor
                          ref={this.disclaimerEditor}
                          editorState={disclaimerText}
                          wrapperClassName="wysiwyg-editor"
                          editorClassName="wysiwyg-editor-textarea"
                          onEditorStateChange={this.handleDisclaimerChange}
                        />)}
                      </Form.Item>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab="Thank You Page" key="thank_you_page">
                  <Row type="flex" gutter={30}>
                    <Col span={24}>
                      <Form.Item label="Thank you text">
                        {getFieldDecorator("thank_you_text", {
                          rules: [
                          ],
                          // initialValue: eventPageData ? eventPageData.thank_you_text : ""
                        })(<Editor
                          ref={this.thankyouEditor}
                          editorState={thankYouText}
                          wrapperClassName="wysiwyg-editor"
                          editorClassName="wysiwyg-editor-textarea"
                          onEditorStateChange={this.handleThankYouChange}
                        />)}
                      </Form.Item>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab="Mobile" key="mobile">
                  <Row type="flex" gutter={30}>
                    <Col span={24}>
                      <Form.Item label="Cover Photo">
                        <ImageUploader
                          match={match}
                          ref={this.mobileCoverPhotoUploader}
                          fileFolder={imagePath}
                          buttonDesc="Click here to select file to be uploaded"
                          imageUrl={mobileSettings && mobileSettings.cover_photo ? mobileSettings.cover_photo : null}
                          filename={mobileSettings && mobileSettings.cover_photo ? mobileSettings.cover_photo : null}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row type="flex" gutter={30}>
                    <Col span={12}>
                      <Form.Item label="Logo">
                        <ImageUploader
                          match={match}
                          ref={this.mobileLogoUploader}
                          fileFolder={imagePath}
                          buttonDesc="Click here to select file to be uploaded"
                          imageUrl={mobileSettings && mobileSettings.display_photo ? mobileSettings.display_photo : null}
                          filename={mobileSettings && mobileSettings.display_photo ? mobileSettings.display_photo : null}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </TabPane>
              </Tabs>

              {(checkPermission(module, "edit")) ?
                (
                  <Row type="flex" gutter={30}>
                    <Col span={24} align="right">
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          disabled={isLoading}
                          loading={isLoading}
                        >
                          <Icon type="save" />
                          Save
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                ) : ''
              }
            </Form>
          </Spin>
        </Content>
      </Layout >
    );
  }
}

const OverviewForm = Form.create({ name: "overview-form" })(Overview);

export default OverviewForm;
