import React, { Component } from "react";
import { Input, Icon, message, Button, Table, Row, Col } from "antd";
import { getEvent } from "../../../utils/token";
import "./index.css";

class DataTable extends Component {
  state = {
    isLoading: false,
    columns: [],
    dataSource: [],
    pagination: {},
    filters: {},
    sorter: {},
    searchText: '',
    searchedColumn: '',
    defaultPageSize: 10
  };

  componentDidMount() {
    const pagination = { ...this.state.pagination };
    pagination.total = this.props.total ?? 0;
    pagination.pageSize = this.state.defaultPageSize;

    this.setState({
      columns: this.props.columns,
      dataSource: this.props.dataSource,
      ...pagination,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.columns !== nextProps.columns) {
      let newColumns = [];
      nextProps.columns.forEach(row => {
        if (row.searchKey) {
          row = {
            ...row,
            ...this.getColumnSearchProps(row.searchKey)
          };
        }
        newColumns.push(row);
      })
      this.setState({
        columns: newColumns
      });
    }

    if (this.props.dataSource !== nextProps.dataSource) {
      this.setState({
        dataSource: nextProps.dataSource
      });
    }

    if (this.props.total !== nextProps.total) {
      const pagination = { ...this.state.pagination };
      pagination.total = nextProps.total;
      pagination.current = 1;
      pagination.showTotal = (total, range) => (<>{range[0]} - {range[1]} of <b>{total}</b></>)
      this.setState({
        pagination,
      });
    }
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  handleTableChange = (page, filters, sorter) => {
    const event = getEvent();
    const pagination = { ...this.state.pagination };
    pagination.current = page.current;
    pagination.pageSize = page.pageSize;

    this.setState({
      pagination: pagination,
      filters: filters,
      sorter: sorter
    });

    const params = {
      results: pagination.pageSize,
      page: pagination.current,
      sort_field: sorter.field ?? null,
      sort_order: sorter.order ?? null,
      event_id: event.id,
      ...filters,
    };

    this.props.onTableChange(params);
  };

  handleTableExport = type => {
    const event = getEvent();
    const { pagination, sorter, filters, defaultPageSize } = this.state;

    const params = {
      results: pagination.pageSize ?? defaultPageSize,
      page: pagination.current,
      sort_field: sorter.field ?? null,
      sort_order: sorter.order ?? null,
      event_id: event.id,
      export_type: type,
      ...filters,
    };
  }

  generateHeader = () => {
    // console.log("this.props", this.props);
    return;
    return (
      <Row type="flex" gutter={30}>
        <Col span={12}></Col>
        <Col span={12} align="right">
          <Button onClick={() => this.handleTableExport('all')}><Icon type="file-excel" />All</Button>&nbsp;
          <Button onClick={() => this.handleTableExport('current')}><Icon type="file-excel" />Current</Button>
        </Col>
      </Row>
    );
  }

  sortAlphabetically = (a, b) => { }

  render() {
    const { isLoading, columns, dataSource, pagination } = this.state;
    // console.log("this.state", this.state);

    let transformColumns = [];
    columns.forEach(row => {
      if (row.filterable) {
        row = {
          ...row,
          ...this.getColumnSearchProps(row.key),
        }
      }
      // row.sort = "hey";
      // row.sorter = (a, b) => a.length - b.length;
      // row.sortDirections = ['descend', 'ascend']
      transformColumns.push(row);
    });

    return (
      <Table
        loading={isLoading}
        columns={transformColumns}
        dataSource={dataSource}
        rowKey={record => record.id}
        onChange={this.handleTableChange}
        pagination={pagination}
        expandedRowRender={this.props.expandedRowRender ?? ''}
        title={this.generateHeader}
      />
    );
  }
}

export default DataTable;

// <DataTable isLoading={} columns={} dataSource={} total={} onTableChange={} expandedRowRenderer={} />
