import React, { Component } from "react";
import { Form, message, Layout, Button, Row, Col, Input, Radio, Switch, DatePicker, TimePicker, Spin, Popconfirm, Select, Icon } from "antd";
import moment from "moment";
import "./index.scss";
import { getEvent, setEvent, deleteEvent } from "../../utils/token";
import { checkPermission } from "../../utils/helper";
import { Redirect } from "react-router-dom";
import PageHeader from "../shared/PageHeader";
import { notify, notifyFormError } from "../shared/Notification";

const { Content } = Layout;
const { RangePicker } = DatePicker;

const timezone = [
  "GMT-14",
  "GMT-13",
  "GMT-12",
  "GMT-11",
  "GMT-10",
  "GMT-9",
  "GMT-8",
  "GMT-7",
  "GMT-6",
  "GMT-5",
  "GMT-4",
  "GMT-3",
  "GMT-1",
  "GMT+0",
  "GMT+1",
  "GMT+2",
  "GMT+3",
  "GMT+4",
  "GMT+5",
  "GMT+6",
  "GMT+7",
  "GMT+8",
  "GMT+9",
  "GMT+10",
  "GMT+11",
  "GMT+12"
];

class Overview extends Component {
  initialState = {
    event: {}
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const params = {
          ...values,
        };

        const event = this.state.event;
        params.id = event.id;

        if (!params.enable_briefcase) {
          params.enable_briefcase = false
        }

        // if (!params.enable_email_sending) {
        //   params.enable_email_sending = false
        // }

        if (!params.enable_passport) {
          params.enable_passport = false
        }

        params.event_date_start = moment(params.event_date[0]).format('YYYY-MM-DD');
        params.event_date_end = moment(params.event_date[1]).format('YYYY-MM-DD');
        params.event_time_start = moment(params.event_time_start).format("hh:mm A");
        params.event_time_end = moment(params.event_time_end).format("hh:mm A");

        delete params.event_date;
        await this.props
          .onUpdateEvent(params)
          .then((response) => {
            if (response.response.data.status === "success") {
              notify('success', 'Successfuly updated event: ' + response.response.data.data.event_name);
              setEvent(response.response.data.data);
            } else {
              notify('error', response.response.data.message);
            }
          })
          .catch(error => {
            notify('error', error.message);
          });
      } else {
        notifyFormError(err);
      }
    });
  }

  handleDeactivateEvent = () => {
    const params = {
      status: 2
    };

    const event = this.state.event;
    params.id = event.id;

    this.props
      .onUpdateEvent(params)
      .then((response) => {
        if (response.response.data.status === "success") {
          notify('success', 'Successfuly deactivated event: ' + response.response.data.data.event_name);
          setEvent(response.response.data.data);
        } else {
          notify('error', response.response.data.message);
        }
      })
      .catch(error => {
        notify('error', error.message);
      });
  }

  handleActivateEvent = () => {
    const params = {
      status: 1
    };

    const event = this.state.event;
    params.id = event.id;

    this.props
      .onUpdateEvent(params)
      .then((response) => {
        if (response.response.data.status === "success") {
          notify('success', 'Successfuly activated event: ' + response.response.data.data.event_name);
          setEvent(response.response.data.data);
        } else {
          notify('error', response.response.data.message);
        }
      })
      .catch(error => {
        notify('error', error.message);
      });
  }

  componentDidMount() {
    let event = getEvent();
    this.setState({
      event: event
    })
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.event !== nextProps.event) {
      this.setState({
        event: nextProps.event
      })
    }
  }

  handleDeleteEvent = (e) => {
    // console.log(e);
    this.props
      .onDeleteEvent(this.state.event)
      .then((response) => {
        if (response.response.data.status === "success") {
          notify('success', 'Successfuly deleted event');
          deleteEvent();
          window.location = "/home";
        } else {
          notify('error', response.response.data.message);
        }
      })
      .catch(error => {
        notify('error', error.message);
      });
  }

  render() {
    const { event } = this.state;
    const { isLoading, form: { getFieldDecorator }, } = this.props;
    const module = "overview";

    if (!checkPermission(module, "view")) {
      return (<Redirect to="/403" />);
    }

    const radioStyle = {
      display: 'block',
      // height: '30px',
      lineHeight: '30px',
      marginBottom: '10px',
    };

    return (
      <Layout className="overview-container">
        <Content className="overview-content" style={{ padding: "20px 60px" }}>
          <Spin spinning={isLoading} key="overview-spin">
            <Form onSubmit={this.handleSubmit} style={{ width: "100%" }} disabled={isLoading}>
              <PageHeader />

              <Row type="flex" gutter={30}>
                <Col span={12}>
                  <Form.Item label="Event Name">
                    {getFieldDecorator("event_name", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter Event Name",
                        },
                      ],
                      initialValue: event ? event.event_name : ""
                    })(<Input />)}
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item label="Timezone">
                    {getFieldDecorator("timezone", {
                      rules: [
                        {
                          required: true,
                          message: "Please select Timezone",
                        },
                      ],
                      initialValue: event ? event.timezone : ""
                    })(
                      <Select>
                        {timezone.map(row => (
                          <Select.Option value={row} key={row}>{row}</Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <Row type="flex" gutter={30}>
                <Col span={12}>
                  <Form.Item label="Event Date">
                    {getFieldDecorator("event_date", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter Event Date",
                        },
                      ],
                      initialValue: event ? [moment(event.event_date_start, 'YYYY-MM-DD'), moment(event.event_date_end, 'YYYY-MM-DD')] : ""
                    })(<RangePicker />)}
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item label="Time Start">
                    {getFieldDecorator("event_time_start", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter Time Start",
                        },
                      ],
                      initialValue: event ? moment("2022-01-01 " + event.event_time_start) : ""
                    })(<TimePicker use12Hours format={'hh:mm A'} defaultOpenValue={moment('2022-01-01 00:00')} />)}
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item label="Time End">
                    {getFieldDecorator("event_time_end", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter Time End",
                        },
                      ],
                      initialValue: event ? moment("2022-01-01 " + event.event_time_end) : ""
                    })(<TimePicker use12Hours format={'hh:mm A'} defaultOpenValue={moment('2022-01-01 00:00')} />)}
                  </Form.Item>
                </Col>
              </Row>

              <Row type="flex" gutter={30}>
                <Col span={12}>
                  <Form.Item label="Address 1">
                    {getFieldDecorator("address1", {
                      rules: [
                        {
                          required: false,
                          message: "Please enter Address 1",
                        },
                      ],
                      initialValue: event ? event.address1 : ""
                    })(<Input />)}
                  </Form.Item>

                  <Form.Item label="Address 2">
                    {getFieldDecorator("address2", {
                      rules: [
                        {
                          required: false,
                          message: "Please enter Address 2",
                        },
                      ],
                      initialValue: event ? event.address2 : ""
                    })(<Input />)}
                  </Form.Item>

                  <Form.Item label="Address 3">
                    {getFieldDecorator("address3", {
                      rules: [
                        {
                          required: false,
                          message: "Please enter Address 3",
                        },
                      ],
                      initialValue: event ? event.address3 : ""
                    })(<Input />)}
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item label="Email Sender">
                    {getFieldDecorator("email_sender", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter Email Sender",
                        },
                      ],
                      initialValue: event ? event.email_sender : ""
                    })(<Input addonAfter="@u-events.com.ph" />)}
                  </Form.Item>

                  <Form.Item label="Name Sender">
                    {getFieldDecorator("name_sender", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter Name Sender",
                        },
                      ],
                      initialValue: event ? event.name_sender : ""
                    })(<Input />)}
                  </Form.Item>
                </Col>
                {/* 
                <Col span={4}>
                  <Form.Item label="Enable Passport">
                    {getFieldDecorator("enable_passport", {
                      rules: [],
                      valuePropName: 'checked',
                      initialValue: event && event.enable_passport == 1 ? true : false
                    })(<Switch />)}
                  </Form.Item>
                </Col>

                <Col span={4}>
                  <Form.Item label="Enable Briefcase">
                    {getFieldDecorator("enable_briefcase", {
                      rules: [],
                      valuePropName: 'checked',
                      initialValue: event && event.enable_briefcase == 1 ? true : false
                    })(<Switch />)}
                  </Form.Item>
                </Col> */}

                {/* <Col span={4}>
                  <Form.Item label="Enable Email Sending">
                    {getFieldDecorator("enable_email_sending", {
                      rules: [],
                      valuePropName: 'checked',
                      initialValue: event && event.enable_email_sending == 1 ? true : false
                    })(<Switch />)}
                  </Form.Item>
                </Col> */}
              </Row>

              <Row type="flex" gutter={30}>
                <Col span={24}>
                  <Form.Item label="Event Type">
                    {getFieldDecorator("event_type", {
                      rules: [
                        {
                          required: true,
                          message: "Select Event Type",
                        },
                      ],
                      initialValue: event ? event.event_type : ""
                    })(<Radio.Group value={2}>
                      <Radio style={radioStyle} value={"1"}>
                        <span>Public</span>
                        <br />
                        <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</span>
                      </Radio>
                      <Radio style={radioStyle} value={"2"}>
                        <span>Private</span>
                        <br />
                        <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</span>
                      </Radio>
                      <Radio style={radioStyle} value={"3"}>
                        <span>Hidden</span>
                        <br />
                        <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</span>
                      </Radio>
                    </Radio.Group>)}
                  </Form.Item>
                </Col>
              </Row>

              <Row type="flex" gutter={30}>
                <Col span={24} align="right">
                  <Form.Item>
                    {(checkPermission(module, "delete")) ?
                      (<Popconfirm
                        title="Are you sure you want to delete this event?"
                        onConfirm={this.handleDeleteEvent}
                        // onCancel={cancel}
                        okText="Proceed"
                        cancelText="Cancel"
                      >
                        <Button
                          type="danger"
                          disabled={isLoading}
                          loading={isLoading}
                        >
                          <Icon type="delete" />
                          Delete
                        </Button>
                      </Popconfirm>) : ''
                    }

                    &nbsp;&nbsp;

                    {event.status == 1 && checkPermission(module, "activate") ?
                      <Popconfirm
                        title="Proceed to deactivate this event?"
                        onConfirm={this.handleDeactivateEvent}
                        // onCancel={cancel}
                        okText="Proceed"
                        cancelText="Cancel"
                      >
                        <Button
                          type="primary"
                          disabled={isLoading}
                          loading={isLoading}
                        >
                          <Icon type="eye-invisible" />
                          Deactivate
                        </Button>
                      </Popconfirm> : ''}

                    {event.status == 2 && checkPermission(module, "activate") ?
                      <Popconfirm
                        title="Proceed to activate this event?"
                        onConfirm={this.handleActivateEvent}
                        // onCancel={cancel}
                        okText="Proceed"
                        cancelText="Cancel"
                      >

                        <Button
                          type="primary"
                          disabled={isLoading}
                          loading={isLoading}
                        >
                          <Icon type="eye" />
                          Activate
                        </Button>
                      </Popconfirm> : ''}

                    &nbsp;&nbsp;

                    {(checkPermission(module, "edit")) ?
                      (<Button
                        type="primary"
                        htmlType="submit"
                        disabled={isLoading}
                        loading={isLoading}
                      >
                        <Icon type="save" />
                        Save
                      </Button>) : ''
                    }
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Content>
      </Layout >
    );
  }
}

const OverviewForm = Form.create({ name: "overview-form" })(Overview);

export default OverviewForm;
