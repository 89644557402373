import React, { Component } from "react";
import { Form, Layout, Modal, Spin, Row, Col, } from "antd";
import "./index.scss";
// import Countdown from "react-countdown";
import RegistrationModal from "./components/RegistrationModal";
import Scanner from "react-webcam-qr-scanner";
import { checkPermission } from "../../utils/helper";
import { Redirect } from "react-router-dom";
import PageHeader from "../shared/PageHeader";

const { Content } = Layout;

class Scan extends Component {
  initialState = {
    qrCode: null,
    isLoadingState: false,
    showResultModal: false,
    isScanning: false
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
    this.videoRef = React.createRef();
  }

  componentDidMount() {

  }

  componentWillReceiveProps(nextProps) {

  }


  handleCloseModal = () => {
    this.setState({
      showResultModal: false,
      isScanning: false,
      qrCode: null
    });
  }

  handleDecode = (result) => {
    if (this.state.isScanning == false) {
      const code = result.data;

      if (code !== "") {
        this.setState({
          isScanning: true,
          qrCode: code,
          showResultModal: true
        })
      }
    }
  }

  handleScannerLoad = (mode) => {
    // console.log("handleScannerLoad", mode);
  }

  getVideo = () => {
    navigator.mediaDevices
      .getUserMedia({ video: { width: 500 } })
      .then(stream => {
        let video = this.videoRef.current;
        video.srcObject = stream;
        video.play();
      })
      .catch(err => {
        console.error("error:", err);
      });
  };

  render() {
    const { isLoadingState, isScanning, showResultModal, qrCode } = this.state;
    const { isLoading, event } = this.props;
    const isDoneLoading = !isLoading && isLoadingState;
    const module = "scan";

    if (!checkPermission(module, "view")) {
      return (<Redirect to="/403" />);
    }

    if (!event) {
      return (
        <Spin spinning={!isDoneLoading}>
          <Layout className="" style={{ minHeight: "100vh", minWidth: "100%" }}>
            &nbsp;
          </Layout>
        </Spin>
      )
    }

    this.getVideo();

    return (
      <Layout className="registration-field-container">
        <Content className="registration-field-content" style={{ padding: "20px 60px" }}>
          <PageHeader />

          <Row type="flex" gutter={30}>
            <Col span={24}>
              <Spin spinning={isScanning}>
                <Scanner
                  style={{ width: "700px" }}
                  onDecode={this.handleDecode}
                  onScannerLoad={this.handleScannerLoad}
                  constraints={{
                    audio: false,
                    video: {
                      facingMode: "user"
                    }
                  }}
                  captureSize={{ width: 1280, height: 720 }}
                />
              </Spin>
            </Col>
          </Row>
        </Content>
        <RegistrationModal qrCode={qrCode} event={event} showResultModal={showResultModal} handleCloseModal={this.handleCloseModal} {...this.props} />
      </Layout>
    );
  }
}

const ScanForm = Form.create({ name: "scan-form" })(Scan);

export default ScanForm;
