import React, { Component } from "react";
import { Form, Layout, Select, Divider, Menu, Icon, Spin, Row, Col, Button } from "antd";
import { Link } from "react-router-dom";
import "./index.scss";
import { getUser, setEvent, getEvent } from "../../utils/token";
import CreateEventModal from "./components/CreateEventModal";
import { checkPermission } from "../../utils/helper";
import { Redirect } from "react-router-dom";
import apkStaging from "../../assets/apk/scanner-staging.apk";
import apkProduction from "../../assets/apk/scanner-production.apk";

const { Header } = Layout;
const { Option } = Select;

class LayoutHeader extends Component {
  initialState = {
    eventsData: [],
    selectedEvent: {},
    showAddEventModal: false
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  handleShowCreateEventModal = (row) => {
    this.setState({
      showAddEventModal: true
    });
  };

  handleCloseModal = () => {
    this.setState({
      showAddEventModal: false,
    });
  };

  componentDidMount() {
    this.props.onGetEvents();
    let event = getEvent();
    if (event && event.id) {
      this.setState({
        selectedEvent: event
      });
      this.props.handleSelectEvent(event);
    } else {
      this.props.handleSelectEvent({});
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.eventsData !== nextProps.eventsData) {
      this.setState({ eventsData: nextProps.eventsData });
      let event = getEvent();

      if (event && event.id) {
        nextProps.eventsData.forEach((row) => {
          if (row.id == event.id) {
            this.setState({
              selectedEvent: row
            });
            this.props.handleSelectEvent(row);
            setEvent(row);
          }
        });
      }
    }
  }

  handleSelectEvent = id => {
    this.state.eventsData.forEach((row) => {
      if (row.id == id) {
        this.setState({
          selectedEvent: row
        });
        this.props.handleSelectEvent(row);
        setEvent(row);
        window.location.reload();
      }
    });
  }

  handleLogout = () => {
    localStorage.clear();
    window.location.href = "/login";
  }

  handleSubmit = e => {
    e.preventDefault();
  }

  render() {
    const user = JSON.parse(getUser());
    const { eventsData, selectedEvent, showAddEventModal } = this.state;
    const { isLoading, form: { getFieldDecorator }, } = this.props;
    const events = user && user.events;

    return (
      <Layout>
        <Header className="admin-header">
          <Menu mode="horizontal" className="ant-menu-header left">
            <Menu.Item>
              <Select
                loading={isLoading}
                style={{ width: 300 }}
                placeholder="Select event to manage"
                onChange={this.handleSelectEvent}
                value={selectedEvent.event_name}
                dropdownRender={menu => (
                  <Spin spinning={isLoading} key='event-select-spin'>
                    {menu}
                    {(checkPermission("overview", "create")) ?
                      (<Divider style={{ margin: '4px 0' }} />) : ''
                    }

                    {(checkPermission("overview", "create")) ?
                      (
                        <div
                          style={{ padding: '4px 8px', cursor: 'pointer' }}
                          onMouseDown={e => e.preventDefault()}
                          onClick={this.handleShowCreateEventModal}
                        >
                          <Icon type="plus" /> Create Event
                        </div>
                      ) : ''}
                  </Spin>
                )}
              >
                {eventsData.map(item => {
                  if (events.indexOf(item.id) > -1 || user.is_super_admin == 1) {
                    return (<Option key={item.id} label={item.event_name}>
                      <Row type="flex" gutter={30}>
                        <Col span={6} style={{ position: "relative", height: "50px", textAlign: "center" }}>
                          {item.page_details && item.page_details.display_photo ? <img width="100%" src={process.env.REACT_APP_S3_BUCKET + item.page_details.display_photo} /> : ''}
                        </Col>
                        <Col span={18}>
                          <div style={{ fontWeight: "bold" }}>{item.event_name}</div>
                        </Col>
                      </Row>
                    </Option>);
                  }
                })}
              </Select>
            </Menu.Item>
          </Menu>

          <Menu mode="horizontal" className="ant-menu-header right">
            <Menu.Item>
              <Link
                to={process.env.REACT_APP_S3_BUCKET === 'production' ? apkProduction : apkStaging}
                download="scanner"
                target="_blank"
                rel="noreferrer"
              >
                <Icon type="android" />
                Download APK
              </Link>
            </Menu.Item>

            <Menu.Item>
              <Link to="/profile">
                {`${user.first_name} ${user.last_name}`}
              </Link>
            </Menu.Item>

            {/* <Menu.Item>
              <Link to="/profile">My Profile</Link>
            </Menu.Item> */}

            <Menu.Item onClick={this.handleLogout}>
              <Icon type="logout" />
              Logout
            </Menu.Item>
          </Menu>
        </Header>

        <CreateEventModal showModal={showAddEventModal} handleCloseModal={this.handleCloseModal} {...this.props} />
      </Layout>
    );
  }
}

const LayoutHeaderComponent = Form.create({ name: "layout-header" })(LayoutHeader);

export default LayoutHeaderComponent;
