import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "antd";
import {
  getStatistics,
} from "./actions";
import Page from "./Page";
import { getEvent } from "../../utils/token";

class AdminCard extends Component {
  render() {
    return <Page {...this.props} />;
  }
}

const AdminForm = Form.create({ name: "admin_form" })(AdminCard);

function mapStateToProps(state) {
  return {
    isLoading: state.home.isLoading,
    isEventLoading: state.header.isLoading,
    statisticsData: state.home.statisticsData,
    eventsData: state.header.eventsData,
    event: getEvent()
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onGetStatistics: (data) => dispatch(getStatistics(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminForm);
