import React, { Component } from "react";
import { Form, Modal, Layout, Button, Row, Col, Input, Spin, Select, InputNumber, Checkbox, Icon } from "antd";
import "./index.scss";
import { notify, notifyFormError } from "../../../shared/Notification";

const { Content } = Layout;

class PassportModal extends Component {
  initialState = {
    showModal: false,
    selectedRow: {},
    selectedType: "",
    ticketOptions: [],
    selectedTicket: []
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  handleCloseModal = () => {
    this.setState({
      showModal: false,
      selectedRow: {},
      selectedTicket: []
    });
    this.props.form.resetFields();
    this.props.handleCloseModal();
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.showModal !== nextProps.showModal) {
      this.setState({ showModal: nextProps.showModal });
    }

    if (this.props.selectedRow !== nextProps.selectedRow) {
      this.setState({
        selectedRow: nextProps.selectedRow,
        selectedType: nextProps.selectedRow.type,
        selectedTicket: nextProps.selectedRow.event_tickets_ids
      });
    }

    if (this.props.ticketsData !== nextProps.ticketsData) {
      this.setState({ ticketOptions: nextProps.ticketsData });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { selectedRow, selectedTicket } = this.state;

    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const params = {
          ...values,
        };

        if (params.status == "Active") {
          params.status = 1;
        } else if (params.status == "Inactive") {
          params.status = 0;
        }

        params.event_id = this.props.event.id;
        params.event_tickets_ids = selectedTicket;

        if (selectedRow.id) {
          params.id = selectedRow.id;
          await this.props
            .onUpdatePassport(params)
            .then((response) => {
              if (response.response.data.status === "success") {
                notify('success', 'Successfuly updated passport: ' + response.response.data.data.name);
                this.handleCloseModal();
              } else {
                notify('error', response.response.data.message);
              }
            })
            .catch(error => {
              notify('error', error.message);
            });
        } else {
          await this.props
            .onCreatePassport(params)
            .then((response) => {
              if (response.response.data.status === "success") {
                notify('success', 'Successfuly created passport: ' + response.response.data.data.name);
                this.handleCloseModal();
              } else {
                notify('error', response.response.data.message);
              }
            })
            .catch(error => {
              notify('error', error.message);
            });
        }
      } else {
        notifyFormError(err);
      }
    });
  }

  handleChangeType = e => {
    this.setState({
      selectedType: e
    });
  }

  onChange = checklist => {
    const { ticketOptions } = this.state;

    this.setState({
      selectedTicket: checklist,
      indeterminate: !!checklist.length && checklist.length < ticketOptions.length,
      checkAll: checklist.length === ticketOptions.length,
    });
  };

  render() {
    const { showModal, selectedRow, selectedTicket, ticketOptions } = this.state;
    const { isLoading, form: { getFieldDecorator } } = this.props;

    return (
      <Modal
        title={selectedRow.id ? 'Update Passport' : 'Create Passport'}
        visible={showModal}
        onCancel={this.handleCloseModal}
        centered={true}
        footer={null}
        closable={false}
        maskClosable={false}
        width={"50%"}
      >
        <Layout className="overview-container">
          <Content className="overview-content" style={{ padding: "10px" }}>
            <Spin spinning={isLoading} key="create-event">
              <Form onSubmit={this.handleSubmit} style={{ width: "100%" }} disabled={isLoading}>
                <Row type="flex" gutter={30}>
                  <Col span={12}>
                    <Form.Item label="Passport Name">
                      {getFieldDecorator("name", {
                        rules: [
                          {
                            required: true,
                            message: "Please enter Passport Name",
                          },
                        ],
                        initialValue: selectedRow.id ? selectedRow.name : ""
                      })(<Input />)}
                    </Form.Item>
                  </Col>
                </Row>

                <Row type="flex" gutter={30}>
                  <Col span={24}>
                    <Form.Item label="Description">
                      {getFieldDecorator("description", {
                        rules: [
                          {
                            required: false,
                            message: "Please enter Description",
                          },
                        ],
                        initialValue: selectedRow.id ? selectedRow.description : ""
                      })(<Input />)}
                    </Form.Item>
                  </Col>
                </Row>

                <Row type="flex" gutter={30}>
                  <Col span={12}>
                    <Form.Item label="Claim Count">
                      {getFieldDecorator("claim_count", {
                        rules: [
                          {
                            required: true,
                            message: "Please enter Claim Count",
                          },
                        ],
                        initialValue: selectedRow.id ? selectedRow.claim_count : 0
                      })(<InputNumber />)}
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="Status">
                      {getFieldDecorator("status", {
                        rules: [
                          {
                            required: true,
                            message: "Please select Status",
                          },
                        ],
                        initialValue: selectedRow.status == 1 ? "Active" : "Inactive"
                      })(
                        <Select>
                          <Select.Option key="1">Active</Select.Option>
                          <Select.Option key="0">Inactive</Select.Option>
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row type="flex" gutter={30}>
                  <Col span={24}>
                    <Form.Item label="Tickets">
                      {getFieldDecorator("tickets", {
                        rules: [
                          {
                            required: false,
                            message: "Please select Ticket/s",
                          },
                        ],
                        initialValue: selectedTicket
                      })(
                        <Checkbox.Group
                          // options={eventOptions}
                          onChange={this.onChange}
                          style={{ width: "100%" }}
                        >
                          <Row>
                            {ticketOptions.map(row => {
                              return (
                                <Col span={8} key={row.id}>
                                  <Checkbox value={row.id}>{row.ticket_name}</Checkbox>
                                </Col>
                              );
                            }
                            )}
                          </Row>
                        </Checkbox.Group>
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row type="flex" gutter={30}>
                  <Col span={24} align="right">
                    <Form.Item>
                      <Button
                        type="default"
                        disabled={isLoading}
                        loading={isLoading}
                        onClick={this.handleCloseModal}
                      >
                        <Icon type="close" />
                        Close
                      </Button>
                      &nbsp;&nbsp;
                      <Button
                        type="primary"
                        htmlType="submit"
                        disabled={isLoading}
                        loading={isLoading}
                      >
                        <Icon type="save" />
                        Save
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </Content>
        </Layout >
      </Modal>
    );
  }
}

const PassportModalComponent = Form.create({ name: "passport-modal" })(PassportModal);

export default PassportModalComponent;
