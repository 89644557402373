import React, { Component } from "react";
import { Form, Layout, Button, Row, Col, Popconfirm, Table, Tag } from "antd";
import "./index.scss";
import { checkPermission } from "../../utils/helper";
import { Redirect } from "react-router-dom";
import PageHeader from "../shared/PageHeader";

const { Content } = Layout;

class EmailLog extends Component {
  initialState = {
    emailLogsData: [],
    showCreateUpdateModal: false,
    selectedRow: {}
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  handleResendButton = (row) => {
    this.props.onResendEmail(row);
  }

  handleCloseModal = () => {
    this.setState({
      showCreateUpdateModal: false,
    });
  };

  componentDidMount() {
    const onGetParam = {
      event_id: this.props.event.id
    };
    this.props.onGetEmailLogs(onGetParam);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.emailLogsData !== nextProps.emailLogsData) {
      this.setState({ emailLogsData: nextProps.emailLogsData });
    }
  }

  render() {
    const { emailLogsData, showCreateUpdateModal, selectedRow } = this.state;
    const { isLoading, } = this.props;
    const module = "email-log";

    if (!checkPermission(module, "view")) {
      return (<Redirect to="/403" />);
    }

    const columns = [
      {
        title: 'Username',
        dataIndex: 'user_name',
        key: 'user_name',
        render: (column, row) => (
          <span>{`${row.user ? row.user.username : ''}`}</span>
        )
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (column, row) => (
          <span>{`${row.user ? row.user.first_name : ''} ${row.user ? row.user.last_name : ''}`}</span>
        )
      },
      {
        title: 'Recipient',
        dataIndex: 'to_email',
        key: 'to_email',
      },
      {
        title: 'Subject',
        dataIndex: 'title',
        key: 'title',
      },
      {
        title: 'Content',
        dataIndex: 'content',
        key: 'content',
        render: (content) => (
          <div dangerouslySetInnerHTML={{ __html: content }} style={{ maxHeight: "300px", overflow: "auto" }} />
        )
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        align: 'center',
        render: type => (
          <span>
            {type == 2 ? "Confirmation" : ''}
            {type == 1 ? "QR Code" : ''}
            {type == 3 ? "Notify" : ''}
          </span>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        render: status => {
          if (status == 1) {
            return (<Tag color='gray' key='queue'>
              Queue
            </Tag>);
          } else if (status == 0) {
            return (<Tag color='red' key='failed'>
              Failed
            </Tag>);
          } else if (status == 2) {
            return (<Tag color='green' key='success'>
              Success
            </Tag>);
          }
        }
      },
      {
        title: 'Action',
        key: 'action',
        align: 'center',
        render: (text, record) => (
          <span>
            {record.status == 0 ? <Button type="default" onClick={() => this.handleResendButton(record)}>Resend</Button> : ''}
          </span>
        ),
      },
    ];

    return (
      <Layout className="email-log-container">
        <Content className="email-log-content" style={{ padding: "20px 60px" }}>
          <PageHeader />

          <Row type="flex" gutter={30}>
            <Col span={24}>
              <Table columns={columns} dataSource={emailLogsData} loading={isLoading} rowKey={"id"} />
            </Col>
          </Row>
        </Content>

        {/* <CreateUpdateModal showModal={showCreateUpdateModal} selectedRow={selectedRow} handleCloseModal={this.handleCloseModal} {...this.props} /> */}
      </Layout>
    );
  }
}

const EmailLogForm = Form.create({ name: "email-log-form" })(EmailLog);

export default EmailLogForm;
