const initialData = {
  isLoading: false,
  ticketFilter: [],
  paymentModeFilter: [],
  paymentStatusFilter: [],
  salesReportData: [],
  ticketQuantityData: [],
  ticketPriceData: [],
  paymentStatusQuantityData: [],
  paymentStatusPriceData: [],
  paymentModeQuantityData: [],
  paymentModePriceData: [],
};

export default function admin(state = initialData, action) {
  switch (action.type) {
    case "GET_SALES_REPORT_FILTERS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_SALES_REPORT_FILTERS_SUCCESS":
      return {
        ...state,
        ticketFilter: action.payload.ticket,
        paymentModeFilter: action.payload.payment_type,
        paymentStatusFilter: action.payload.payment_status,
        isLoading: false,
      };
    case "GET_SALES_REPORT_FILTERS_FAILED":
      return {
        ...state,
        isLoading: false,
      };

    case "GET_SALES_REPORT_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_SALES_REPORT_SUCCESS":
      return {
        ...state,
        ticketQuantityData: action.payload.ticket_quantity,
        ticketPriceData: action.payload.ticket_price,
        paymentStatusQuantityData: action.payload.payment_status_quantity,
        paymentStatusPriceData: action.payload.payment_status_price,
        paymentModeQuantityData: action.payload.payment_mode_quantity,
        paymentModePriceData: action.payload.payment_mode_price,
        isLoading: false,
      };
    case "GET_SALES_REPORT_FAILED":
      return {
        ...state,
        isLoading: false,
      };

    default:
      return {
        ...state,
      };
  }
}
