import React, { Component } from "react";
import { Form, Layout, Button, Row, Col, Popconfirm, Table, Tag, message, Icon, Tooltip } from "antd";
import "./index.scss";
import CreateUpdateModal from "./components/CreateUpdateModal";
import { checkPermission } from "../../utils/helper";
import { Redirect } from "react-router-dom";
import PageHeader from "../shared/PageHeader";

const { Content } = Layout;

class User extends Component {
  initialState = {
    userRolesData: [],
    accessRolesData: [],
    showCreateUpdateModal: false,
    selectedRow: {}
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  handleCreateButton = () => {
    this.setState({
      showCreateUpdateModal: true
    });
  };

  handleUpdateButton = (row) => {
    this.setState({
      showCreateUpdateModal: true,
      selectedRow: row
    });
  };

  handleDeleteButton = async (row) => {
    await this.props.onDeleteUserRole(row)
      .then((response) => {
        if (response.response.data.status === "success") {
          message.success('Successfuly deleted user role: ' + response.response.data.data.role_name);
          this.handleCloseModal();
        } else {
          message.error('Error occured: ' + response.response.data.message);
        }
      })
      .catch(error => {
        console.log("error", error);
      });
  }

  handleCloseModal = () => {
    this.setState({
      showCreateUpdateModal: false,
    });
  };

  componentDidMount() {
    const onGetParam = {
      event_id: this.props.event.id
    };
    this.props.onGetUserRoles(onGetParam);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.userRolesData !== nextProps.userRolesData) {
      this.setState({ userRolesData: nextProps.userRolesData });
    }

    if (this.props.accessRolesData !== nextProps.accessRolesData) {
      this.setState({ accessRolesData: nextProps.accessRolesData });
    }
  }

  render() {
    const { userRolesData, accessRolesData, showCreateUpdateModal, selectedRow } = this.state;
    const { isLoading, } = this.props;
    const module = "user-role";

    if (!checkPermission(module, "view")) {
      return (<Redirect to="/403" />);
    }

    const columns = [
      {
        title: 'User Role',
        dataIndex: 'role_name',
        key: 'role_name',
      },
      {
        title: '# of Assigned',
        dataIndex: 'user_count',
        key: 'user_count',
        align: 'center',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        render: status => (
          <span>
            {status == 1 ?
              (
                <Tag color='green' key='active'>
                  Active
                </Tag>
              ) : (
                <Tag color='gray' key='inactive'>
                  Inactive
                </Tag>
              )
            }
          </span>
        ),
      },
      {
        title: 'Action',
        key: 'action',
        align: 'center',
        render: (text, record) => (
          <span>
            {(checkPermission(module, "edit")) ?
              (<Tooltip title="Edit"><Button type="default" onClick={() => this.handleUpdateButton(record)}><Icon type="edit" /></Button></Tooltip>) : ''
            }
            &nbsp;&nbsp;
            {(checkPermission(module, "delete")) ?
              record.user_count == 0 ? (
                <Popconfirm
                  title="Are you sure you want to delete?"
                  onConfirm={() => this.handleDeleteButton(record)}
                  okText="Proceed"
                  cancelText="Cancel"
                >
                  <Tooltip title="Delete">
                    <Button type="danger" disabled={record.user_count > 0 ? true : false} readOnly={record.user_count > 0 ? true : false} ><Icon type="delete" /></Button>
                  </Tooltip>
                </Popconfirm>
              ) : (
                <Tooltip title="Delete">
                  <Button type="danger" disabled={record.user_count > 0 ? true : false} readOnly={record.user_count > 0 ? true : false} ><Icon type="delete" /></Button>
                </Tooltip>
              )
              : ''}

          </span>
        ),
      },
    ];

    return (
      <Layout className="user-container">
        <Content className="user-content" style={{ padding: "20px 60px" }}>
          <PageHeader rightPanel={(checkPermission(module, "create")) ?
            <Button type="primary" loading={isLoading} onClick={this.handleCreateButton}><Icon type="plus" />Add User Role</Button>
            : ''
          } />

          <Row type="flex" gutter={30}>
            <Col span={24}>
              <Table columns={columns} dataSource={userRolesData} loading={isLoading} rowKey={"id"} />
            </Col>
          </Row>
        </Content>

        <CreateUpdateModal showModal={showCreateUpdateModal} selectedRow={selectedRow} handleCloseModal={this.handleCloseModal} {...this.props} />
      </Layout>
    );
  }
}

const UserForm = Form.create({ name: "user-form" })(User);

export default UserForm;
