import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "antd";
import { getTicketSales, updatePaymentStatus } from "./actions";
import Page from "./Page";
import { getEvent } from "../../utils/token";

class TicketSalesCard extends Component {
  render() {
    return <Page {...this.props} />;
  }
}

const TicketSalesForm = Form.create({ name: "ticket-sales-form" })(TicketSalesCard);

function mapStateToProps(state) {
  return {
    isLoading: state.payments.isLoading,
    ticketSalesData: state.payments.ticketSalesData,
    event: getEvent()
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onGetTicketSales: (data) => dispatch(getTicketSales(data)),
    onUpdatePaymentStatus: (data) => dispatch(updatePaymentStatus(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TicketSalesForm);
