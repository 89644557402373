import React, { Component } from "react";
import { Form, Layout, Button, Row, Col, Input, Spin, Icon, Tooltip } from "antd";
import "./index.scss";
import { getUser, setUser } from "../../utils/token";
import { notify, notifyFormError } from "../shared/Notification";
import ChangePasswordModal from "./components/ChangePasswordModal";

const { Content } = Layout;

class Overview extends Component {
  initialState = {
    user: {},
    showChangePasswordModal: false,
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(['first_name', 'last_name'], async (err, values) => {
      if (!err) {
        const user = this.state.user;
        const params = {
          first_name: values?.first_name,
          last_name: values?.last_name,
          id: user?.id,
        };

        await this.props
          .onUpdateProfile(params)
          .then((response) => {
            if (response.response.data.status === "success") {
              notify('success', 'Successfuly updated profile:');
              setUser(JSON.stringify(response.response.data.data));
              window.location.reload();
            } else {
              notify('error', response.response.data.message);
            }
          })
          .catch(error => {
            notify('error', error.message);
          });
      } else {
        notifyFormError(err);
      }
    });
  }

  componentDidMount() {
    const user = JSON.parse(getUser());
    this.setState({
      user
    })
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.event !== nextProps.event) {
      this.setState({
        event: nextProps.event
      })
    }
  }

  handleChangeButton = () => {
    this.setState({
      showChangePasswordModal: true
    });
  };

  handleCloseModal = () => {
    this.setState({
      showChangePasswordModal: false,
    });
  };

  render() {
    const { user, showChangePasswordModal } = this.state;
    const { isLoading, form: { getFieldDecorator }, } = this.props;

    return (
      <Layout className="overview-container">
        <Content className="overview-content" style={{ padding: "20px 60px" }}>
          <Spin spinning={isLoading} key="overview-spin">
            <Form onSubmit={this.handleSubmit} style={{ width: "100%" }} disabled={isLoading}>
              <Row type="flex" gutter={30}>
                <Col span={24}>
                  &nbsp;
                </Col>
                <Col span={12} className="page-header">
                  <h2><Icon className="page-header-icon" type='profile' /> Profile {
                    user.is_super_admin
                      ? <Tooltip title="Super user"><Icon type="crown" style={{ color: 'black', backgroundColor: 'yellow' }} /></Tooltip>
                      : null
                  }</h2>
                </Col>
              </Row>

              <Row type="flex" gutter={30}>
                <Col span={8}>
                  <Form.Item label="Username">
                    {getFieldDecorator("username", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter Username",
                        },
                      ],
                      initialValue: user?.username ?? ""
                    })(<Input disabled readOnly />)}
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label="First Name">
                    {getFieldDecorator("first_name", {
                      rules: [
                        {
                          required: false,
                          message: "Please enter First Name",
                        },
                      ],
                      initialValue: user?.first_name ?? ""
                    })(<Input />)}
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label="Last Name">
                    {getFieldDecorator("last_name", {
                      rules: [
                        {
                          required: false,
                          message: "Please enter Last Name",
                        },
                      ],
                      initialValue: user?.last_name ?? ""
                    })(<Input />)}
                  </Form.Item>
                </Col>
              </Row>

              <Row type="flex" gutter={30}>
                <Col span={8}>
                  <Form.Item label="Email Address">
                    {getFieldDecorator("email", {
                      rules: [
                        {
                          required: false,
                          message: "Please enter Email Address",
                        },
                      ],
                      initialValue: user?.email ?? ""
                    })(<Input disabled readOnly />)}
                  </Form.Item>
                </Col>
              </Row>

              <Row type="flex" gutter={30}>
                <Col span={24} align="right">
                  <Form.Item>
                    <Button
                      type="primary"
                      disabled={isLoading}
                      loading={isLoading}
                      onClick={this.handleChangeButton}
                    >
                      <Icon type="edit" />
                      Change Password
                    </Button>

                    &nbsp;&nbsp;

                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={isLoading}
                      loading={isLoading}
                    >
                      <Icon type="save" />
                      Save
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Content>
        <ChangePasswordModal showModal={showChangePasswordModal} handleCloseModal={this.handleCloseModal} {...this.props} />
      </Layout>
    );
  }
}

const OverviewForm = Form.create({ name: "overview-form" })(Overview);

export default OverviewForm;
