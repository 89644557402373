const initialData = {
  isLoading: false,
  passportClaimsData: [],
};

export default function admin(state = initialData, action) {
  switch (action.type) {
    case "GET_PASSPORT_CLAIMS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_PASSPORT_CLAIMS_SUCCESS":
      return {
        ...state,
        passportClaimsData: action.payload.data,
        isLoading: false,
      };
    case "GET_PASSPORT_CLAIMS_FAILED":
      return {
        ...state,
        passportClaimsData: [],
        isLoading: false,
      };

    default:
      return {
        ...state,
      };
  }
}
