import Axios from "../../../utils/axios";

export const getEvents = (data) => {
    return (dispatch) => {
        Axios.get("/events", dispatch, "GET_EVENTS", data);
    };
};

export const createEvent = (data) => {
    return (dispatch) => {
        return Axios
            .post("/event", dispatch, "CREATE_EVENT", data)
            .then((response) => {
                if (response.response.data.status === "success") {
                    dispatch(getEvents());
                }
                return response;
            });
    };
};