import React, { Component } from "react";
import { Form, Modal, message, Layout, Button, Row, Col, Checkbox, Radio, Switch, DatePicker, TimePicker, Spin } from "antd";
import "./index.scss";

const { Content } = Layout;

class RegistrationModal extends Component {
  initialState = {
    showModal: false,
    qrCode: null,
    registrationData: {},
    checked: false
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  handleCloseModal = () => {
    this.setState({
      showModal: false,
    });
    this.props.form.resetFields();
    this.props.handleCloseModal();
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.showResultModal !== nextProps.showRegistrationModal) {
      this.setState({ showModal: nextProps.showResultModal });
    }

    if (this.props.qrCode !== nextProps.qrCode && nextProps.qrCode !== "") {
      this.handleScanRequest(nextProps.qrCode);

      this.setState({ qrCode: nextProps.qrCode });
    }

    if (this.props.registrationData !== nextProps.registrationData) {
      this.setState({ registrationData: nextProps.registrationData });
    }
  }

  handleScanRequest = (qrCode) => {
    const params = {
      event_id: this.props.event.id,
      qr_code: qrCode
    }

    this.props.onGetRegistration(params);
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const registrationData = this.state.registrationData;
        const params = {
          event_id: registrationData.event_id,
          event_registration_id: registrationData.id,
          event_ticket_id: registrationData.ticket.id
        };

        await this.props
          .onAcceptAttendance(params)
          .then((response) => {
            if (response.response.data.status === "success") {
              message.success('Successfuly registered');
              this.handleCloseModal();
              this.setState({
                checked: false,
              });
            } else {
              message.error('Error occured: ' + response.response.data.message);
            }
          })
          .catch(error => {
            console.log("error", error);
          });
      } else {
        console.log("error", err);
      }
    });
  }

  handleCheckbox = e => {
    this.setState({
      checked: e.target.checked,
    });
  };

  render() {
    const { showModal, registrationData, checked } = this.state;
    const { isLoading, form: { getFieldDecorator }, } = this.props;

    return (
      <Modal
        title={`Verify`}
        visible={showModal}
        onCancel={this.handleCloseModal}
        centered={true}
        footer={null}
        width={"500px"}
        destroyOnClose={true}
      >
        <Layout className="overview-container">
          <Content className="overview-content" style={{ padding: "10px" }}>
            <Spin spinning={isLoading} key="create-event-spin">
              {registrationData && registrationData.values ? (
                <Form onSubmit={this.handleSubmit} style={{ width: "100%" }} disabled={isLoading}>
                  <Row type="flex" gutter={30}>
                    {registrationData.values.map(row => (
                      <Col span={12} style={{ marginBottom: "10px" }} key={row.id}>
                        <span style={{ fontWeight: "bold" }}>{row.field.label}: </span><br />
                        <span>{row.field_value}</span>
                      </Col>
                    ))}
                  </Row>

                  <Row type="flex" gutter={30}>
                    <Col span={24} align="center">
                      <Form.Item>
                        {getFieldDecorator("checkbox", {
                          rules: [
                            {
                              required: true,
                              message: "Please check to proceed",
                            },
                          ]
                        })(<Checkbox
                          checked={checked}
                          onChange={this.handleCheckbox}
                        >
                          I agree that these data are all correct.
                        </Checkbox>)}
                      </Form.Item>
                    </Col>
                  </Row>


                  <Row type="flex" gutter={30}>
                    <Col span={24} align="right">
                      <Form.Item>
                        <Button
                          type="default"
                          disabled={isLoading}
                          loading={isLoading}
                          onClick={this.handleCloseModal}
                        >
                          Decline
                        </Button>
                        &nbsp;
                        &nbsp;
                        <Button
                          type="primary"
                          htmlType="submit"
                          disabled={isLoading}
                          loading={isLoading}
                        >
                          Accept
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              ) : (
                <Form onSubmit={this.handleSubmit} style={{ width: "100%" }} disabled={isLoading}>
                  <Row type="flex" gutter={30}>
                    <Col span={24} style={{ textAlign: "center" }}>
                      No data found
                    </Col>
                    <Col span={24} align="right">
                      <Form.Item>
                        <Button
                          type="default"
                          disabled={isLoading}
                          loading={isLoading}
                          onClick={this.handleCloseModal}
                        >
                          Close
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              )}

              {/* <Row type="flex" gutter={30}>
                  <Col span={12}>
                    <Form.Item label="Event Name">
                      {getFieldDecorator("event_name", {
                        rules: [
                          {
                            required: true,
                            message: "Please enter Event Name",
                          },
                        ],
                      })(<Input />)}
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="Timezone">
                      {getFieldDecorator("timezone", {
                        rules: [
                          {
                            required: true,
                            message: "Please enter Timezone",
                          },
                        ],
                      })(<Input />)}
                    </Form.Item>
                  </Col>
                </Row>

                <Row type="flex" gutter={30}>
                  <Col span={12}>
                    <Form.Item label="Event Date">
                      {getFieldDecorator("event_date", {
                        rules: [
                          {
                            required: true,
                            message: "Please enter Event Date",
                          },
                        ],
                      })(<RangePicker />)}
                    </Form.Item>
                  </Col>
                </Row>

                <Row type="flex" gutter={30}>
                  <Col span={4}>
                    <Form.Item label="Enable Passport">
                      {getFieldDecorator("enable_passport", {
                        rules: [],
                      })(<Switch />)}
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <Form.Item label="Enable Briefcase">
                      {getFieldDecorator("enable_briefcase", {
                        rules: [],
                      })(<Switch />)}
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <Form.Item label="Enable Email Sending">
                      {getFieldDecorator("enable_email_sending", {
                        rules: [],
                      })(<Switch />)}
                    </Form.Item>
                  </Col>
                </Row>

                <Row type="flex" gutter={30}>
                  <Col span={24}>
                    <Form.Item label="Event Type">
                      {getFieldDecorator("event_type", {
                        rules: [
                          {
                            required: true,
                            message: "Select Event Type",
                          },
                        ],
                      })(<Radio.Group value={2}>
                        <Radio style={radioStyle} value={"1"}>
                          <span>Public</span>
                          <br />
                          <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</span>
                        </Radio>
                        <Radio style={radioStyle} value={"2"}>
                          <span>Private</span>
                          <br />
                          <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</span>
                        </Radio>
                        <Radio style={radioStyle} value={"3"}>
                          <span>Hidden</span>
                          <br />
                          <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</span>
                        </Radio>
                      </Radio.Group>)}
                    </Form.Item>
                  </Col>
                </Row> */}


            </Spin>
          </Content>
        </Layout >
      </Modal >
    );
  }
}

const RegistrationModalComponent = Form.create({ name: "registration-modal" })(RegistrationModal);

export default RegistrationModalComponent;
