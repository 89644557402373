const initialData = {
  isLoading: false,
  rPrizesData: [],
  rPrizeData: {},
  delRPrizeData: {},
  putRPrizeData: {}
};

export default function rprizes(state = initialData, action) {
  switch (action.type) {
    case "GET_PRIZES":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_PRIZES_SUCCESS":
      return {
        ...state,
        rPrizesData: action.payload.data,
        isLoading: false,
      };
    case "GET_PRIZES_FAILED":
      return {
        ...state,
        rPrizesData: [],
        isLoading: false,
      };

    case "CREATE_PRIZE":
      return {
        ...state,
        isLoading: true,
      };
    case "CREATE_PRIZE_SUCCESS":
      return {
        ...state,
        rPrizeData: action.payload.data,
        isLoading: false,
      };
    case "CREATE_PRIZE_FAILED":
      return {
        ...state,
        rPrizeData: {},
        isLoading: false,
      };

    case "DELETE_PRIZE":
      return {
        ...state,
        isLoading: true,
      };
    case "DELETE_PRIZE_SUCCESS":
      return {
        ...state,
        delRPrizeData: action.payload.data,
        isLoading: false,
      };
    case "DELETE_PRIZE_FAILED":
      return {
        ...state,
        delRPrizeData: {},
        isLoading: false,
      };

    case "UPDATE_PRIZE":
      return {
        ...state,
        isLoading: true,
      };
    case "UPDATE_PRIZE_SUCCESS":
      return {
        ...state,
        putRPrizeData: action.payload.data,
        isLoading: false,
      };
    case "UPDATE_PRIZE_FAILED":
      return {
        ...state,
        putRPrizeData: {},
        isLoading: false,
      };

    default:
      return {
        ...state,
      };
  }
}
