import Axios from "../../../utils/axios";

export const getTicketSales = (data) => {
    return (dispatch) => {
        Axios.get("/ticket-sales", dispatch, "GET_TICKET_SALES", data);
    };
};

export const updatePaymentStatus = (data) => {
    return (dispatch) => {
        return Axios
            .post("/payment-status/" + data.id, dispatch, "UPDATE_PAYMENT_STATUS", data)
            .then((response) => {
                if (response.response.data.status === "success") {
                    const param = {
                        event_id: response.response.data.data.event_id
                    };
                    dispatch(getTicketSales(param));
                }
                return response;
            });
    };
};
