import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "antd";
import { getAuditLogs } from "./actions";
import Page from "./Page";
import { getEvent } from "../../utils/token";

class AuditLogCard extends Component {
  render() {
    return <Page {...this.props} />;
  }
}

const AuditLogForm = Form.create({ name: "audit-log-form" })(AuditLogCard);

function mapStateToProps(state) {
  return {
    isLoading: state.auditLog.isLoading,
    auditLogData: state.auditLog.auditLogData,
    auditLogTotal: state.auditLog.auditLogTotal,
    auditLogsData: state.auditLog.auditLogsData,
    event: getEvent()
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onGetAuditLogs: (data) => dispatch(getAuditLogs(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuditLogForm);
