import Axios from "../../../utils/axios";

export const getUserRoles = (data) => {
    return (dispatch) => {
        Axios.get("/user-roles", dispatch, "GET_USER_ROLES", data);
    };
};

export const createUserRole = (data) => {
    return (dispatch) => {
        return Axios
            .post("/user-role", dispatch, "CREATE_USER_ROLE", data)
            .then((response) => {
                if (response.response.data.status === "success") {
                    dispatch(getUserRoles());
                }
                return response;
            });
    };
};

export const updateUserRole = (data) => {
    return (dispatch) => {
        return Axios
            .post("/user-role/" + data.id, dispatch, "UPDATE_USER_ROLE", data)
            .then((response) => {
                if (response.response.data.status === "success") {
                    dispatch(getUserRoles());
                }
                return response;
            });
    };
};

export const deleteUserRole = (data) => {
    return (dispatch) => {
        return Axios
            .delete("/user-role/" + data.id, dispatch, "DELETE_USER_ROLE", data)
            .then((response) => {
                if (response.response.data.status === "success") {
                    dispatch(getUserRoles());
                }
                return response;
            });
    };
};