const initialData = {
  isLoading: false,
  ticketsData: [],
  ticketData: {},
};

export default function admin(state = initialData, action) {
  switch (action.type) {
    case "GET_TICKETS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_TICKETS_SUCCESS":
      return {
        ...state,
        ticketsData: action.payload.data,
        isLoading: false,
      };
    case "GET_TICKETS_FAILED":
      return {
        ...state,
        ticketsData: [],
        isLoading: false,
      };

    case "CREATE_TICKET_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "CREATE_TICKET_SUCCESS":
      return {
        ...state,
        ticketData: action.payload.data,
        isLoading: false,
      };
    case "CREATE_TICKET_FAILED":
      return {
        ...state,
        ticketData: {},
        isLoading: false,
      };

    case "UPDATE_TICKET_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "UPDATE_TICKET_SUCCESS":
      return {
        ...state,
        ticketData: action.payload.data,
        isLoading: false,
      };
    case "UPDATE_TICKET_FAILED":
      return {
        ...state,
        ticketData: {},
        isLoading: false,
      };

    case "DELETE_TICKET_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "DELETE_TICKET_SUCCESS":
      return {
        ...state,
        ticketData: action.payload.data,
        isLoading: false,
      };
    case "DELETE_TICKET_FAILED":
      return {
        ...state,
        ticketData: {},
        isLoading: false,
      };

    default:
      return {
        ...state,
      };
  }
}
