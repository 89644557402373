import React from "react";
import LogoPath from "../../../assets/images/logo.png";

export default function logo(props) {
  return (
    <div style={{ position: "relative", textAlign: "center" }}>
      <img
        src={LogoPath}
        loading="lazy"
        alt="U-events"
        onClick={() => window.location.href = "/"}
        style={{ cursor: "pointer", maxWidth: "70%", maxHeight: "100px" }}
      />
    </div>
  );
}
