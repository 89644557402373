import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "antd";
import { getAttendance, exportAttendance } from "./actions";
import { getRegistrations } from "../Registration/actions";
import { acceptAttendance } from "../Scan/actions";
import Page from "./Page";
import { getEvent } from "../../utils/token";


class AttendanceCard extends Component {
  render() {
    return <Page {...this.props} />;
  }
}

const AttendanceForm = Form.create({ name: "attendance-form" })(AttendanceCard);

function mapStateToProps(state) {
  return {
    isLoading: state.attendance.isLoading,
    attendanceData: state.attendance.attendanceData,
    event: getEvent(),
    isRegistrationLoading: state.registration.isLoading,
    registrationsData: state.registration.registrationsData,
    attendanceTotal: state.attendance.attendanceTotal,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onGetAttendance: (data) => dispatch(getAttendance(data)),
    onExportAttendance: (data) => dispatch(exportAttendance(data)),
    onGetRegistrations: (data) => dispatch(getRegistrations(data)),
    onAcceptAttendance: (data) => dispatch(acceptAttendance(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AttendanceForm);
