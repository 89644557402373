import React, { Component } from "react";
import { Form, Layout, Row, Col } from "antd";
import "./index.scss";

const { Content } = Layout;

class RaffleViewer extends Component {
  initialState = {
    rSettingsData: null,
    eventData: {}
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  getData = async () => {
    const onGetParam = {
      event_id: this.props.event.id,
    };
    await this.props.OnGetSettings(onGetParam);
    this.forceUpdate();
  }

  async componentDidMount() {
    await this.getData()
    setInterval(() => this.getData(), 5000);
  }

  async componentWillReceiveProps(nextProps) {

    if (this.props.rSettingsData !== nextProps.rSettingsData) {
      this.setState({
        rSettingsData: nextProps.rSettingsData.data,
        eventData: nextProps.rSettingsData.event_page_details,
      });
    }
  }

  render() {
    const { rSettingsData, eventData } = this.state
    const { isLoading, } = this.props;

    if (!rSettingsData) {
      return '';
    }

    const showPage = rSettingsData[1]?.value
    const showWinner = rSettingsData[2]?.value
    const showPrize = rSettingsData[3] && JSON.parse(rSettingsData[3]?.value)

    let pageSettings = {};
    let prize = null;
    let winner = null;
    rSettingsData.forEach(row => {
      if (row.name == showPage.toLowerCase().replace(' ', '_') + "_page") {
        pageSettings = row.value;
      }

      if (row.name == "prize") {
        prize = JSON.parse(row.value);
      }

      if (row.name == "winner") {
        winner = row.value;
      }
    });

    let pageContent = pageSettings.content;
    if (prize) {
      pageContent = pageContent.replace("{{prize-name}}", prize.label);
      pageContent = pageContent.replace("{{prize-category}}", prize.name);
      pageContent = pageContent.replace("{{prize-image}}", `<img class='prize-image' src='${process.env.REACT_APP_S3_BUCKET}${prize.prize_image}' />`);
      pageContent = pageContent.replace("{{prize-description}}", prize.description);
    }

    if (winner) {
      pageContent = pageContent.replace("{{winner}}", winner.join("<br />"));
    }

    const getPageContent = (type) => {
      switch (type.toLowerCase()) {
        case 'blank':
          return <div className="viewer-content">
            <img src={`${process.env.REACT_APP_S3_BUCKET}/storage/${eventData?.display_photo}`} className="background-img" alt='raffle' />
          </div>
        case 'splash':
          return <div className="viewer-content">
            <img src={`${process.env.REACT_APP_S3_BUCKET}/storage/${showPrize?.splash_image}`} className="background-img" alt='raffle' />
            <div>MINOR RAFFLE</div>
          </div>
        case 'prize':
          return <div className="viewer-content">
            <img src={`${process.env.REACT_APP_S3_BUCKET}/storage/${showPrize?.background_image}`} className="background-img" alt='raffle' />
            <div className="prize-content">
              <div>
                <img src={`${process.env.REACT_APP_S3_BUCKET}/storage/${showPrize?.prize_image}`} className="prize-img" alt='raffle' />
              </div>
              <div className="prize-label">{showPrize?.label}</div>
              <div className="prize-desc">{showPrize?.description}</div>
            </div>
          </div>
        case 'winner':
          return <div className="viewer-content">
            <img src={`${process.env.REACT_APP_S3_BUCKET}/storage/${showPrize?.background_image}`} className="background-img" alt='raffle' />
            <div className="winner-content">
              <div className="winner-text">WINNER</div>
              <div className="winner-name">{showWinner}</div>
            </div>
          </div>
      }
    }

    return (
      <Layout className="raffle-viewer-container"
        style={{
          backgroundColor: pageSettings.background_color ?? '',
          backgroundImage: pageSettings.background_color ? 'url("' + process.env.REACT_APP_S3_BUCKET + pageSettings.background_image + '")' : '',
          height: "100vh"
        }}>
        <Content>
          <Row type="flex" gutter={30} height={"100%"}>
            <Col span={24} height={"100%"} dangerouslySetInnerHTML={{ __html: pageContent }}>
            </Col>
          </Row>
          {/* {getPageContent(showPage)} */}
        </Content>
      </Layout >
    );
  }
}

const RaffleViewerForm = Form.create({ name: "raffle-viewer-form" })(RaffleViewer);

export default RaffleViewerForm;
