import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "antd";
import { getPassportClaims } from "./actions";
import Page from "./Page";
import { getEvent } from "../../utils/token";

class PassportClaimsCard extends Component {
  render() {
    return <Page {...this.props} />;
  }
}

const PassportClaimsForm = Form.create({ name: "passport-claims-form" })(PassportClaimsCard);

function mapStateToProps(state) {
  return {
    isLoading: state.passportClaims.isLoading,
    passportClaimsData: state.passportClaims.passportClaimsData,
    event: getEvent()
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onGetPassportClaims: (data) => dispatch(getPassportClaims(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PassportClaimsForm);
