import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "antd";
import { getSalesReportFilters, getSalesReport } from "./actions";
import Page from "./Page";
import { getEvent } from "../../utils/token";

class SalesReportCard extends Component {
  render() {
    return <Page {...this.props} />;
  }
}

const SalesReportForm = Form.create({ name: "sales-report-form" })(SalesReportCard);

function mapStateToProps(state) {
  return {
    isLoading: state.salesReport.isLoading,
    ticketFilter: state.salesReport.ticketFilter,
    paymentStatusFilter: state.salesReport.paymentStatusFilter,
    paymentModeFilter: state.salesReport.paymentModeFilter,
    salesReportData: state.salesReport.salesReportData,
    ticketQuantityData: state.salesReport.ticketQuantityData,
    ticketPriceData: state.salesReport.ticketPriceData,
    paymentStatusQuantityData: state.salesReport.paymentStatusQuantityData,
    paymentStatusPriceData: state.salesReport.paymentStatusPriceData,
    paymentModeQuantityData: state.salesReport.paymentModeQuantityData,
    paymentModePriceData: state.salesReport.paymentModePriceData,
    event: getEvent()
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onGetSalesReportFilters: (data) => dispatch(getSalesReportFilters(data)),
    onGetSalesReport: (data) => dispatch(getSalesReport(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesReportForm);
