const initialData = {
  isLoading: false,
  emailTemplatesData: [],
  emailTemplateData: {},
};

export default function admin(state = initialData, action) {
  switch (action.type) {
    case "GET_EMAIL_TEMPLATE_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_EMAIL_TEMPLATE_SUCCESS":
      return {
        ...state,
        emailTemplatesData: action.payload.data,
        isLoading: false,
      };
    case "GET_EMAIL_TEMPLATE_FAILED":
      return {
        ...state,
        emailTemplatesData: [],
        isLoading: false,
      };

    case "UPDATE_EMAIL_TEMPLATE_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "UPDATE_EMAIL_TEMPLATE_SUCCESS":
      return {
        ...state,
        emailTemplateData: action.payload.data,
        isLoading: false,
      };
    case "UPDATE_EMAIL_TEMPLATE_FAILED":
      return {
        ...state,
        emailTemplateData: {},
        isLoading: false,
      };

    default:
      return {
        ...state,
      };
  }
}
