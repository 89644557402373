const initialData = {
  isLoading: false,
  attendanceData: [],
  attendanceTotal: 0
};

export default function admin(state = initialData, action) {
  switch (action.type) {
    case "GET_ATTENDANCE_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_ATTENDANCE_SUCCESS":
      return {
        ...state,
        attendanceData: action.payload.data,
        attendanceTotal: action.payload.total_count,
        isLoading: false,
      };
    case "GET_ATTENDANCE_FAILED":
      return {
        ...state,
        attendanceData: [],
        isLoading: false,
      };

    default:
      return {
        ...state,
      };
  }
}
