import Axios from "../../../utils/axios";

export const getPassports = (data) => {
  return (dispatch) => {
    Axios.get("/passports", dispatch, "GET_PASSPORTS", data);
  };
};

export const createPassport = (data) => {
  return (dispatch) => {
    return Axios
      .post("/passport", dispatch, "CREATE_PASSPORT", data)
      .then((response) => {
        if (response.response.data.status === "success") {
          const param = {
            event_id: response.response.data.data.event_id
          };
          dispatch(getPassports(param));
        }
        return response;
      });
  };
};

export const updatePassport = (data) => {
  return (dispatch) => {
    return Axios
      .post("/passport/" + data.id, dispatch, "UPDATE_PASSPORT", data)
      .then((response) => {
        if (response.response.data.status === "success") {
          const param = {
            event_id: response.response.data.data.event_id
          };
          dispatch(getPassports(param));
        }
        return response;
      });
  };
};

export const deletePassport = (data) => {
  return (dispatch) => {
    return Axios
      .delete("/passport/" + data.id, dispatch, "DELETE_PASSPORT", data)
      .then((response) => {
        if (response.response.data.status === "success") {
          const param = {
            event_id: data.event_id
          };
          dispatch(getPassports(param));
        }
        return response;
      });
  };
};