import React from "react";
import { Row, Col, Icon } from "antd";
import { getMenu } from "../../../utils/storage";
import { getEvent } from "../../../utils/token"

export default function pageHeader(props) {
  const menu = getMenu();
  const event = getEvent();

  if (!event || !menu) {
    return '';
  }

  return (
    <Row type="flex" gutter={30}>
      <Col span={24}>
        <span style={{ fontWeight: "bold", fontStyle: "italic" }}>{event ? event.event_name : ''}'s</span>
      </Col>
      <Col span={12} className="page-header">
        <h2><Icon className="page-header-icon" type={menu.icon} /> {menu.name}</h2>
      </Col>
      <Col span={12} align={props.rightPanelAlign ?? 'right'}>
        {props.rightPanel ?? ''}
      </Col>
    </Row>
  );
}